import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import CasinoVideo from "./components/CasinoVideo";
import Timer from "./components/Timer";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import {
  getCasinoBetsAPI,
  getCasinoLastTenResultAPI,
  getCasinoOddsAPI,
} from "../../service/casino";
import {
  CARD_ARRAY,
  CASINO_VIDEO,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import PlaceBet from "./components/PlaceBet";
import PlacedBet from "./components/PlacedBet";
import { io } from "socket.io-client";
import { API } from "../../service/API";
import { fetchThirtyTwoCardStack } from "../../redux/features/thirtyTwoCardGameSlice";
import CasinoBetModal from "./CasinoBetModal";
export default function Lucky7A() {
  const GAME_ID = "LUCKY7";
  const VIDEO_ID = 3056;
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  // const lastTenResultData = useQuery(
  //   "getResult",
  //   () => getCasinoLastTenResultAPI(GAME_ID),
  //   {
  //     refetchInterval: 1500,
  //   }
  // );
  // const casinoBets = useQuery("casinoBets", () => getCasinoBetsAPI(GAME_ID), {
  //   refetchInterval: 2500,
  // });
  const [selectedTab, setSelectedTab] = React.useState("game");

  const id = 2;
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const uid = user.data.resultid;
  // const imageImports = [c1, c2, c3, c4, c5, c6, c7, c8, c9, c10, c11, c12, c13];
  const [showresultModal, setShowresultModal] = useState(false);
  const [showDataModal, setShowdataModal] = useState(false);
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [rulesModal, setRulesModal] = useState(false);
  const [realTime, setRealTimeData] = useState(false);

  // const { casinobetHistory, loading } = useSelector(
  //   (state) => state.casinobetHistory
  // );

  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  const [betno, setBetNum] = useState(0);
  const [betDetails, setbetDetails] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      // console.log("CasinoGameResult lucky:", response?.data?.data[0]);
      setCasinoGameResult(response?.data?.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const SOCKET_URL = "https://dreamwin-socket.nakshtech.info/";

  useEffect(() => {
    // console.log("Odd socket before");
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      console.log("TeenPatti socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: item?.resultUrlResponse
            ? JSON.parse(item?.resultUrlResponse)
            : null,
        }));

        console.log("l7a", parsedData[0].reqResponse);
        // console.log("parsed_result", parsedData[0].resultUrlResponse);
        setResults(parsedData[0]?.reqResponse);
        setreqRes(parsedData[0]?.reqResponse);
        setName(parsedData[0]?.name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  async function ResultScheduler_API() {
    try {
      let res = await API.post(
        "/Schulder_7CareGameLive",
        {
          gameid: id,
          username: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  const [history, setHistory] = useState([]);

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);

    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  const handleModalPopUp = () => {
    setShowModal(!showModal);
  };

  const handleModal = (value, nation, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nation: nation,
      number: no,
    });
    handleModalPopUp(true);
    setRealTimeData(true);
  };

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.nation === betDetails.nation
      );
      // console.log("filter", filteredData, betDetails);
      filteredData[0]?.gstatus !== "0" &&
      betDetails?.nation === filteredData[0]?.nation &&
      betDetails?.multipler === filteredData[0]?.rate
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  // const findCardImage = (cardId) => {
  //   const card = card52War.find((card) => card.id === cardId);
  //   return card ? card.src : null;
  // };

  return (
    <div className="center-main-container casino-page ng-tns-c1798067373-0 ng-star-inserted">
      <div className="center-container ng-tns-c1798067373-0">
        <div className="casino-page-container ng-tns-c1798067373-0 lucky7a">
          <div className="casino-title-header-mobile d-xl-none ng-tns-c1798067373-0">
            <ul className="nav nav-tabs menu-tabs ng-tns-c1798067373-0">
              <li className="nav-item ng-tns-c1798067373-0">
                <div className="ng-tns-c1798067373-0 active nav-link">
                  {name}
                </div>
              </li>
              <li className="nav-item ng-tns-c1798067373-0">
                <div className="ng-tns-c1798067373-0 nav-link">
                  {" "}
                  Placed Bet (0){" "}
                </div>
              </li>
            </ul>
            <div className="pe-2 ng-tns-c3502172659-1">
              <span className="casino-rid ng-tns-c3502172659-1">
                <a className="d-block ng-tns-c3502172659-1">
                  <small className="ng-tns-c3502172659-1">Rules</small>
                </a>{" "}
                Round ID:{" "}
                <span className="ng-tns-c3502172659-1 ng-star-inserted">
                  {reqRes?.data?.data?.t1[0]?.mid || 0.0}
                </span>
              </span>
            </div>
          </div>
          <div className="casino-header ng-tns-c3502172659-1">
            <span className="casino-name ng-tns-c3502172659-1">
              {" "}
              Lucky 7 - A
              <a className="ms-1 d-xl-inline d-none ng-tns-c3502172659-1">
                <small className="ng-tns-c3502172659-1">Rules</small>
              </a>
            </span>
            <span className="casino-rid d-none d-xl-inline-block ng-tns-c3502172659-1">
              <small className="ng-tns-c3502172659-1">
                Round ID:{" "}
                <span className="ng-tns-c3502172659-1 ng-star-inserted">
                  {reqRes?.data?.data?.t1[0]?.mid || 0.0}
                </span>
              </small>
            </span>
          </div>

          <div className="ng-tns-c1798067373-0 ng-star-inserted">
            <div className="casino-video ng-tns-c1798067373-0">
              <div className="video-box-container ng-tns-c1798067373-0">
                <div className="casino-video-box ng-tns-c1798067373-0 ng-star-inserted">
                  <iframe
                    className="ng-tns-c1798067373-0"
                    src={CASINO_VIDEO_URL + CASINO_VIDEO.lucky7A}
                  />
                </div>
              </div>

              <Timer value={reqRes?.data?.data?.t1[0]?.autotime || 0} />
            </div>

            <div className="casino-detail ng-tns-c1798067373-0">
              <div className="casino-table ng-tns-c1798067373-0 ng-star-inserted">
                <div className="casino-table-full-box ng-tns-c1798067373-0">
                  <div className="lucky7low ng-tns-c1798067373-0 ng-star-inserted">
                    <div className="casino-odds text-center ng-tns-c1798067373-0">
                      {" "}
                      {reqRes?.data?.data?.t2[0]?.rate}{" "}
                    </div>
                    {reqRes?.data?.data?.t2[0]?.gstatus == 0 ? (
                      <div className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme suspended-box">
                        <span className="casino-odds ng-tns-c1798067373-0">
                          {" "}
                          <i className="fa fa-lock" />
                        </span>
                      </div>
                    ) : (
                      <>
                        <div
                          className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme"
                          onClick={() => {
                            handleModal(
                              reqRes?.data?.data?.t2[0]?.rate,
                              reqRes?.data?.data?.t2[0]?.nation,
                              1
                            );
                          }}
                        >
                          <span className="casino-odds ng-tns-c1798067373-0">
                            {" "}
                            {reqRes?.data?.data?.t2[0]?.nation}
                          </span>
                        </div>
                      </>
                    )}
                    <div className="casino-nation-book text-center ng-tns-c1798067373-0">
                      {" "}
                      {thirtyTwoCardStack.map(
                        (ele, index) =>
                          ele.number === 1 && (
                            <span
                              className={` ${
                                ele.number == 1 && ele.amount >= 0
                                  ? "success-1"
                                  : "zero"
                              }`}
                              key={index}
                            >
                              {ele.number == 1 && ele.amount}
                            </span>
                          )
                      )}{" "}
                    </div>
                  </div>

                  <div className="lucky7 ng-tns-c1798067373-0 ng-star-inserted">
                    <img
                      className="ng-tns-c1798067373-0"
                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/7.webp"
                    />
                  </div>

                  <div className="lucky7high ng-tns-c1798067373-0 ng-star-inserted">
                    <div className="casino-odds text-center ng-tns-c1798067373-0">
                      {reqRes?.data?.data?.t2[1]?.rate}
                    </div>
                    {reqRes?.data?.data?.t2[1]?.gstatus == 0 ? (
                      <div className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme suspended-box">
                        <span className="casino-odds ng-tns-c1798067373-0">
                          <i className="fa fa-lock" />
                        </span>
                      </div>
                    ) : (
                      <>
                        <div
                          className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme"
                          onClick={() => {
                            handleModal(
                              reqRes?.data?.data?.t2[1]?.rate,
                              reqRes?.data?.data?.t2[1]?.nation,
                              2
                            );
                          }}
                        >
                          <span className="casino-odds ng-tns-c1798067373-0">
                            {reqRes?.data?.data?.t2[1]?.nation}
                          </span>
                        </div>
                      </>
                    )}
                    <div className="casino-nation-book text-center ng-tns-c1798067373-0">
                      {thirtyTwoCardStack.map(
                        (ele) =>
                          ele.number === 2 && (
                            <span
                              className={` ${
                                ele.number == 2 && ele.amount >= 0
                                  ? "success-1"
                                  : "zero"
                              }`}
                            >
                              {ele.number == 2 && ele.amount}
                            </span>
                          )
                      )}
                    </div>
                  </div>

                  <div className="min_max_odds ng-tns-c1798067373-0 ng-star-inserted">
                    <span className="min_max_span ng-tns-c1798067373-0">
                      <b className="ng-tns-c1798067373-0">Min:</b>
                      <span className="ng-tns-c1798067373-0">
                        {" "}
                        {reqRes?.data?.data?.t2[0]?.min}
                      </span>
                    </span>
                    <span className="min_max_span ng-tns-c1798067373-0">
                      <b className="ng-tns-c1798067373-0">Max:</b>
                      <span className="ng-tns-c1798067373-0">
                        <span className="ng-tns-c1798067373-0 ng-star-inserted">
                          {" "}
                          {reqRes?.data?.data?.t2[0]?.max}{" "}
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
                <div className="casino-table-box mt-3 ng-tns-c1798067373-0">
                  <div className="casino-table-left-box ng-tns-c1798067373-0">
                    <div className="lucky7odds ng-tns-c1798067373-0 ng-star-inserted">
                      <div className="casino-odds text-center ng-tns-c1798067373-0">
                        {" "}
                        {reqRes?.data?.data?.t2[2]?.rate}
                      </div>
                      {reqRes?.data?.data?.t2[2]?.gstatus == 0 ? (
                        <div className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme suspended-box">
                          <span className="casino-odds ng-tns-c1798067373-0">
                            <i className="fa fa-lock" />
                          </span>
                        </div>
                      ) : (
                        <>
                          <div
                            className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme"
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[2]?.rate,
                                reqRes?.data?.data?.t2[2]?.nation,
                                3
                              );
                            }}
                          >
                            <span className="casino-odds ng-tns-c1798067373-0">
                              {reqRes?.data?.data?.t2[2]?.nation}
                            </span>
                          </div>
                        </>
                      )}
                      <div className="casino-nation-book text-center ng-tns-c1798067373-0">
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 3 && (
                              <span
                                className={` ${
                                  ele.number == 3 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}
                              >
                                {ele.number == 3 && ele.amount}
                              </span>
                            )
                        )}
                      </div>
                    </div>

                    <div className="lucky7odds ng-tns-c1798067373-0 ng-star-inserted">
                      <div className="casino-odds text-center ng-tns-c1798067373-0">
                        {reqRes?.data?.data?.t2[3]?.rate}
                      </div>
                      {reqRes?.data?.data?.t2[3]?.gstatus == 0 ? (
                        <div className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme suspended-box">
                          <span className="casino-odds ng-tns-c1798067373-0">
                            <i className="fa fa-lock" />
                          </span>
                        </div>
                      ) : (
                        <>
                          <div
                            className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme "
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[3]?.rate,
                                reqRes?.data?.data?.t2[3]?.nation,
                                4
                              );
                            }}
                          >
                            <span className="casino-odds ng-tns-c1798067373-0">
                              {reqRes?.data?.data?.t2[3]?.nation}
                            </span>
                          </div>
                        </>
                      )}
                      <div className="casino-nation-book text-center ng-tns-c1798067373-0">
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 4 && (
                              <span
                                className={` ${
                                  ele.number == 4 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}
                              >
                                {ele.number == 4 && ele.amount}
                              </span>
                            )
                        )}
                      </div>
                    </div>

                    <div className="min_max_odds ng-tns-c1798067373-0 ng-star-inserted">
                      <span className="min_max_span ng-tns-c1798067373-0">
                        <b className="ng-tns-c1798067373-0">Min:</b>
                        <span className="ng-tns-c1798067373-0">
                          {" "}
                          {reqRes?.data?.data?.t2[2]?.min}
                        </span>
                      </span>
                      <span className="min_max_span ng-tns-c1798067373-0">
                        <b className="ng-tns-c1798067373-0">Max:</b>
                        <span className="ng-tns-c1798067373-0">
                          <span className="ng-tns-c1798067373-0 ng-star-inserted">
                            {reqRes?.data?.data?.t2[2]?.max}
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="casino-table-right-box ng-tns-c1798067373-0">
                    <div className="lucky7odds ng-tns-c1798067373-0 ng-star-inserted">
                      <div className="casino-odds text-center ng-tns-c1798067373-0">
                        {reqRes?.data?.data?.t2[4]?.rate}
                      </div>
                      {reqRes?.data?.data?.t2[4]?.gstatus == 0 ? (
                        <div className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme suspended-box">
                          <span className="casino-odds ng-tns-c1798067373-0">
                            <span className="card-icon ms-1 ng-tns-c1798067373-0">
                              <span className="card-red ng-tns-c1798067373-0">
                                <i className="fa fa-lock" />
                              </span>
                            </span>
                          </span>
                        </div>
                      ) : (
                        <>
                          <div
                            className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme"
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[4]?.rate,
                                reqRes?.data?.data?.t2[4]?.nation,
                                5
                              );
                            }}
                          >
                            <span className="casino-odds ng-tns-c1798067373-0">
                              <span className="card-icon ms-1 ng-tns-c1798067373-0">
                                <span className="card-red ng-tns-c1798067373-0">
                                  <img
                                    className="ng-tns-c1798067373-0"
                                    src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                                  />
                                </span>
                              </span>
                              <span className="card-icon ms-1 ng-tns-c1798067373-0">
                                <span className="card-red ng-tns-c1798067373-0">
                                  <img
                                    className="ng-tns-c1798067373-0"
                                    src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                                  />
                                </span>
                              </span>
                            </span>
                          </div>
                        </>
                      )}
                      <div className="casino-nation-book text-center ng-tns-c1798067373-0">
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 5 && (
                              <span
                                className={` ${
                                  ele.number == 5 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}
                              >
                                {ele.number == 5 && ele.amount}
                              </span>
                            )
                        )}
                      </div>
                    </div>

                    <div className="lucky7odds ng-tns-c1798067373-0 ng-star-inserted">
                      <div className="casino-odds text-center ng-tns-c1798067373-0">
                        {reqRes?.data?.data?.t2[5]?.rate}
                      </div>
                      {reqRes?.data?.data?.t2[5]?.gstatus == 0 ? (
                        <div className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme suspended-box">
                          <span className="casino-odds ng-tns-c1798067373-0">
                            <span className="card-icon ms-1 ng-tns-c1798067373-0">
                              <span className="card-red ng-tns-c1798067373-0">
                                <i className="fa fa-lock" />
                              </span>
                            </span>
                          </span>
                        </div>
                      ) : (
                        <>
                          <div
                            className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme "
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[5]?.rate,
                                reqRes?.data?.data?.t2[5]?.nation,
                                6
                              );
                            }}
                          >
                            <span className="casino-odds ng-tns-c1798067373-0">
                              <span className="card-icon ms-1 ng-tns-c1798067373-0">
                                <span className="card-red ng-tns-c1798067373-0">
                                  <img
                                    className="ng-tns-c1798067373-0"
                                    src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                                  />
                                </span>
                              </span>
                              <span className="card-icon ms-1 ng-tns-c1798067373-0">
                                <span className="card-red ng-tns-c1798067373-0">
                                  <img
                                    className="ng-tns-c1798067373-0"
                                    src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                                  />
                                </span>
                              </span>
                            </span>
                          </div>
                        </>
                      )}
                      <div className="casino-nation-book text-center ng-tns-c1798067373-0">
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 6 && (
                              <span
                                className={` ${
                                  ele.number == 6 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}
                              >
                                {ele.number == 6 && ele.amount}
                              </span>
                            )
                        )}
                      </div>
                    </div>

                    <div className="min_max_odds ng-tns-c1798067373-0 ng-star-inserted">
                      <span className="min_max_span ng-tns-c1798067373-0">
                        <b className="ng-tns-c1798067373-0">Min:</b>
                        <span className="ng-tns-c1798067373-0">
                          {" "}
                          {reqRes?.data?.data?.t2[5]?.min}
                        </span>
                      </span>
                      <span className="min_max_span ng-tns-c1798067373-0">
                        <b className="ng-tns-c1798067373-0">Max:</b>
                        <span className="ng-tns-c1798067373-0">
                          <span className="ng-tns-c1798067373-0 ng-star-inserted">
                            {reqRes?.data?.data?.t2[5]?.max}
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="casino-table-full-box lucky7acards mt-3 ng-tns-c1798067373-0">
                  <div className="casino-odds w-100 text-center ng-tns-c1798067373-0">
                    {reqRes?.data?.data?.t2[6]?.rate}
                  </div>
                  {reqRes?.data?.data?.t2?.slice(6, 19)?.map((item, index) => (
                    <div
                      className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                      key={index}
                    >
                      {item.gstatus == 0 ? (
                        <div className="ng-tns-c1798067373-0 suspended-box">
                          <img
                            className="ng-tns-c1798067373-0 ng-star-inserted"
                            src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${
                              CARD_ARRAY[index]
                            }.webp`}
                            alt={`Card ${CARD_ARRAY[index]}`}
                          />
                        </div>
                      ) : (
                        <div
                          className="ng-tns-c1798067373-0"
                          onClick={() => {
                            handleModal(item?.rate, item?.nation, index + 7);
                          }}
                        >
                          <img
                            className="ng-tns-c1798067373-0 ng-star-inserted"
                            src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${
                              CARD_ARRAY[index]
                            }.webp`}
                            alt={`Card ${CARD_ARRAY[index]}`}
                          />
                        </div>
                      )}

                      <div className="casino-nation-book ng-tns-c1798067373-0">
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === index + 7 && (
                              <span
                                className={` ${
                                  ele.number == index + 7 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}
                              >
                                {ele.number == index + 7 && ele.amount}
                              </span>
                            )
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="min_max_odds ng-tns-c1798067373-0 ng-star-inserted">
                    <span className="min_max_span ng-tns-c1798067373-0">
                      <b className="ng-tns-c1798067373-0">Min:</b>
                      <span className="ng-tns-c1798067373-0">
                        {" "}
                        {reqRes?.data?.data?.t2[6]?.min}
                      </span>
                    </span>
                    <span className="min_max_span ng-tns-c1798067373-0">
                      <b className="ng-tns-c1798067373-0">Max:</b>
                      <span className="ng-tns-c1798067373-0">
                        <span className="ng-tns-c1798067373-0 ng-star-inserted">
                          {reqRes?.data?.data?.t2[6]?.max}
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <div className="ng-tns-c1798067373-0">
                <div className="casino-last-result-title ng-tns-c1798067373-0">
                  <span className="ng-tns-c1798067373-0">Last Result</span>
                  <span className="ng-tns-c1798067373-0">
                    <a className="ng-tns-c1798067373-0">View All</a>
                  </span>
                </div>
                <div className="casino-last-results ng-tns-c1798067373-0 ng-star-inserted">
                  {results?.data?.result?.map((result, index) => {
                    const buttonValue = reqRes?.data?.data?.t2.find(
                      (item) => item.sid === result?.result
                    );
                    return (
                      <span
                        className="ng-tns-c1798067373-0 result result-b ng-star-inserted"
                        key={index}
                      >
                        <span
                          className="ng-tns-c1798067373-0"
                          onClick={() => {
                            setMidRound(result.mid);
                            CasinoGameResult_API(result.mid);
                            setShowresultModal(true);
                          }}
                        >
                          {buttonValue ? <>{buttonValue?.nation[0]}</> : "T"}
                        </span>
                      </span>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="ng-tns-c1798067373-0 d-none matchbettable w-100 ng-star-inserted">
            <div className="table-responsive ng-tns-c1798067373-0">
              <table className="table ng-tns-c1798067373-0">
                <thead className="ng-tns-c1798067373-0">
                  <tr className="ng-tns-c1798067373-0">
                    <th className="ng-tns-c1798067373-0">Matched Bet</th>
                    <th className="text-end ng-tns-c1798067373-0">Odds</th>
                    <th className="text-end ng-tns-c1798067373-0">Stake</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
      <CasinoBetModal
        id={id}
        name={name}
        modalOpen={showModal}
        betDetails={betDetails}
        setbetDetails={setbetDetails}
        setShowModal={setShowModal}
        betHistory={history}
        realTime={realTime}
        setRealTimeData={setRealTimeData}
      />
    </div>
  );
}
