import React from "react";
import "./index.scoped.css";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import { CASINO_VIDEO } from "./CasinoGeneric";
export default function Card32B() {
  return (
    <div
      _ngcontent-ng-c1798067373=""
      className="center-main-container casino-page ng-tns-c1798067373-4 ng-star-inserted"
    >
      <div
        _ngcontent-ng-c1798067373=""
        className="center-container ng-tns-c1798067373-4"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-page-container ng-tns-c1798067373-4 cards32a cards32b"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-title-header-mobile d-xl-none ng-tns-c1798067373-4"
          >
            <ul
              _ngcontent-ng-c1798067373=""
              className="nav nav-tabs menu-tabs ng-tns-c1798067373-4"
            >
              <li
                _ngcontent-ng-c1798067373=""
                className="nav-item ng-tns-c1798067373-4"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-4 active nav-link"
                >
                  Game
                </div>
              </li>
              <li
                _ngcontent-ng-c1798067373=""
                className="nav-item ng-tns-c1798067373-4"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-4 nav-link"
                >
                  {" "}
                  Placed Bet (0){" "}
                </div>
              </li>
            </ul>
            <div
              _ngcontent-ng-c1798067373=""
              className="pe-2 ng-tns-c1798067373-4"
            >
              <span
                _ngcontent-ng-c1798067373=""
                className="casino-rid ng-tns-c1798067373-4"
              >
                <a
                  _ngcontent-ng-c1798067373=""
                  className="d-block ng-tns-c1798067373-4"
                >
                  <small
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-4"
                  >
                    Rules
                  </small>
                </a>{" "}
                Round ID:{" "}
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-4 ng-star-inserted"
                >
                  29.241909124142
                </span>
                {/**/}
              </span>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-header ng-tns-c1798067373-4"
          >
            <span
              _ngcontent-ng-c1798067373=""
              className="casino-name ng-tns-c1798067373-4"
            >
              {" "}
              32 Cards - B
              <a
                _ngcontent-ng-c1798067373=""
                className="ms-1 d-xl-inline d-none ng-tns-c1798067373-4"
              >
                <small
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-4"
                >
                  Rules
                </small>
              </a>
            </span>
            <span
              _ngcontent-ng-c1798067373=""
              className="casino-rid d-none d-xl-inline-block ng-tns-c1798067373-4"
            >
              <small
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-4"
              >
                Round ID:{" "}
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-4 ng-star-inserted"
                >
                  29.241909124142
                </span>
                {/**/}
              </small>
              {/**/}
            </span>
          </div>
          {/**/}
          {/**/}
          <div
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-4 ng-star-inserted"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-video ng-tns-c1798067373-4"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="video-box-container ng-tns-c1798067373-4"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-video-box ng-tns-c1798067373-4 ng-star-inserted"
                >
                  <iframe
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-4"
                    src={CASINO_VIDEO_URL + CASINO_VIDEO.thirtyTwoCardB}
                  />
                </div>
                {/**/}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-4 ng-star-inserted"
              >
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-video-cards ng-tns-c1798067373-4 ng-star-inserted"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-4 ng-star-inserted"
                  >
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                  </div>
                  {/**/}
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="mt-1 ng-tns-c1798067373-4"
                  >
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="mt-1 ng-tns-c1798067373-4"
                  >
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="mt-1 ng-tns-c1798067373-4"
                  >
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                  </div>
                </div>
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
              </div>
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="clock ng-tns-c1798067373-4"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="count-no ng-tns-c1798067373-4 ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-4"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="content ng-tns-c1798067373-4"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        style={{ position: "absolute" }}
                        className="ng-tns-c1798067373-4"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="flip ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="up ng-tns-c1798067373-4"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              1
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="down ng-tns-c1798067373-4"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                8
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        style={{ position: "absolute" }}
                        className="ng-tns-c1798067373-4"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="flip-card ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="flip-card-inner ng-tns-c1798067373-4 ng-trigger ng-trigger-flip"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="flip-card-front ng-tns-c1798067373-4"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="up ng-tns-c1798067373-4"
                              >
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  8
                                </div>
                              </div>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="flip-card-back ng-tns-c1798067373-4"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="down ng-tns-c1798067373-4"
                              >
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  <div
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-4"
                                  >
                                    1
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
                {/**/}
                <div
                  _ngcontent-ng-c1798067373=""
                  className="count-no ng-tns-c1798067373-4 ng-star-inserted"
                  style={{ marginRight: 80 }}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-4"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="content ng-tns-c1798067373-4"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        style={{ position: "absolute" }}
                        className="ng-tns-c1798067373-4"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="flip ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="up ng-tns-c1798067373-4"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              1
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="down ng-tns-c1798067373-4"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                1
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        style={{ position: "absolute" }}
                        className="ng-tns-c1798067373-4"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="flip-card ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="flip-card-inner ng-tns-c1798067373-4 ng-trigger ng-trigger-flip"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="flip-card-front ng-tns-c1798067373-4"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="up ng-tns-c1798067373-4"
                              >
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  1
                                </div>
                              </div>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="flip-card-back ng-tns-c1798067373-4"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="down ng-tns-c1798067373-4"
                              >
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  <div
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-4"
                                  >
                                    1
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
                {/**/}
              </div>
            </div>
            {/**/}
            {/**/}
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-detail ng-tns-c1798067373-4"
            >
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table ng-tns-c1798067373-4 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-table-box ng-tns-c1798067373-4"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-left-box ng-tns-c1798067373-4"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-header ng-tns-c1798067373-4"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-detail ng-tns-c1798067373-4"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="d-lg-none d-block ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min-max-dt20 ng-tns-c1798067373-4"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                Min:{" "}
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                {" "}
                                100 {/**/}
                                {/**/}
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                Max:{" "}
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                {" "}
                                500000 {/**/}
                                {/**/}
                              </span>
                            </span>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="d-lg-block d-none ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min_max_collapse ng-tns-c1798067373-4"
                          >
                            <a
                              _ngcontent-ng-c1798067373=""
                              data-bs-toggle="collapse"
                              href="#collapserange1"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapserange1"
                              className="minmax-btn ng-tns-c1798067373-4"
                            >
                              <i
                                _ngcontent-ng-c1798067373=""
                                className="fas fa-info-circle ng-tns-c1798067373-4"
                              />
                            </a>
                            <div
                              _ngcontent-ng-c1798067373=""
                              id="collapserange1"
                              className="range-collpase collapse ng-tns-c1798067373-4"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-0 ng-tns-c1798067373-4"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  Min:
                                </b>{" "}
                                100 {/**/}
                                {/**/}
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-1 ng-tns-c1798067373-4"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  Max:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  {" "}
                                  500000 {/**/}
                                  {/**/}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-4"
                      >
                        Back
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box lay ng-tns-c1798067373-4"
                      >
                        Lay
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-body ng-tns-c1798067373-4"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-4 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-4"
                          >
                            {" "}
                            Player 8{" "}
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4 text-success"
                            >
                              {" "}
                              0{" "}
                            </p>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-4 casino_odds_row"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box back ng-tns-c1798067373-4"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              12.2
                            </span>
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              783325{" "}
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box lay ng-tns-c1798067373-4"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              13.7
                            </span>
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              52075{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-4 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-4"
                          >
                            {" "}
                            Player 9{" "}
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4 text-success"
                            >
                              {" "}
                              0{" "}
                            </p>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-4 casino_odds_row"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box back ng-tns-c1798067373-4"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              5.95
                            </span>
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              629727{" "}
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box lay ng-tns-c1798067373-4"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              6.45
                            </span>
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              439208{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-4 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-4"
                          >
                            {" "}
                            Player 10{" "}
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4 text-success"
                            >
                              {" "}
                              0{" "}
                            </p>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-4 casino_odds_row"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box back ng-tns-c1798067373-4"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              3.2
                            </span>
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              743123{" "}
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box lay ng-tns-c1798067373-4"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              3.45
                            </span>
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              202626{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-4 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-4"
                          >
                            {" "}
                            Player 11{" "}
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4 text-success"
                            >
                              {" "}
                              0{" "}
                            </p>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-4 casino_odds_row"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box back ng-tns-c1798067373-4"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              2.08
                            </span>
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              617662{" "}
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box lay ng-tns-c1798067373-4"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              2.18
                            </span>
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              810697{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/**/}
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-left-box ng-tns-c1798067373-4"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-header ng-tns-c1798067373-4"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-detail ng-tns-c1798067373-4"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="d-lg-none d-block ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min-max-dt20 ng-tns-c1798067373-4"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                Min:{" "}
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                {" "}
                                100 {/**/}
                                {/**/}
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                Max:{" "}
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                {" "}
                                500000 {/**/}
                                {/**/}
                              </span>
                            </span>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="d-lg-block d-none ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min_max_collapse ng-tns-c1798067373-4"
                          >
                            <a
                              _ngcontent-ng-c1798067373=""
                              data-bs-toggle="collapse"
                              href="#collapserange1"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapserange1"
                              className="minmax-btn ng-tns-c1798067373-4"
                            >
                              <i
                                _ngcontent-ng-c1798067373=""
                                className="fas fa-info-circle ng-tns-c1798067373-4"
                              />
                            </a>
                            <div
                              _ngcontent-ng-c1798067373=""
                              id="collapserange1"
                              className="range-collpase collapse ng-tns-c1798067373-4"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-0 ng-tns-c1798067373-4"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  Min:
                                </b>{" "}
                                100 {/**/}
                                {/**/}
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-1 ng-tns-c1798067373-4"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  Max:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  {" "}
                                  500000 {/**/}
                                  {/**/}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-4"
                      >
                        Odd
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-4"
                      >
                        Even
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-body ng-tns-c1798067373-4"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-4"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-4"
                          >
                            Player 8
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino_odds_row ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-4 back casino-odds-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              1.97
                            </span>
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              1M{" "}
                            </p>
                          </div>
                          {/**/}
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-4 back casino-odds-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              1.97
                            </span>
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              752748{" "}
                            </p>
                          </div>
                          {/**/}
                          {/**/}
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-4"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-4"
                          >
                            Player 9
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino_odds_row ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-4 back casino-odds-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              1.97
                            </span>
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              297285{" "}
                            </p>
                          </div>
                          {/**/}
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-4 back casino-odds-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              1.97
                            </span>
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              244471{" "}
                            </p>
                          </div>
                          {/**/}
                          {/**/}
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-4"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-4"
                          >
                            Player 10
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino_odds_row ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-4 back casino-odds-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              1.97
                            </span>
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              1M{" "}
                            </p>
                          </div>
                          {/**/}
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-4 back casino-odds-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              1.97
                            </span>
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              943188{" "}
                            </p>
                          </div>
                          {/**/}
                          {/**/}
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-4"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-4"
                          >
                            Player 11
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino_odds_row ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-4 back casino-odds-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              1.97
                            </span>
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              179873{" "}
                            </p>
                          </div>
                          {/**/}
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-4 back casino-odds-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              1.97
                            </span>
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              147772{" "}
                            </p>
                          </div>
                          {/**/}
                          {/**/}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-left-box ng-tns-c1798067373-4"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-header ng-tns-c1798067373-4"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-detail ng-tns-c1798067373-4"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="d-lg-none d-block ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min-max-dt20 ng-tns-c1798067373-4"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                Min:{" "}
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                {" "}
                                100 {/**/}
                                {/**/}
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                Max:{" "}
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                {" "}
                                500000 {/**/}
                                {/**/}
                              </span>
                            </span>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="d-lg-block d-none ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min_max_collapse ng-tns-c1798067373-4"
                          >
                            <a
                              _ngcontent-ng-c1798067373=""
                              data-bs-toggle="collapse"
                              href="#collapserange1"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapserange1"
                              className="minmax-btn ng-tns-c1798067373-4"
                            >
                              <i
                                _ngcontent-ng-c1798067373=""
                                className="fas fa-info-circle ng-tns-c1798067373-4"
                              />
                            </a>
                            <div
                              _ngcontent-ng-c1798067373=""
                              id="collapserange1"
                              className="range-collpase collapse ng-tns-c1798067373-4"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-0 ng-tns-c1798067373-4"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  Min:
                                </b>{" "}
                                100 {/**/}
                                {/**/}
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-1 ng-tns-c1798067373-4"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  Max:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  {" "}
                                  500000 {/**/}
                                  {/**/}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-4"
                      >
                        Back
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box lay ng-tns-c1798067373-4"
                      >
                        Lay
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-body ng-tns-c1798067373-4"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-4 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-4"
                          >
                            {" "}
                            Any Three Card Black{" "}
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4 text-success"
                            >
                              {" "}
                              0{" "}
                            </p>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-4 casino_odds_row"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box back ng-tns-c1798067373-4"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              3.25
                            </span>
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              1M{" "}
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box lay ng-tns-c1798067373-4"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            />
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              800804{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-4 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-4"
                          >
                            {" "}
                            Any Three Card Red{" "}
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4 text-success"
                            >
                              {" "}
                              0{" "}
                            </p>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-4 casino_odds_row"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box back ng-tns-c1798067373-4"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              3.25
                            </span>
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              255724{" "}
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box lay ng-tns-c1798067373-4"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            />
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              779113{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-4 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-4"
                          >
                            {" "}
                            Two Black Two Red{" "}
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4 text-success"
                            >
                              {" "}
                              0{" "}
                            </p>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-4 casino_odds_row"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box back ng-tns-c1798067373-4"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              2.44
                            </span>
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              873961{" "}
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box lay ng-tns-c1798067373-4"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            />
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              430517{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/**/}
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-left-box cards32total ng-tns-c1798067373-4"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-header ng-tns-c1798067373-4"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-detail ng-tns-c1798067373-4"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="d-lg-none d-block ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min-max-dt20 ng-tns-c1798067373-4"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                Min:{" "}
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                {" "}
                                100 {/**/}
                                {/**/}
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                Max:{" "}
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                {" "}
                                500000 {/**/}
                                {/**/}
                              </span>
                            </span>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="d-lg-block d-none ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min_max_collapse ng-tns-c1798067373-4"
                          >
                            <a
                              _ngcontent-ng-c1798067373=""
                              data-bs-toggle="collapse"
                              href="#collapserange1"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapserange1"
                              className="minmax-btn ng-tns-c1798067373-4"
                            >
                              <i
                                _ngcontent-ng-c1798067373=""
                                className="fas fa-info-circle ng-tns-c1798067373-4"
                              />
                            </a>
                            <div
                              _ngcontent-ng-c1798067373=""
                              id="collapserange1"
                              className="range-collpase collapse ng-tns-c1798067373-4"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-0 ng-tns-c1798067373-4"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  Min:
                                </b>{" "}
                                100 {/**/}
                                {/**/}
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-1 ng-tns-c1798067373-4"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  Max:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  {" "}
                                  500000 {/**/}
                                  {/**/}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-body ng-tns-c1798067373-4"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-4 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-4"
                          >
                            {" "}
                            8 &amp; 9 Total{" "}
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4 text-success"
                            >
                              {" "}
                              0{" "}
                            </p>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-4 casino_odds_row"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box back w-100 ng-tns-c1798067373-4"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              1.97
                            </span>
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              427244{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-4 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-4"
                          >
                            {" "}
                            10 &amp; 11 Total{" "}
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4 text-success"
                            >
                              {" "}
                              0{" "}
                            </p>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-4 casino_odds_row"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box back w-100 ng-tns-c1798067373-4"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              1.97
                            </span>
                            <p
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              732559{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/**/}
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-full-box mt-3 card32numbers ng-tns-c1798067373-4"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="d-flex ng-tns-c1798067373-4"
                    >
                      <h4
                        _ngcontent-ng-c1798067373=""
                        className="w-100 text-center mb-2 ng-tns-c1798067373-4"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-4"
                        >
                          9.5
                        </b>
                      </h4>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="min_max_collapse mt-1 me-1 ng-tns-c1798067373-4"
                      >
                        <a
                          _ngcontent-ng-c1798067373=""
                          data-bs-toggle="collapse"
                          href="#collapserange5"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapserange4"
                          className="minmax-btn ng-tns-c1798067373-4"
                        >
                          <i
                            _ngcontent-ng-c1798067373=""
                            className="fas fa-info-circle ng-tns-c1798067373-4"
                          />
                        </a>
                        <div
                          _ngcontent-ng-c1798067373=""
                          id="collapserange5"
                          className="range-collpase collapse ng-tns-c1798067373-4"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-0 ng-tns-c1798067373-4"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              Min:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              100 {/**/}
                              {/**/}
                            </span>
                          </span>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-1 ng-tns-c1798067373-4"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              Max:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4"
                            >
                              {" "}
                              500000 {/**/}
                              {/**/}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-4 card32numbers-container"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-4"
                        >
                          {" "}
                          1
                        </span>
                        <p
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-4 text-success"
                        >
                          {" "}
                          0{" "}
                        </p>
                      </div>
                      {/**/}
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-4"
                        >
                          {" "}
                          2
                        </span>
                        <p
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-4 text-success"
                        >
                          {" "}
                          0{" "}
                        </p>
                      </div>
                      {/**/}
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-4"
                        >
                          {" "}
                          3
                        </span>
                        <p
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-4 text-success"
                        >
                          {" "}
                          0{" "}
                        </p>
                      </div>
                      {/**/}
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-4"
                        >
                          {" "}
                          4
                        </span>
                        <p
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-4 text-success"
                        >
                          {" "}
                          0{" "}
                        </p>
                      </div>
                      {/**/}
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-4"
                        >
                          {" "}
                          5
                        </span>
                        <p
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-4 text-success"
                        >
                          {" "}
                          0{" "}
                        </p>
                      </div>
                      {/**/}
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-4"
                        >
                          {" "}
                          6
                        </span>
                        <p
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-4 text-success"
                        >
                          {" "}
                          0{" "}
                        </p>
                      </div>
                      {/**/}
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-4"
                        >
                          {" "}
                          7
                        </span>
                        <p
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-4 text-success"
                        >
                          {" "}
                          0{" "}
                        </p>
                      </div>
                      {/**/}
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-4"
                        >
                          {" "}
                          8
                        </span>
                        <p
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-4 text-success"
                        >
                          {" "}
                          0{" "}
                        </p>
                      </div>
                      {/**/}
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-4"
                        >
                          {" "}
                          9
                        </span>
                        <p
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-4 text-success"
                        >
                          {" "}
                          0{" "}
                        </p>
                      </div>
                      {/**/}
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-4"
                        >
                          {" "}
                          0
                        </span>
                        <p
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-4 text-success"
                        >
                          {" "}
                          0{" "}
                        </p>
                      </div>
                      {/**/}
                      {/**/}
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="remark text-right pr-2 w-100 ng-tns-c1798067373-4"
                />
              </div>
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-4"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-last-result-title ng-tns-c1798067373-4"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-4"
                  >
                    Last Result
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-4"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-4"
                    >
                      View All
                    </a>
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-last-results ng-tns-c1798067373-4 ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-4 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-4"
                    >
                      {" "}
                      10
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-4 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-4"
                    >
                      {" "}
                      11
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-4 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-4"
                    >
                      {" "}
                      11
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-4 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-4"
                    >
                      {" "}
                      11
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-4 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-4"
                    >
                      {" "}
                      10
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-4 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-4"
                    >
                      {" "}
                      11
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-4 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-4"
                    >
                      {" "}
                      11
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-4 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-4"
                    >
                      {" "}
                      11
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-4 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-4"
                    >
                      {" "}
                      11
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-4 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-4"
                    >
                      {" "}
                      10
                    </span>
                  </span>
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                </div>
                {/**/}
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-4 d-none matchbettable w-100 ng-star-inserted"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="table-responsive ng-tns-c1798067373-4"
            >
              <table
                _ngcontent-ng-c1798067373=""
                className="table ng-tns-c1798067373-4"
              >
                <thead
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-4"
                >
                  <tr
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-4"
                  >
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-4"
                    >
                      Matched Bet
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-4"
                    >
                      Odds
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-4"
                    >
                      Stake
                    </th>
                  </tr>
                </thead>
                {/**/}
              </table>
            </div>
          </div>
          {/**/}
          {/**/}
        </div>
        {/**/}
      </div>
      <div
        _ngcontent-ng-c1798067373=""
        className="sidebar right-sidebar casino-right-sidebar ng-tns-c1798067373-4"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="sidebar-box place-bet-container d-xl-block d-none ng-tns-c1798067373-4"
        >
          {/**/}
          <div
            _ngcontent-ng-c1798067373=""
            className="sidebar-title ng-tns-c1798067373-4"
          >
            <h4 _ngcontent-ng-c1798067373="" className="ng-tns-c1798067373-4">
              Place Bet
            </h4>
          </div>
          {/**/}
        </div>
        <div
          _ngcontent-ng-c1798067373=""
          className="sidebar-box my-bet-container d-xl-block d-none ng-tns-c1798067373-4"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="sidebar-title ng-tns-c1798067373-4"
          >
            <h4 _ngcontent-ng-c1798067373="" className="ng-tns-c1798067373-4">
              My Bet
            </h4>
            <a
              _ngcontent-ng-c1798067373=""
              className="sideviewmore ng-tns-c1798067373-4"
            >
              View more
            </a>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="my-bets ng-tns-c1798067373-4"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="table-responsive w-100 ng-tns-c1798067373-4"
            >
              <table
                _ngcontent-ng-c1798067373=""
                className="table ng-tns-c1798067373-4"
              >
                <thead
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-4"
                >
                  <tr
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-4"
                  >
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-4"
                    >
                      Matched Bet
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-4"
                    >
                      Odds
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-4"
                    >
                      Stake
                    </th>
                  </tr>
                </thead>
                {/**/}
              </table>
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c1798067373=""
          className="sidebar-box sidebar-box-rules my-bet-container ng-tns-c1798067373-4"
        >
          <app-casino-game-rules
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-4"
            _nghost-ng-c1761710207=""
          >
            {/**/}
            {/**/}
            {/**/}
            {/**/}
          </app-casino-game-rules>
        </div>
      </div>
    </div>
  );
}
