import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import { CASINO_VIDEO } from "./CasinoGeneric";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import { fetchThirtyTwoCardStack } from "../../redux/features/thirtyTwoCardGameSlice";
import { API } from "../../service/API";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import CasinoBetModal from "./CasinoBetModal";
import Timer from "./components/Timer";

export default function Poker1Day() {
  const id = 13;
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const uid = user.data.resultid;
  console.log("id1", user);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [midRound, setMidRound] = useState(0);
  const [showresultModal, setShowresultModal] = useState(false);
  const [trophy, setTrophy] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [realTime, setRealTimeData] = useState(false);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [], t3: [] });
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const [betDetails, setbetDetails] = useState({});
  const [history, setHistory] = useState([]);
  const [betIndex, setBetIndex] = useState("");

  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      console.log("CasinoGameResult 32:", response);
      setCasinoGameResult(response?.data?.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const SOCKET_URL = "https://dreamwin-socket.nakshtech.info/";

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("TeenPatti socket1==>", data);
      if (data && data?.length > 0) {
        const parsedData = data?.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item?.reqResponse),
          resultUrlResponse: JSON.parse(item?.resultUrlResponse),
        }));

        console.log("32cardA", parsedData[0]?.reqResponse);
        setResults(parsedData[0]?.reqResponse);
        setreqRes(parsedData[0]?.reqResponse);
        setName(parsedData[0]?.name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  const handleModalPopUp = () => {
    setShowModal(!showModal);
  };

  const handleModal = (value, nation, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nation: nation,
      number: no,
    });
    handleModalPopUp(true);
    setRealTimeData(true);
  };

  function checkValidthree() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t3.filter(
        (item) => item.sid === betIndex
      );

      // console.log("validcheck", filteredData);
      // console.log("filterpoker3", filteredData, betDetails);
      filteredData[0]?.gstatus === "ACTIVE" &&
      betDetails?.multipler === filteredData[0].nation
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    if (reqRes?.data?.data?.t2) {
      // checkValid();
    } else if (reqRes?.data?.data?.t3) {
      checkValidthree();
    }
  }, [showModal, realTime, reqRes?.data?.data, betIndex]);

  useEffect(() => {
    console.log("id2", id);

    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);

    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      // console.log("===Get", data);
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  async function ResultScheduler_API() {
    try {
      let res = await API.post(
        "/Schulder_7CareGameLive",
        {
          gameid: id,
          username: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t2[0]?.mid]);

  return (
    <div className="center-main-container casino-page ng-tns-c1798067373-1 ng-star-inserted">
      <div className="center-container ng-tns-c1798067373-1">
        <div className="casino-page-container ng-tns-c1798067373-1 cards32a poker-20 Poker_oneday">
          <div className="casino-title-header-mobile d-xl-none ng-tns-c1798067373-1">
            <ul className="nav nav-tabs menu-tabs ng-tns-c1798067373-1">
              <li className="nav-item ng-tns-c1798067373-1">
                <div className="ng-tns-c1798067373-1 active nav-link">Game</div>
              </li>
              <li className="nav-item ng-tns-c1798067373-1">
                <div className="ng-tns-c1798067373-1 nav-link">
                  {" "}
                  Placed Bet (0){" "}
                </div>
              </li>
            </ul>
            <div className="pe-2 ng-tns-c1798067373-1">
              <span className="casino-rid ng-tns-c1798067373-1">
                <a className="d-block ng-tns-c1798067373-1">
                  <small className="ng-tns-c1798067373-1">Rules</small>
                </a>{" "}
                Round ID:{" "}
                <span className="ng-tns-c1798067373-1 ng-star-inserted">
                  {reqRes?.data?.data?.t1[0]?.mid || 0.0}
                </span>
                {/**/}
              </span>
            </div>
          </div>
          <div className="casino-header ng-tns-c1798067373-1">
            <span className="casino-name ng-tns-c1798067373-1">
              {" "}
              1 Day Poker
              <a className="ms-1 d-xl-inline d-none ng-tns-c1798067373-1">
                <small className="ng-tns-c1798067373-1">Rules</small>
              </a>
            </span>
            <span className="casino-rid d-none d-xl-inline-block ng-tns-c1798067373-1">
              <small className="ng-tns-c1798067373-1">
                Round ID:{" "}
                <span className="ng-tns-c1798067373-1 ng-star-inserted">
                  13.241909122052
                </span>
                {/**/}
              </small>
              <span className="ng-tns-c1798067373-1 ng-star-inserted">
                {" "}
                | Min: 100 {/**/}
                {/**/} | Max: 300000 {/**/}
                {/**/}
              </span>
              {/**/}
            </span>
          </div>
          {/**/}
          {/**/}
          <div className="ng-tns-c1798067373-1 ng-star-inserted">
            <div className="casino-video ng-tns-c1798067373-1">
              <div className="video-box-container ng-tns-c1798067373-1">
                <div className="casino-video-box ng-tns-c1798067373-1 ng-star-inserted">
                  <iframe
                    className="ng-tns-c1798067373-1"
                    src={CASINO_VIDEO_URL + CASINO_VIDEO.poker1Day}
                  />
                </div>
                {/**/}
              </div>
              <div className="ng-tns-c1798067373-1 ng-star-inserted">
                {/**/}
                {/**/}
                {/**/}
                <div className="casino-video-cards ng-tns-c1798067373-1 ng-star-inserted">
                  <div className="mb-lg-2 mb-1 d-flex justify-content-between align-items-center ng-tns-c1798067373-1">
                    <div className="ng-tns-c1798067373-1">
                      <h5 className="ng-tns-c1798067373-1">PLAYER A</h5>
                      <div className="flip-card-container ng-tns-c1798067373-1">
                        <div className="me-lg-2 me-1 ng-tns-c1798067373-1 ng-star-inserted">
                          <img
                            className="ng-tns-c1798067373-1"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/AHH.webp"
                          />
                        </div>
                        <div className="me-lg-2 me-1 ng-tns-c1798067373-1 ng-star-inserted">
                          <img
                            className="ng-tns-c1798067373-1"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/6SS.webp"
                          />
                        </div>
                        {/**/}
                      </div>
                    </div>
                    <div className="ng-tns-c1798067373-1">
                      <h5 className="text-end ng-tns-c1798067373-1">
                        PLAYER B
                      </h5>
                      <div className="flip-card-container ng-tns-c1798067373-1">
                        <div className="ms-lg-2 ms-1 ng-tns-c1798067373-1 ng-star-inserted">
                          <img
                            className="ng-tns-c1798067373-1"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/7CC.webp"
                          />
                        </div>
                        <div className="ms-lg-2 ms-1 ng-tns-c1798067373-1 ng-star-inserted">
                          <img
                            className="ng-tns-c1798067373-1"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/8DD.webp"
                          />
                        </div>
                        {/**/}
                      </div>
                    </div>
                  </div>
                  <h5 className="ng-tns-c1798067373-1">BOARD</h5>
                  <div className="flip-card-container ng-tns-c1798067373-1">
                    <div className="me-lg-2 me-1 mb-1 ng-tns-c1798067373-1 ng-star-inserted">
                      <img
                        className="ng-tns-c1798067373-1"
                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/2CC.webp"
                      />
                    </div>
                    <div className="me-lg-2 me-1 mb-1 ng-tns-c1798067373-1 ng-star-inserted">
                      <img
                        className="ng-tns-c1798067373-1"
                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/8HH.webp"
                      />
                    </div>
                    <div className="me-lg-2 me-1 mb-1 ng-tns-c1798067373-1 ng-star-inserted">
                      <img
                        className="ng-tns-c1798067373-1"
                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/10HH.webp"
                      />
                    </div>
                    <div className="me-lg-2 me-1 mb-1 ng-tns-c1798067373-1 ng-star-inserted">
                      <img
                        className="ng-tns-c1798067373-1"
                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/JSS.webp"
                      />
                    </div>
                    <div className="me-lg-2 me-1 mb-1 ng-tns-c1798067373-1 ng-star-inserted">
                      <img
                        className="ng-tns-c1798067373-1"
                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/1.webp"
                      />
                    </div>
                    {/**/}
                  </div>
                </div>

                {/**/}
              </div>
              {/**/}
              <Timer value={reqRes?.data?.data?.t1[0]?.autotime || 0} />

           
            </div>
            {/**/}
            {/**/}
            <div className="casino-detail ng-tns-c1798067373-1">
              <div className="casino-table ng-tns-c1798067373-1 ng-star-inserted">
                <div className="casino-table-box ng-tns-c1798067373-1">
                  <div className="casino-table-left-box ng-tns-c1798067373-1">
                    <div className="casino-table-header ng-tns-c1798067373-1">
                      <div className="casino-nation-detail ng-tns-c1798067373-1">
                        <div className="min_max_odds justify-content-start d-lg-none d-flex ng-tns-c1798067373-1">
                          <span className="min_max_span ms-0 ng-tns-c1798067373-1">
                            <b className="ng-tns-c1798067373-1">Min:</b>
                            <span className="ng-tns-c1798067373-1">
                              {" "}
                              100 {/**/}
                              {/**/}
                            </span>
                          </span>
                          <span className="min_max_span ms-1 ng-tns-c1798067373-1">
                            <b className="ng-tns-c1798067373-1">Max:</b>
                            <span className="ng-tns-c1798067373-1">
                              {" "}
                              300000 {/**/}
                              {/**/}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="casino-odds-box back ng-tns-c1798067373-1">
                        BACK
                      </div>
                      <div className="casino-odds-box lay ng-tns-c1798067373-1">
                        Lay
                      </div>
                    </div>
                    <div className="casino-table-body ng-tns-c1798067373-1">
                      <div className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted">
                        <div className="casino-nation-detail ng-tns-c1798067373-1">
                          <div className="casino-nation-name ng-tns-c1798067373-1">
                            {" "}
                            {reqRes?.data?.data?.t2[0]?.nation}
                            <p className="ng-tns-c1798067373-1">
                              {" "}
                              {thirtyTwoCardStack.map(
                                (ele) =>
                                  ele.number === 1 && (
                                    <span
                                      style={{
                                        color:
                                          ele.amount >= 0 ? "green" : "red",
                                      }}
                                    >
                                      {ele.number == 1 && ele.amount}
                                    </span>
                                  )
                              )}
                            </p>
                          </div>
                        </div>
                        {reqRes?.data?.data?.t2[0]?.gstatus == "SUSPENDED" ||
                        reqRes?.data?.data?.t2[0]?.gstatus == "CLOSED" ? (
                          <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box lay ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="ng-tns-c1798067373-5 casino_odds_row ">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span
                                className="casino-odds ng-tns-c1798067373-5"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[0]?.b1,
                                    reqRes?.data?.data?.t2[0]?.nation,
                                    1
                                  );
                                }}
                              >
                                {reqRes?.data?.data?.t2[0]?.b1}
                              </span>
                              <p className="ng-tns-c1798067373-5"> 1M </p>
                            </div>
                            <div
                              className="casino-odds-box lay ng-tns-c1798067373-5"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[0]?.l1,
                                  reqRes?.data?.data?.t2[0]?.nation,
                                  3
                                );
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-5">
                                {reqRes?.data?.data?.t2[0]?.l1}
                              </span>
                              <p className="ng-tns-c1798067373-5"> 0 </p>
                            </div>
                          </div>
                        )}
                        {/* <div className="ng-tns-c1798067373-1 casino_odds_row suspended-box">
                          <div className="casino-odds-box back ng-tns-c1798067373-1">
                            <span className="casino-odds ng-tns-c1798067373-1">
                              0
                            </span>
                            <p className="ng-tns-c1798067373-1"> 0 </p>
                          </div>
                          <div className="casino-odds-box lay ng-tns-c1798067373-1">
                            <span className="casino-odds ng-tns-c1798067373-1">
                              0
                            </span>
                            <p className="ng-tns-c1798067373-1"> 0 </p>
                          </div>
                        </div> */}
                      </div>
                      {/**/}
                      {/**/}
                      <div className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted">
                        <div className="casino-nation-detail ng-tns-c1798067373-1">
                          <div className="casino-nation-name ng-tns-c1798067373-1">
                            {" "}
                            {reqRes?.data?.data?.t2[1]?.nation}
                            <p className="ng-tns-c1798067373-1">
                              {" "}
                              {thirtyTwoCardStack.map(
                                (ele) =>
                                  ele.number === 2 && (
                                    <span
                                      style={{
                                        color:
                                          ele.number == 2 && ele.amount >= 0
                                            ? "green"
                                            : "red",
                                      }}
                                    >
                                      {ele.number == 2 && ele.amount}
                                    </span>
                                  )
                              )}
                            </p>
                          </div>
                        </div>
                        {reqRes?.data?.data?.t2[1]?.gstatus == "SUSPENDED" ||
                        reqRes?.data?.data?.t2[1]?.gstatus == "CLOSED" ? (
                          <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box lay ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="ng-tns-c1798067373-5 casino_odds_row ">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span
                                className="casino-odds ng-tns-c1798067373-5"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[1]?.b1,
                                    reqRes?.data?.data?.t2[1]?.nation,
                                    2
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                                }}
                              >
                                {reqRes?.data?.data?.t2[1]?.b1}
                              </span>
                              <p className="ng-tns-c1798067373-5"> 1M </p>
                            </div>
                            <div
                              className="casino-odds-box lay ng-tns-c1798067373-5"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[1]?.l1,
                                  reqRes?.data?.data?.t2[1]?.nation,
                                  4
                                );
                                setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-5">
                                {reqRes?.data?.data?.t2[1]?.l1}
                              </span>
                              <p className="ng-tns-c1798067373-5"> 0 </p>
                            </div>
                          </div>
                        )}
                      </div>

                      {/**/}
                    </div>
                  </div>
                  <div className="casino-table-left-box ng-tns-c1798067373-1">
                    <div className="market-title d-lg-none d-block ng-tns-c1798067373-1">
                      <span className="ng-tns-c1798067373-1">Bonus Bet</span>
                    </div>
                    <div className="casino-table-header ng-tns-c1798067373-1">
                      <div className="casino-nation-detail ng-tns-c1798067373-1">
                        <div className="min_max_odds justify-content-start d-lg-none d-flex ng-tns-c1798067373-1">
                          <span className="min_max_span ms-0 ng-tns-c1798067373-1">
                            <b className="ng-tns-c1798067373-1">Min:</b>
                            <span className="ng-tns-c1798067373-1">
                              {" "}
                              100 {/**/}
                              {/**/}
                            </span>
                          </span>
                          <span className="min_max_span ms-1 ng-tns-c1798067373-1">
                            <b className="ng-tns-c1798067373-1">Max:</b>
                            <span className="ng-tns-c1798067373-1">
                              {" "}
                              300000 {/**/}
                              {/**/}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="casino-odds-box back ng-tns-c1798067373-1">
                        BACK
                      </div>
                      <div className="casino-odds-box back ng-tns-c1798067373-1">
                        BACK
                      </div>
                    </div>
                    <div className="casino-table-body ng-tns-c1798067373-1">
                      <div className="casino-table-row ng-tns-c1798067373-1">
                        <div className="casino-nation-detail ng-tns-c1798067373-1">
                          <div className="casino-nation-name ng-tns-c1798067373-1">
                            Player A
                          </div>
                        </div>
                        {reqRes?.data?.data?.t3[0]?.gstatus == "SUSPENDED" ||
                        reqRes?.data?.data?.t3[0]?.gstatus == "CLOSED" ? (
                          <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box lay ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="ng-tns-c1798067373-5 casino_odds_row ">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span
                                className="casino-odds ng-tns-c1798067373-5"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t3[0]?.b1,
                                    reqRes?.data?.data?.t3[0]?.nation,
                                    2
                                  );
                                  setBetIndex(reqRes?.data?.data?.t3[0]?.sid);
                                }}
                              >
                                {reqRes?.data?.data?.t3[0]?.nation}
                              </span>
                              <p className="ng-tns-c1798067373-5">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 3 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 3 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                            <div
                              className="casino-odds-box lay ng-tns-c1798067373-5"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t3[1]?.b1,
                                  reqRes?.data?.data?.t3[1]?.nation,
                                  4
                                );
                                setBetIndex(reqRes?.data?.data?.t3[1]?.sid);
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-5">
                                {reqRes?.data?.data?.t3[1]?.nation}
                              </span>
                              <p className="ng-tns-c1798067373-5">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 4 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 4 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                          </div>
                        )}
                        {/* <div className="ng-tns-c1798067373-1 casino_odds_row suspended-box">
                          <div className="casino-odds-box back ng-tns-c1798067373-1">
                            <span className="casino-odds ng-tns-c1798067373-1">
                              Player A 2 card Bonu
                            </span>
                            <p className="ng-tns-c1798067373-1"> 0 </p>
                          </div>
                          <div className="casino-odds-box back ng-tns-c1798067373-1">
                            <span className="casino-odds ng-tns-c1798067373-1">
                              Player A 7 card bonu
                            </span>
                            <p className="ng-tns-c1798067373-1"> 0 </p>
                          </div>
                        </div> */}
                      </div>
                      <div className="casino-table-row ng-tns-c1798067373-1">
                        <div className="casino-nation-detail ng-tns-c1798067373-1">
                          <div className="casino-nation-name ng-tns-c1798067373-1">
                            Player B{" "}
                          </div>
                        </div>
                        {reqRes?.data?.data?.t3[2]?.gstatus == "SUSPENDED" ||
                        reqRes?.data?.data?.t3[2]?.gstatus == "CLOSED" ? (
                          <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box lay ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="ng-tns-c1798067373-5 casino_odds_row ">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span
                                className="casino-odds ng-tns-c1798067373-5"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t3[2]?.b1,
                                    reqRes?.data?.data?.t3[2]?.nation,
                                    5
                                  );
                                  setBetIndex(reqRes?.data?.data?.t3[2]?.sid);
                                }}
                              >
                                {reqRes?.data?.data?.t3[2]?.nation}
                              </span>
                              <p className="ng-tns-c1798067373-5">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 5 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 5 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                            <div
                              className="casino-odds-box lay ng-tns-c1798067373-5"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t3[3]?.l1,
                                  reqRes?.data?.data?.t3[3]?.nation,
                                  6
                                );
                                setBetIndex(reqRes?.data?.data?.t3[3]?.sid);
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-5">
                                {reqRes?.data?.data?.t3[3]?.nation}
                              </span>
                              <p className="ng-tns-c1798067373-5">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 6 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 6 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="remark w-100 text-right pr-2 ng-tns-c1798067373-1" />
              </div>

              <div className="ng-tns-c1798067373-1">
                <div className="casino-last-result-title ng-tns-c1798067373-1">
                  <span className="ng-tns-c1798067373-1">Last Result</span>
                  <span className="ng-tns-c1798067373-1">
                    <a className="ng-tns-c1798067373-1">View All</a>
                  </span>
                </div>
                <div className="casino-last-results ng-tns-c1798067373-1 ng-star-inserted">
                  <span className="ng-tns-c1798067373-1 result result-a ng-star-inserted">
                    <span className="ng-tns-c1798067373-1"> A</span>
                  </span>
                  <span className="ng-tns-c1798067373-1 result ng-star-inserted">
                    <span className="ng-tns-c1798067373-1"> T</span>
                  </span>
                  <span className="ng-tns-c1798067373-1 result result-b ng-star-inserted">
                    <span className="ng-tns-c1798067373-1"> B</span>
                  </span>
                  <span className="ng-tns-c1798067373-1 result result-a ng-star-inserted">
                    <span className="ng-tns-c1798067373-1"> A</span>
                  </span>
                  <span className="ng-tns-c1798067373-1 result result-b ng-star-inserted">
                    <span className="ng-tns-c1798067373-1"> B</span>
                  </span>
                  <span className="ng-tns-c1798067373-1 result result-b ng-star-inserted">
                    <span className="ng-tns-c1798067373-1"> B</span>
                  </span>
                  <span className="ng-tns-c1798067373-1 result result-b ng-star-inserted">
                    <span className="ng-tns-c1798067373-1"> B</span>
                  </span>
                  <span className="ng-tns-c1798067373-1 result result-a ng-star-inserted">
                    <span className="ng-tns-c1798067373-1"> A</span>
                  </span>
                  <span className="ng-tns-c1798067373-1 result result-a ng-star-inserted">
                    <span className="ng-tns-c1798067373-1"> A</span>
                  </span>
                  <span className="ng-tns-c1798067373-1 result result-a ng-star-inserted">
                    <span className="ng-tns-c1798067373-1"> A</span>
                  </span>
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                </div>
                {/**/}
              </div>
            </div>
          </div>
          <div className="ng-tns-c1798067373-1 d-none matchbettable w-100 ng-star-inserted">
            <div className="table-responsive ng-tns-c1798067373-1">
              <table className="table ng-tns-c1798067373-1">
                <thead className="ng-tns-c1798067373-1">
                  <tr className="ng-tns-c1798067373-1">
                    <th className="ng-tns-c1798067373-1">Matched Bet</th>
                    <th className="text-end ng-tns-c1798067373-1">Odds</th>
                    <th className="text-end ng-tns-c1798067373-1">Stake</th>
                  </tr>
                </thead>
                {/**/}
              </table>
            </div>
          </div>
          {/**/}
          {/**/}
        </div>
        {/**/}
      </div>
      <CasinoBetModal
                id={id}
                name={name}
                modalOpen={showModal}
                betDetails={betDetails}
                setbetDetails={setbetDetails}
                setShowModal={setShowModal}
                betHistory={history}
                realTime={realTime}
                setRealTimeData={setRealTimeData}
              />
    </div>
  );
}
