import React, { useEffect, useState } from "react";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import { CASINO_VIDEO } from "./CasinoGeneric";
import "./index.scoped.css";
import { fetchThirtyTwoCardStack } from "../../redux/features/thirtyTwoCardGameSlice";
import { API } from "../../service/API";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import CasinoBetModal from "./CasinoBetModal";
import Timer from "./components/Timer";

export default function DragonTigerOneDay() {
  const id = 7;
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const uid = user.data.resultid;
  console.log("id1", user);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [midRound, setMidRound] = useState(0);
  const [showresultModal, setShowresultModal] = useState(false);
  const [trophy, setTrophy] = useState("");
  const [betIndex, setBetIndex] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [realTime, setRealTimeData] = useState(false);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const [betDetails, setbetDetails] = useState({});
  const [history, setHistory] = useState([]);

  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      console.log("CasinoGameResult 32:", response);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const SOCKET_URL = "https://dreamwin-socket.nakshtech.info/";

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("TeenPatti socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        console.log("onedaydragontiger", parsedData[0].reqResponse);
        setResults(parsedData[0].reqResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);
  const handleModal = (value, nat, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nat: nat,
      number: no,
    });
    setShowModal(true);
    setRealTimeData(true);
  };
  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.nat === betDetails.nat
      );
      console.log("filter", filteredData, betDetails?.multipler);
      filteredData[0]?.gstatus === "ACTIVE" &&
      (betDetails?.multipler === filteredData[0].l1 ||
        betDetails?.multipler === filteredData[0].b1)
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }
  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  useEffect(() => {
    console.log("id2", id);

    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);

    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      // console.log("===Get", data);
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  async function ResultScheduler_API() {
    try {
      let res = await API.post(
        "/Schulder_7CareGameLive",
        {
          gameid: id,
          username: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  // const findCardImage = (cardId) => {
  //   const card = card52War.find((card) => card.id === cardId);
  //   return card ? card.src : null;
  // };

  // const parseCardData = (cardData) => {
  //   return cardData?.split(",").map((card) => {
  //     const cardName = card.trim(); // Extract card name and trim any whitespace
  //     return cardName;
  //   });
  // };

  // const arrayData = parseCardData(reqRes?.data?.data?.t1[0]?.desc ?? "");
  // console.log("arr", arrayData);

  return (
    <div
      _ngcontent-ng-c1798067373=""
      className="center-main-container casino-page ng-tns-c1798067373-6 ng-star-inserted"
    >
      <div
        _ngcontent-ng-c1798067373=""
        className="center-container ng-tns-c1798067373-6"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-page-container ng-tns-c1798067373-6 dt1day"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-title-header-mobile d-xl-none ng-tns-c1798067373-6"
          >
            <ul
              _ngcontent-ng-c1798067373=""
              className="nav nav-tabs menu-tabs ng-tns-c1798067373-6"
            >
              <li
                _ngcontent-ng-c1798067373=""
                className="nav-item ng-tns-c1798067373-6"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-6 active nav-link"
                >
                  Game
                </div>
              </li>
              <li
                _ngcontent-ng-c1798067373=""
                className="nav-item ng-tns-c1798067373-6"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-6 nav-link"
                >
                  {" "}
                  Placed Bet (0){" "}
                </div>
              </li>
            </ul>
            <div
              _ngcontent-ng-c1798067373=""
              className="pe-2 ng-tns-c1798067373-6"
            >
              <span
                _ngcontent-ng-c1798067373=""
                className="casino-rid ng-tns-c1798067373-6"
              >
                <a
                  _ngcontent-ng-c1798067373=""
                  className="d-block ng-tns-c1798067373-6"
                >
                  <small
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    Rules
                  </small>
                </a>{" "}
                Round ID:{" "}
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-6 ng-star-inserted"
                >
                  28.241909124748
                </span>
                {/**/}
              </span>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-header ng-tns-c1798067373-6"
          >
            <span
              _ngcontent-ng-c1798067373=""
              className="casino-name ng-tns-c1798067373-6"
            >
              {" "}
              1 Day Dragon Tiger
              <a
                _ngcontent-ng-c1798067373=""
                className="ms-1 d-xl-inline d-none ng-tns-c1798067373-6"
              >
                <small
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-6"
                >
                  Rules
                </small>
              </a>
            </span>
            <span
              _ngcontent-ng-c1798067373=""
              className="casino-rid d-none d-xl-inline-block ng-tns-c1798067373-6"
            >
              <small
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-6"
              >
                Round ID: {reqRes?.data?.data?.t1[0]?.mid || 0.0}
                {/* | Left
                  Time: {reqRes?.data?.data?.t1[0]?.autotime || 0} |{" "} */}
                {/* <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-6 ng-star-inserted"
                >
                  28.2419091247
                </span> */}
                {/**/}
              </small>
              <span
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-6 ng-star-inserted"
              >
                {" "}
                | Min: 100 {/**/}
                {/**/} | Max: 300000 {/**/}
                {/**/}
              </span>
              {/**/}
            </span>
          </div>
          {/**/}
          {/**/}
          <div
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-6 ng-star-inserted"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-video ng-tns-c1798067373-6"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="video-box-container ng-tns-c1798067373-6"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-video-box ng-tns-c1798067373-6 ng-star-inserted"
                >
                  {/* <iframe
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                    src={CASINO_VIDEO_URL + CASINO_VIDEO.dragonTiger1Day}
                  /> */}
                  <iframe
                    height="100%"
                    width="100%"
                    src={`https://battlemoney.live/iframe/${id}`}
                    title=""
                  />
                </div>
                {/**/}
              </div>
              <Timer value={reqRes?.data?.data?.t1[0]?.autotime || 0} />

           
      
            </div>
         
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-detail ng-tns-c1798067373-6"
            >
        
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table ng-tns-c1798067373-6 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-table-box ng-tns-c1798067373-6"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-left-box ng-tns-c1798067373-6"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-header ng-tns-c1798067373-6"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-detail ng-tns-c1798067373-6"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="d-lg-none d-block ng-tns-c1798067373-6"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min-max-dt20 ng-tns-c1798067373-6"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-6"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-6"
                              >
                                Min:{" "}
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-6"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-6"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-6"
                              >
                                Max:{" "}
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-6"
                              >
                                300000
                              </span>
                            </span>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="d-lg-block d-none ng-tns-c1798067373-6"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min_max_collapse ng-tns-c1798067373-6"
                          >
                            <a
                              _ngcontent-ng-c1798067373=""
                              data-bs-toggle="collapse"
                              href="#collapseranged1014"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseranged1014"
                              className="minmax-btn collapsed ng-tns-c1798067373-6"
                            >
                              <i
                                _ngcontent-ng-c1798067373=""
                                className="fas fa-info-circle ng-tns-c1798067373-6"
                              />
                            </a>
                            <div
                              _ngcontent-ng-c1798067373=""
                              id="collapseranged1014"
                              className="range-collpase collapse ng-tns-c1798067373-6"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-0 ng-tns-c1798067373-6"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-6"
                                >
                                  Min:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-6"
                                >
                                  100
                                </span>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-1 ng-tns-c1798067373-6"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-6"
                                >
                                  Max:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-6"
                                >
                                  300000
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-6"
                      >
                        Back
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-6"
                      >
                        Lay
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-body ng-tns-c1798067373-6"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-6 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-6"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-6"
                          >
                            {" "}
                            Dragon{" "}
                          </div>
                          <p
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                          >
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 1 && (
                                  <span
                                    style={{
                                      color: ele.amount >= 0 ? "green" : "red",
                                    }}
                                  >
                                    {ele.number == 1 && ele.amount}
                                  </span>
                                )
                            )}
                          </p>
                        </div>
                        {reqRes?.data?.data?.t2[0]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[0]?.gstatus === "CLOSED" ? (
                          <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="ng-tns-c1798067373-5 casino_odds_row ">
                              <div
                                className="casino-odds-box back ng-tns-c1798067373-5"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[0]?.b1,
                                    reqRes?.data?.data?.t2[0]?.nat,
                                    1
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                }}
                              >
                                {reqRes?.data?.data?.t2[0]?.b1}
                              </div>
                              <div
                                className="casino-odds-box back ng-tns-c1798067373-5"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[0]?.l1,
                                    reqRes?.data?.data?.t2[0]?.nat,
                                    1
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                }}
                              >
                                {reqRes?.data?.data?.t2[0]?.l1}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-6 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-6"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-6"
                          >
                            {" "}
                            Tiger{" "}
                          </div>
                          <p
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                          >
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 2 && (
                                  <span
                                    style={{
                                      color: ele.amount >= 0 ? "green" : "red",
                                    }}
                                  >
                                    {ele.number == 2 && ele.amount}
                                  </span>
                                )
                            )}
                          </p>
                        </div>
                        {reqRes?.data?.data?.t2[1]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[1]?.gstatus === "CLOSED" ? (
                          <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="ng-tns-c1798067373-5 casino_odds_row ">
                              <div
                                className="casino-odds-box back ng-tns-c1798067373-5"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[1]?.b1,
                                    reqRes?.data?.data?.t2[1]?.nat,
                                    2
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                                }}
                              >
                                {reqRes?.data?.data?.t2[1]?.b1}
                          
                              </div>
                              <div
                                className="casino-odds-box back ng-tns-c1798067373-5"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[1]?.l1,
                                    reqRes?.data?.data?.t2[1]?.nat,
                                    2
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                                }}
                              >
                                {reqRes?.data?.data?.t2[1]?.l1}
                              
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      {/**/}
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-right-box dtpair ng-tns-c1798067373-6 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds text-center ng-tns-c1798067373-6"
                    >
                      {" "}
                      {/* 12{" "} */}
                      {reqRes?.data?.data?.t2[2]?.b1}
                    </div>
                    {reqRes?.data?.data?.t2[2]?.gstatus === "SUSPENDED" ||
                    reqRes?.data?.data?.t2[2]?.gstatus === "CLOSED" ? (
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-6 back suspended-box casino-odds-box casino-odds-box-theme"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-6"
                        >
                          <i className="fa fa-lock" />
                        </span>
                      </div>
                    ) : (
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-6 back casino-odds-box casino-odds-box-theme"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-6"
                        >
                          Pair
                        </span>
                      </div>
                    )}

                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-6"
                    >
                      {thirtyTwoCardStack.map(
                        (ele) =>
                          ele.number === 3 && (
                            <span
                              style={{
                                color: ele.amount >= 0 ? "green" : "red",
                              }}
                            >
                              {ele.number == 3 && ele.amount}
                            </span>
                          )
                      )}
                    </p>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="d-lg-none d-block w-100 text-end ng-tns-c1798067373-6"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="min-max-dt20 ng-tns-c1798067373-6"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-6"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                          >
                            Min:{" "}
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                          >
                            100
                          </span>
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-6"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                          >
                            Max:{" "}
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                          >
                            300000
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  {/**/}
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-table-box mt-3 ng-tns-c1798067373-6"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-left-box ng-tns-c1798067373-6"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-header ng-tns-c1798067373-6"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-detail ng-tns-c1798067373-6"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="d-lg-none d-block ng-tns-c1798067373-6"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min-max-dt20 ng-tns-c1798067373-6"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-6"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-6"
                              >
                                Min:{" "}
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-6"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-6"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-6"
                              >
                                Max:{" "}
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-6"
                              >
                                300000
                              </span>
                            </span>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="d-lg-block d-none ng-tns-c1798067373-6"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min_max_collapse ng-tns-c1798067373-6"
                          >
                            <a
                              _ngcontent-ng-c1798067373=""
                              data-bs-toggle="collapse"
                              href="#collapseranged112"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseranged112"
                              className="minmax-btn collapsed ng-tns-c1798067373-6"
                            >
                              <i
                                _ngcontent-ng-c1798067373=""
                                className="fas fa-info-circle ng-tns-c1798067373-6"
                              />
                            </a>
                            <div
                              _ngcontent-ng-c1798067373=""
                              id="collapseranged112"
                              className="range-collpase collapse ng-tns-c1798067373-6"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-0 ng-tns-c1798067373-6"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-6"
                                >
                                  Min:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-6"
                                >
                                  100
                                </span>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-1 ng-tns-c1798067373-6"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-6"
                                >
                                  Max:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-6"
                                >
                                  300000
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-6"
                      >
                        Even
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-6"
                      >
                        Odd
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-body ng-tns-c1798067373-6"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-6"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-6"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-6"
                          >
                            Dragon
                          </div>
                        </div>
                        {/* "SUSPENDED" ||
                          reqRes?.data?.data?.t2[0]?.gstatus === "CLOSED" ? ( */}
                        {reqRes?.data?.data?.t2[3]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[3]?.gstatus === "CLOSED" ? (
                          <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="ng-tns-c1798067373-5 casino_odds_row ">
                              <div
                                className="casino-odds-box back ng-tns-c1798067373-5"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[3]?.b1,
                                    reqRes?.data?.data?.t2[3]?.nat,
                                    4
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[3]?.sid);
                                }}
                              >
                                {reqRes?.data?.data?.t2[3]?.b1}
                                <p>
                                  {" "}
                                  {thirtyTwoCardStack.map(
                                    (ele) =>
                                      ele.number === 4 && (
                                        <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}
                                        >
                                          {ele.number == 4 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </p>
                              </div>
                              <div
                                className="casino-odds-box back ng-tns-c1798067373-5"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[4]?.b1,
                                    reqRes?.data?.data?.t2[4]?.nat,
                                    5
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[4]?.sid);
                                }}
                              >
                                {reqRes?.data?.data?.t2[4]?.b1}
                                <p>
                                  {" "}
                                  {thirtyTwoCardStack.map(
                                    (ele) =>
                                      ele.number === 5 && (
                                        <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}
                                        >
                                          {ele.number == 5 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                        {/* <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-6 back casino-odds-box ng-star-inserted"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-6"
                          >
                            2.1
                          </span>
                           
                          <p
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                          >
                            0
                          </p>
                        </div> */}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/* <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-6 back casino-odds-box ng-star-inserted"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-6"
                          >
                            1.79
                          </span>
                          <p
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                          >
                            0
                          </p>
                        </div> */}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-6"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-6"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-6"
                          >
                            Tiger
                          </div>
                        </div>
                        {reqRes?.data?.data?.t2[11]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[11]?.gstatus === "CLOSED" ? (
                          <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="ng-tns-c1798067373-5 casino_odds_row ">
                              <div
                                className="casino-odds-box back ng-tns-c1798067373-5"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[11]?.b1,
                                    reqRes?.data?.data?.t2[11]?.nat,
                                    12
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[11]?.sid);
                                }}
                              >
                                {reqRes?.data?.data?.t2[11]?.b1}
                                <p>
                                  {" "}
                                  {thirtyTwoCardStack.map(
                                    (ele) =>
                                      ele.number === 12 && (
                                        <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}
                                        >
                                          {ele.number == 12 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </p>
                              </div>
                              <div
                                className="casino-odds-box back ng-tns-c1798067373-5"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[12]?.b1,
                                    reqRes?.data?.data?.t2[12]?.nat,
                                    13
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[12]?.sid);
                                }}
                              >
                                {reqRes?.data?.data?.t2[12]?.b1}
                                <p>
                                  {" "}
                                  {thirtyTwoCardStack.map(
                                    (ele) =>
                                      ele.number === 13 && (
                                        <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}
                                        >
                                          {ele.number == 13 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/* <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-6 back casino-odds-box ng-star-inserted"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-6"
                          >
                            2.1
                          </span>
                          <p
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                          >
                            0
                          </p>
                        </div> */}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/* <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-6 back casino-odds-box ng-star-inserted"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-6"
                          >
                            1.79
                          </span>
                          <p
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                          >
                            0
                          </p>
                        </div> */}
                        {/**/}
                        {/**/}
                        {/**/}
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-right-box dtredblack ng-tns-c1798067373-6"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-header ng-tns-c1798067373-6"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-detail ng-tns-c1798067373-6"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="d-lg-none d-block ng-tns-c1798067373-6"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min-max-dt20 ng-tns-c1798067373-6"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-6"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-6"
                              >
                                Min:{" "}
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-6"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-6"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-6"
                              >
                                Max:{" "}
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-6"
                              >
                                300000
                              </span>
                            </span>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="d-lg-block d-none ng-tns-c1798067373-6"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min_max_collapse ng-tns-c1798067373-6"
                          >
                            <a
                              _ngcontent-ng-c1798067373=""
                              data-bs-toggle="collapse"
                              href="#collapseranged12"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseranged12"
                              className="minmax-btn collapsed ng-tns-c1798067373-6"
                            >
                              <i
                                _ngcontent-ng-c1798067373=""
                                className="fas fa-info-circle ng-tns-c1798067373-6"
                              />
                            </a>
                            <div
                              _ngcontent-ng-c1798067373=""
                              id="collapseranged12"
                              className="range-collpase collapse ng-tns-c1798067373-6"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-0 ng-tns-c1798067373-6"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-6"
                                >
                                  Min:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-6"
                                >
                                  100
                                </span>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-1 ng-tns-c1798067373-6"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-6"
                                >
                                  Max:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-6"
                                >
                                  300000
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-6"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-6"
                        >
                          Red
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-icon ms-1 ng-tns-c1798067373-6"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="card-red ng-tns-c1798067373-6"
                          >
                            <img
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-6"
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                            />
                          </span>
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-icon ms-1 ng-tns-c1798067373-6"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="card-red ng-tns-c1798067373-6"
                          >
                            <img
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-6"
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                            />
                          </span>
                        </span>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-6"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-6"
                        >
                          Black
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-icon ms-1 ng-tns-c1798067373-6"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="card-black ng-tns-c1798067373-6"
                          >
                            <img
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-6"
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                            />
                          </span>
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-icon ms-1 ng-tns-c1798067373-6"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="card-black ng-tns-c1798067373-6"
                          >
                            <img
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-6"
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                            />
                          </span>
                        </span>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-body ng-tns-c1798067373-6"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-6"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-6"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-6"
                          >
                            Dragon
                          </div>
                        </div>
                        {reqRes?.data?.data?.t2[5]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[5]?.gstatus === "CLOSED" ? (
                          <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="ng-tns-c1798067373-5 casino_odds_row ">
                              <div
                                className="casino-odds-box back ng-tns-c1798067373-5"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[5]?.b1,
                                    reqRes?.data?.data?.t2[5]?.nat,
                                    6
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[5]?.sid);
                                }}
                              >
                                {reqRes?.data?.data?.t2[5]?.b1}
                                <p>
                                  {" "}
                                  {thirtyTwoCardStack.map(
                                    (ele) =>
                                      ele.number === 6 && (
                                        <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}
                                        >
                                          {ele.number == 6 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </p>
                              </div>
                              <div
                                className="casino-odds-box back ng-tns-c1798067373-5"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[6]?.b1,
                                    reqRes?.data?.data?.t2[6]?.nat,
                                    7
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[6]?.sid);
                                }}
                              >
                                {reqRes?.data?.data?.t2[6]?.b1}
                                <p>
                                  {" "}
                                  {thirtyTwoCardStack.map(
                                    (ele) =>
                                      ele.number === 7 && (
                                        <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}
                                        >
                                          {ele.number == 7 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                        {/* <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-6 back casino-odds-box ng-star-inserted"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-6"
                          >
                            1.95
                          </span>
                          <p
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                          >
                            0
                          </p>
                        </div> */}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/* <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-6 back casino-odds-box ng-star-inserted"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-6"
                          >
                            1.95
                          </span>
                          <p
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                          >
                            0
                          </p>
                        </div> */}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-6"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-6"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-6"
                          >
                            Tiger
                          </div>
                        </div>
                        {reqRes?.data?.data?.t2[13]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[13]?.gstatus === "CLOSED" ? (
                          <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="ng-tns-c1798067373-5 casino_odds_row ">
                              <div
                                className="casino-odds-box back ng-tns-c1798067373-5"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[13]?.b1,
                                    reqRes?.data?.data?.t2[13]?.nat,
                                    12
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[13]?.sid);
                                }}
                              >
                                {reqRes?.data?.data?.t2[13]?.b1}
                                <p>
                                  {" "}
                                  {thirtyTwoCardStack.map(
                                    (ele) =>
                                      ele.number === 12 && (
                                        <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}
                                        >
                                          {ele.number == 12 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </p>
                              </div>
                              <div
                                className="casino-odds-box back ng-tns-c1798067373-5"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[14]?.b1,
                                    reqRes?.data?.data?.t2[14]?.nat,
                                    13
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[14]?.sid);
                                }}
                              >
                                {reqRes?.data?.data?.t2[14]?.b1}
                                <p>
                                  {" "}
                                  {thirtyTwoCardStack.map(
                                    (ele) =>
                                      ele.number === 13 && (
                                        <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}
                                        >
                                          {ele.number == 13 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/* <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-6 back casino-odds-box ng-star-inserted"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-6"
                          >
                            1.95
                          </span>
                          <p
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                          >
                            0
                          </p>
                        </div> */}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/* <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-6 back casino-odds-box ng-star-inserted"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-6"
                          >
                            1.95
                          </span>
                          <p
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                          >
                            0
                          </p>
                        </div> */}
                        {/**/}
                        {/**/}
                        {/**/}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-table-full-box dt1day-other-odds mt-3 ng-tns-c1798067373-6"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-header ng-tns-c1798067373-6"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-6"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="min_max_collapse ng-tns-c1798067373-6"
                      >
                        <a
                          _ngcontent-ng-c1798067373=""
                          data-bs-toggle="collapse"
                          href="#collapseranged22"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapseranged22"
                          className="minmax-btn collapsed ng-tns-c1798067373-6"
                        >
                          <i
                            _ngcontent-ng-c1798067373=""
                            className="fas fa-info-circle ng-tns-c1798067373-6"
                          />
                        </a>
                        <div
                          _ngcontent-ng-c1798067373=""
                          id="collapseranged22"
                          className="range-collpase collapse ng-tns-c1798067373-6"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-0 ng-tns-c1798067373-6"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-6"
                            >
                              Min:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-6"
                            >
                              100
                            </span>
                          </span>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-1 ng-tns-c1798067373-6"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-6"
                            >
                              Max:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-6"
                            >
                              300000
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds-box ng-tns-c1798067373-6"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-icon ms-1 ng-tns-c1798067373-6"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-black ng-tns-c1798067373-6"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                          />
                        </span>
                      </span>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds-box ng-tns-c1798067373-6"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-icon ms-1 ng-tns-c1798067373-6"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-red ng-tns-c1798067373-6"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                          />
                        </span>
                      </span>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds-box ng-tns-c1798067373-6"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-icon ms-1 ng-tns-c1798067373-6"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-black ng-tns-c1798067373-6"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                          />
                        </span>
                      </span>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds-box ng-tns-c1798067373-6"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-icon ms-1 ng-tns-c1798067373-6"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-red ng-tns-c1798067373-6"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                          />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-body ng-tns-c1798067373-6"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-row ng-tns-c1798067373-6"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-detail ng-tns-c1798067373-6"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-name ng-tns-c1798067373-6"
                        >
                          Dragon
                        </div>
                      </div>
                      {reqRes?.data?.data?.t2[3]?.gstatus === "SUSPENDED" ||
                      reqRes?.data?.data?.t2[3]?.gstatus === "CLOSED" ? (
                        <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                          <div className="casino-odds-box back ng-tns-c1798067373-5">
                            <span className="casino-odds ng-tns-c1798067373-5">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                          <div className="casino-odds-box back ng-tns-c1798067373-5">
                            <span className="casino-odds ng-tns-c1798067373-5">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="ng-tns-c1798067373-5 casino_odds_row ">
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-5"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[7]?.b1,
                                  reqRes?.data?.data?.t2[7]?.nat,
                                  8
                                );
                                setBetIndex(reqRes?.data?.data?.t2[7]?.sid);
                              }}
                            >
                              {reqRes?.data?.data?.t2[7]?.b1}
                              <p>
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 8 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 8 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-5"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[8]?.b1,
                                  reqRes?.data?.data?.t2[8]?.nat,
                                  9
                                );
                                setBetIndex(reqRes?.data?.data?.t2[8]?.sid);
                              }}
                            >
                              {reqRes?.data?.data?.t2[8]?.b1}
                              <p>
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 9 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 9 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                      {reqRes?.data?.data?.t2[9]?.gstatus === "SUSPENDED" ||
                      reqRes?.data?.data?.t2[9]?.gstatus === "CLOSED" ? (
                        <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                          <div className="casino-odds-box back ng-tns-c1798067373-5">
                            <span className="casino-odds ng-tns-c1798067373-5">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                          <div className="casino-odds-box back ng-tns-c1798067373-5">
                            <span className="casino-odds ng-tns-c1798067373-5">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="ng-tns-c1798067373-5 casino_odds_row ">
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-5"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[9]?.b1,
                                  reqRes?.data?.data?.t2[9]?.nat,
                                  10
                                );
                                setBetIndex(reqRes?.data?.data?.t2[9]?.sid);
                              }}
                            >
                              {reqRes?.data?.data?.t2[9]?.b1}
                              <p>
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 10 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 10 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-5"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[10]?.b1,
                                  reqRes?.data?.data?.t2[10]?.nat,
                                  11
                                );
                                setBetIndex(reqRes?.data?.data?.t2[10]?.sid);
                              }}
                            >
                              {reqRes?.data?.data?.t2[10]?.b1}
                              <p>
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 11 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 11 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                      {/* <div
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-6 back casino-odds-box ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-6"
                        >
                          3.79
                        </span>
                        <p
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-6"
                        >
                          {" "}
                          0{" "}
                        </p>
                      </div> */}
                      {/* <div
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-6 back casino-odds-box ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-6"
                        >
                          3.79
                        </span>
                        <p
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-6"
                        >
                          {" "}
                          0{" "}
                        </p>
                      </div> */}
                      {/* <div
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-6 back casino-odds-box ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-6"
                        >
                          3.79
                        </span>
                        <p
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-6"
                        >
                          {" "}
                          0{" "}
                        </p>
                      </div> */}
                      {/* <div
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-6 back casino-odds-box ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-6"
                        >
                          3.79
                        </span>
                        <p
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-6"
                        >
                          {" "}
                          0{" "}
                        </p>
                      </div> */}
                      {/**/}
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-row ng-tns-c1798067373-6"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-detail ng-tns-c1798067373-6"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-name ng-tns-c1798067373-6"
                        >
                          Tiger
                        </div>
                      </div>
                      {reqRes?.data?.data?.t2[15]?.gstatus === "SUSPENDED" ||
                      reqRes?.data?.data?.t2[15]?.gstatus === "CLOSED" ? (
                        <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                          <div className="casino-odds-box back ng-tns-c1798067373-5">
                            <span className="casino-odds ng-tns-c1798067373-5">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                          <div className="casino-odds-box back ng-tns-c1798067373-5">
                            <span className="casino-odds ng-tns-c1798067373-5">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="ng-tns-c1798067373-5 casino_odds_row ">
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-5"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[15]?.b1,
                                  reqRes?.data?.data?.t2[15]?.nat,
                                  16
                                );
                                setBetIndex(reqRes?.data?.data?.t2[15]?.sid);
                              }}
                            >
                              {reqRes?.data?.data?.t2[15]?.b1}
                              <p>
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 16 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 16 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-5"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[16]?.b1,
                                  reqRes?.data?.data?.t2[16]?.nat,
                                  17
                                );
                                setBetIndex(reqRes?.data?.data?.t2[16]?.sid);
                              }}
                            >
                              {reqRes?.data?.data?.t2[16]?.b1}
                              <p>
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 17 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 17 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                      {reqRes?.data?.data?.t2[17]?.gstatus === "SUSPENDED" ||
                      reqRes?.data?.data?.t2[17]?.gstatus === "CLOSED" ? (
                        <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                          <div className="casino-odds-box back ng-tns-c1798067373-5">
                            <span className="casino-odds ng-tns-c1798067373-5">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                          <div className="casino-odds-box back ng-tns-c1798067373-5">
                            <span className="casino-odds ng-tns-c1798067373-5">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="ng-tns-c1798067373-5 casino_odds_row ">
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-5"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[17]?.b1,
                                  reqRes?.data?.data?.t2[17]?.nat,
                                  18
                                );
                                setBetIndex(reqRes?.data?.data?.t2[17]?.sid);
                              }}
                            >
                              {reqRes?.data?.data?.t2[17]?.b1}
                              <p>
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 18 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 18 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-5"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[18]?.b1,
                                  reqRes?.data?.data?.t2[18]?.nat,
                                  19
                                );
                                setBetIndex(reqRes?.data?.data?.t2[18]?.sid);
                              }}
                            >
                              {reqRes?.data?.data?.t2[18]?.b1}
                              <p>
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 19 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 19 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                      {/* <div
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-6 back casino-odds-box ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-6"
                        >
                          3.79
                        </span>
                        <p
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-6"
                        >
                          {" "}
                          0{" "}
                        </p>
                      </div> */}
                      {/* <div
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-6 back casino-odds-box ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-6"
                        >
                          3.79
                        </span>
                        <p
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-6"
                        >
                          {" "}
                          0{" "}
                        </p>
                      </div> */}
                      {/* <div
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-6 back casino-odds-box ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-6"
                        >
                          3.79
                        </span>
                        <p
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-6"
                        >
                          {" "}
                          0{" "}
                        </p>
                      </div> */}
                      {/* <div
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-6 back casino-odds-box ng-star-inserted"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-6"
                        >
                          3.79
                        </span>
                        <p
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-6"
                        >
                          {" "}
                          0{" "}
                        </p>
                      </div> */}
                      {/**/}
                    </div>
                  </div>
                </div>
              </div>
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-6"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-last-result-title ng-tns-c1798067373-6"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    Last Result
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-6"
                    >
                      View All
                    </a>
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-last-results ng-tns-c1798067373-6 ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6 result result-a ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-6"
                    >
                      {" "}
                      D
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-6"
                    >
                      {" "}
                      T
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6 result result-a ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-6"
                    >
                      {" "}
                      D
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6 result result-a ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-6"
                    >
                      {" "}
                      D
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6 result result-a ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-6"
                    >
                      {" "}
                      D
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6 result result-a ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-6"
                    >
                      {" "}
                      D
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6 result result-a ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-6"
                    >
                      {" "}
                      D
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-6"
                    >
                      {" "}
                      T
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6 result result-a ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-6"
                    >
                      {" "}
                      D
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6 result result-a ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-6"
                    >
                      {" "}
                      D
                    </span>
                  </span>
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                </div>
                {/**/}
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-6 d-none matchbettable w-100 ng-star-inserted"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="table-responsive ng-tns-c1798067373-6"
            >
              <table
                _ngcontent-ng-c1798067373=""
                className="table ng-tns-c1798067373-6"
              >
                <thead
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-6"
                >
                  <tr
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-6"
                    >
                      Matched Bet
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-6"
                    >
                      Odds
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-6"
                    >
                      Stake
                    </th>
                  </tr>
                </thead>
                {/**/}
              </table>
            </div>
          </div>
          {/**/}
          {/**/}
        </div>
        {/**/}
      </div>
      <CasinoBetModal
        id={id}
        name={name}
        modalOpen={showModal}
        betDetails={betDetails}
        setbetDetails={setbetDetails}
        setShowModal={setShowModal}
        betHistory={history}
        realTime={realTime}
        setRealTimeData={setRealTimeData}
      />
    </div>
  );
}
