import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import { fetchThirtyTwoCardStack } from "../../redux/features/thirtyTwoCardGameSlice";
import { API } from "../../service/API";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import CasinoBetModal from "./CasinoBetModal";
import Timer from "./components/Timer";
import { CASINO_VIDEO, getOddForEntity, setBet } from "./CasinoGeneric";
import CasinoVideo from "./components/CasinoVideo";

export default function Race20() {
  const GAME_ID = "RACE20";
  const VIDEO_ID = 3056;
  const dispatch = useDispatch();
  const CARD_MAPPING = {
    S: 1,
    H: 2,
    D: 3,
    C: 4,
  };
  const id = 16;
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const uid = user.data.resultid;
  console.log("id1", user);
  const [name, setName] = useState("");
  const [betIndex, setBetIndex] = useState("");
  const [results, setResults] = useState([]);
  const [midRound, setMidRound] = useState(0);
  const [showresultModal, setShowresultModal] = useState(false);
  const [trophy, setTrophy] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [realTime, setRealTimeData] = useState(false);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const [betDetails, setbetDetails] = useState({});
  const [history, setHistory] = useState([]);

  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      console.log("CasinoGameResult 32:", response);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const SOCKET_URL = "https://dreamwin-socket.nakshtech.info/";

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("TeenPatti socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        console.log("teenpattiopen", parsedData[0].reqResponse);
        setResults(parsedData[0].reqResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  const handleModal = (value, nation, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nation: nation,
      number: no,
    });
    setShowModal(true);
    setRealTimeData(true);
  };

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.sid === betIndex
      );
      // console.log("filter", filteredData, betDetails);
      filteredData[0]?.gstatus === "ACTIVE" &&
      (betDetails?.multipler === filteredData[0]?.l1 ||
        betDetails?.multipler === filteredData[0]?.b1 ||
        betDetails.multipler === parseFloat(2.05) ||
        betDetails.multipler === parseFloat(2.0) ||
        betDetails.multipler === parseFloat(1.9))
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  useEffect(() => {
    console.log("id2", id);

    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);

    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      // console.log("===Get", data);
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  async function ResultScheduler_API() {
    try {
      let res = await API.post(
        "/Schulder_7CareGameLive",
        {
          gameid: id,
          username: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  // const casinoData = useQuery(
  //   "getCasinoOdds",
  //   () => getCasinoOddsAPI(GAME_ID),
  //   {
  //     refetchInterval: 800,
  //   }
  // );
  // const lastTenResultData = useQuery(
  //   "getResult",
  //   () => getCasinoLastTenResultAPI(GAME_ID),
  //   {
  //     refetchInterval: 1500,
  //   }
  // );
  // const casinoBets = useQuery("casinoBets", () => getCasinoBetsAPI(GAME_ID), {
  //   refetchInterval: 2500,
  // });
  const [selectedTab, setSelectedTab] = React.useState("game");
  const isMobile = window.innerWidth < 768;
  return (
    <div className="center-main-container casino-page ng-tns-c3502172659-2 ng-star-inserted">
      <div className="center-container ng-tns-c3502172659-2">
        <div className="casino-page-container ng-tns-c3502172659-2 race20">
          <div className="casino-title-header-mobile d-xl-none ng-tns-c3502172659-2">
            <ul className="nav nav-tabs menu-tabs ng-tns-c3502172659-2">
              <li
                onClick={() => setSelectedTab("game")}
                className="nav-item ng-tns-c3502172659-2"
              >
                <div className="ng-tns-c3502172659-2 active nav-link">Game</div>
              </li>
              <li
                onClick={() => setSelectedTab("placed")}
                className="nav-item ng-tns-c3502172659-2"
              >
                <div className="ng-tns-c3502172659-2 nav-link">
                  Placed Bet
                  {/* ({casinoBets?.data?.response?.length}){" "} */}
                </div>
              </li>
            </ul>
            <div className="pe-2 ng-tns-c3502172659-2">
              <span className="casino-rid ng-tns-c3502172659-2">
                <a className="d-block ng-tns-c3502172659-2">
                  <small className="ng-tns-c3502172659-2">Rules</small>
                </a>{" "}
                Round ID:{" "}
                <span className="ng-tns-c3502172659-2 ng-star-inserted">
                  {reqRes?.data?.data?.t1[0]?.mid || 0.0}
                  {/* {casinoData?.data?.response?.marketId} */}
                </span>
              </span>
            </div>
          </div>
          <div className="casino-header ng-tns-c3502172659-2">
            <span className="casino-name ng-tns-c3502172659-2">
              Race 20 20
              <a className="ms-1 d-xl-inline d-none ng-tns-c3502172659-2">
                <small className="ng-tns-c3502172659-2">Rules</small>
              </a>
            </span>
            <span className="casino-rid d-none d-xl-inline-block ng-tns-c3502172659-2">
              <small className="ng-tns-c3502172659-2">
                Round ID:{" "}
                <span className="ng-tns-c3502172659-2 ng-star-inserted">
                  {reqRes?.data?.data?.t1[0]?.mid || 0.0}
                </span>
              </small>
              <span className="ng-tns-c3502172659-2 ng-star-inserted">
                | Min: 100 | Max: 100000
              </span>
            </span>
          </div>

          <div className="ng-tns-c3502172659-2 ng-star-inserted">
            <div className="casino-video ng-tns-c3502172659-2">
              <div className="video-box-container ng-tns-c3502172659-2">
                <div className="casino-video-box ng-tns-c3502172659-2 ng-star-inserted">
                  <CasinoVideo id={CASINO_VIDEO.race2020} />
                </div>
              </div>
              <Timer value={reqRes?.data?.data?.t1[0]?.autotime || 0} />
            </div>

            <div className="casino-detail ng-tns-c3502172659-2">
              <div className="casino-detail ng-tns-c3502172659-2 ng-star-inserted">
                <div className="casino-table ng-tns-c3502172659-2">
                  <div className="casino-table-box casino-table-box_bg ng-tns-c3502172659-2">
                    <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
                      <div className="casino-nation-name ng-tns-c3502172659-2">
                        <img
                          className="ng-tns-c3502172659-2 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/KHH.webp"
                        />

                        <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                          <a
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c3502172659-2"
                            href="#collapserange2r20201"
                            aria-controls="collapserange21"
                          >
                            <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                          </a>
                          <div
                            className="range-collpase collapse ng-tns-c3502172659-2"
                            id="collapserange2r20201"
                          >
                            <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                              <b className="ng-tns-c3502172659-2">Min:</b>
                              <span className="ng-tns-c3502172659-2">100</span>
                            </span>
                            <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                              <b className="ng-tns-c3502172659-2">Max:</b>
                              <span className="ng-tns-c3502172659-2">
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      {reqRes?.data?.data?.t2[0]?.gstatus === "SUSPENDED" ||
                      reqRes?.data?.data?.t2[0]?.gstatus === "CLOSED" ? (
                        <div className=" ng-tns-c3502172659-2 casinobox-row suspended-box">
                          <div className="casino-odds-box back ng-tns-c3502172659-2">
                            <span className="casino-odds  ng-tns-c3502172659-2">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                          <div className="casino-odds-box lay ng-tns-c3502172659-2">
                            <span className="casino-odds  ng-tns-c3502172659-2">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`ng-tns-c3502172659-2 casinobox-row suspended-box`}
                        >
                          <div
                            className="casino-odds-box back ng-tns-c3502172659-2"
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[0]?.b1,
                                reqRes?.data?.data?.t2[0]?.nat,
                                1
                              );
                              setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                            }}
                          >
                            <span className="casino-odds ng-tns-c3502172659-2">
                              {reqRes?.data?.data?.t2[0].b1}
                            </span>
                            <div className="casino-volume ng-tns-c3502172659-2">
                              100
                            </div>
                          </div>
                          <div
                            className="casino-odds-box lay ng-tns-c3502172659-2"
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[0]?.l1,
                                reqRes?.data?.data?.t2[0]?.nat,
                                5
                              );
                              setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                            }}
                          >
                            <span className="casino-odds ng-tns-c3502172659-2">
                              {reqRes?.data?.data?.t2[0].l1}
                            </span>
                            <div className="casino-volume ng-tns-c3502172659-2">
                              100
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 1 && (
                              <span
                                className={`${
                                  ele.number == 1 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}
                              >
                                {ele.number == 1 && ele.amount}
                              </span>
                            )
                        )}
                      </div>
                    </div>
                    <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
                      <div className="casino-nation-name ng-tns-c3502172659-2">
                        <img
                          className="ng-tns-c3502172659-2 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/KDD.webp"
                        />

                        <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                          <a
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c3502172659-2"
                            href="#collapserange2r20202"
                            aria-controls="collapserange22"
                          >
                            <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                          </a>
                          <div
                            className="range-collpase collapse ng-tns-c3502172659-2"
                            id="collapserange2r20202"
                          >
                            <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                              <b className="ng-tns-c3502172659-2">Min:</b>
                              <span className="ng-tns-c3502172659-2">100</span>
                            </span>
                            <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                              <b className="ng-tns-c3502172659-2">Max:</b>
                              <span className="ng-tns-c3502172659-2">
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      {reqRes?.data?.data?.t2[1]?.gstatus === "SUSPENDED" ||
                      reqRes?.data?.data?.t2[1]?.gstatus === "CLOSED" ? (
                        <div className=" ng-tns-c3502172659-2 casinobox-row suspended-box">
                          <div className="casino-odds-box back ng-tns-c3502172659-2">
                            <span className="casino-odds  ng-tns-c3502172659-2">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                          <div className="casino-odds-box lay ng-tns-c3502172659-2">
                            <span className="casino-odds  ng-tns-c3502172659-2">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`ng-tns-c3502172659-2 casinobox-row suspended-box`}
                        >
                          <div
                            className="casino-odds-box back ng-tns-c3502172659-2"
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[1]?.b1,
                                reqRes?.data?.data?.t2[1]?.nat,
                                2
                              );
                              setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                            }}
                          >
                            <span className="casino-odds ng-tns-c3502172659-2">
                              {reqRes?.data?.data?.t2[1].b1}
                            </span>
                            <div className="casino-volume ng-tns-c3502172659-2">
                              100
                            </div>
                          </div>
                          <div
                            className="casino-odds-box lay ng-tns-c3502172659-2"
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[1]?.l1,
                                reqRes?.data?.data?.t2[1]?.nat,
                                6
                              );
                              setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                            }}
                          >
                            <span className="casino-odds ng-tns-c3502172659-2">
                              {reqRes?.data?.data?.t2[1].l1}
                            </span>
                            <div className="casino-volume ng-tns-c3502172659-2">
                              100
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 2 && (
                              <span
                                className={`${
                                  ele.number == 2 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}
                              >
                                {ele.number == 2 && ele.amount}
                              </span>
                            )
                        )}
                      </div>
                    </div>
                    <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
                      <div className="casino-nation-name ng-tns-c3502172659-2">
                        <img
                          className="ng-tns-c3502172659-2 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/KCC.webp"
                        />

                        <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                          <a
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c3502172659-2"
                            href="#collapserange2r20203"
                            aria-controls="collapserange23"
                          >
                            <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                          </a>
                          <div
                            className="range-collpase collapse ng-tns-c3502172659-2"
                            id="collapserange2r20203"
                          >
                            <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                              <b className="ng-tns-c3502172659-2">Min:</b>
                              <span className="ng-tns-c3502172659-2">100</span>
                            </span>
                            <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                              <b className="ng-tns-c3502172659-2">Max:</b>
                              <span className="ng-tns-c3502172659-2">
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      {reqRes?.data?.data?.t2[2]?.gstatus === "SUSPENDED" ||
                      reqRes?.data?.data?.t2[2]?.gstatus === "CLOSED" ? (
                        <div className=" ng-tns-c3502172659-2 casinobox-row suspended-box">
                          <div className="casino-odds-box back ng-tns-c3502172659-2">
                            <span className="casino-odds  ng-tns-c3502172659-2">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                          <div className="casino-odds-box lay ng-tns-c3502172659-2">
                            <span className="casino-odds  ng-tns-c3502172659-2">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`ng-tns-c3502172659-2 casinobox-row suspended-box`}
                        >
                          <div
                            className="casino-odds-box back ng-tns-c3502172659-2"
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[2]?.b1,
                                reqRes?.data?.data?.t2[2]?.nat,
                                3
                              );
                              setBetIndex(reqRes?.data?.data?.t2[2]?.sid);
                            }}
                          >
                            <span className="casino-odds ng-tns-c3502172659-2">
                              {reqRes?.data?.data?.t2[2].b1}
                            </span>
                            <div className="casino-volume ng-tns-c3502172659-2">
                              100
                            </div>
                          </div>
                          <div
                            className="casino-odds-box lay ng-tns-c3502172659-2"
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[2]?.l1,
                                reqRes?.data?.data?.t2[2]?.nat,
                                7
                              );
                              setBetIndex(reqRes?.data?.data?.t2[2]?.sid);
                            }}
                          >
                            <span className="casino-odds ng-tns-c3502172659-2">
                              {reqRes?.data?.data?.t2[2].l1}
                            </span>
                            <div className="casino-volume ng-tns-c3502172659-2">
                              100
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 3 && (
                              <span
                                className={`${
                                  ele.number == 3 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}
                              >
                                {ele.number == 3 && ele.amount}
                              </span>
                            )
                        )}
                      </div>
                    </div>
                    <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
                      <div className="casino-nation-name ng-tns-c3502172659-2">
                        <img
                          className="ng-tns-c3502172659-2 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/KSS.webp"
                        />

                        <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                          <a
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c3502172659-2"
                            href="#collapserange2r20204"
                            aria-controls="collapserange24"
                          >
                            <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                          </a>
                          <div
                            className="range-collpase collapse ng-tns-c3502172659-2"
                            id="collapserange2r20204"
                          >
                            <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                              <b className="ng-tns-c3502172659-2">Min:</b>
                              <span className="ng-tns-c3502172659-2">100</span>
                            </span>
                            <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                              <b className="ng-tns-c3502172659-2">Max:</b>
                              <span className="ng-tns-c3502172659-2">
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>

                      {reqRes?.data?.data?.t2[3]?.gstatus === "SUSPENDED" ||
                      reqRes?.data?.data?.t2[3]?.gstatus === "CLOSED" ? (
                        <div className=" ng-tns-c3502172659-2 casinobox-row suspended-box">
                          <div className="casino-odds-box back ng-tns-c3502172659-2">
                            <span className="casino-odds  ng-tns-c3502172659-2">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                          <div className="casino-odds-box lay ng-tns-c3502172659-2">
                            <span className="casino-odds  ng-tns-c3502172659-2">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`ng-tns-c3502172659-2 casinobox-row suspended-box`}
                        >
                          <div
                            className="casino-odds-box back ng-tns-c3502172659-2"
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[3]?.b1,
                                reqRes?.data?.data?.t2[3]?.nat,
                                4
                              );
                              setBetIndex(reqRes?.data?.data?.t2[3]?.sid);
                            }}
                          >
                            <span className="casino-odds ng-tns-c3502172659-2">
                              {reqRes?.data?.data?.t2[3].b1}
                            </span>
                            <div className="casino-volume ng-tns-c3502172659-2">
                              100
                            </div>
                          </div>
                          <div
                            className="casino-odds-box lay ng-tns-c3502172659-2"
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[3]?.l1,
                                reqRes?.data?.data?.t2[3]?.nat,
                                8
                              );
                              setBetIndex(reqRes?.data?.data?.t2[3]?.sid);
                            }}
                          >
                            <span className="casino-odds ng-tns-c3502172659-2">
                              {reqRes?.data?.data?.t2[3].l1}
                            </span>
                            <div className="casino-volume ng-tns-c3502172659-2">
                              100
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 4 && (
                              <span
                                className={`${
                                  ele.number == 4 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}
                              >
                                {ele.number == 4 && ele.amount}
                              </span>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="casino-table-box mt-2 ng-tns-c3502172659-2">
                    <div className="casino-table-left-box ng-tns-c3502172659-2">
                      <div className="casino-odd-box-container ng-tns-c3502172659-2">
                        <div className="casino-nation-name ng-tns-c3502172659-2" />
                        <div className="casino-nation-name ng-tns-c3502172659-2">
                          No
                        </div>
                        <div className="casino-nation-name ng-tns-c3502172659-2">
                          Yes
                        </div>
                      </div>
                      <div className="casino-odd-box-container ng-tns-c3502172659-2">
                        <div className="casino-nation-name ng-tns-c3502172659-2">
                          Total points{" "}
                          <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                            <a
                              data-bs-toggle="collapse"
                              href="#collapserange2rac2020513"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapserange2rac2020513"
                              className="minmax-btn collapsed ng-tns-c3502172659-2"
                            >
                              <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                            </a>
                            <div
                              id="collapserange2rac2020513"
                              className="range-collpase collapse ng-tns-c3502172659-2"
                            >
                              <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                                <b className="ng-tns-c3502172659-2">Min:</b>
                                <span className="ng-tns-c3502172659-2">
                                  100
                                </span>
                              </span>
                              <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                                <b className="ng-tns-c3502172659-2">Max:</b>
                                <span className="ng-tns-c3502172659-2">
                                  100000
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>

                        {reqRes?.data?.data?.t2[4]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[4]?.gstatus === "CLOSED" ? (
                          <div className=" ng-tns-c3502172659-2 casino-odds-box lay suspended-box">
                            <div className="casino-odds ng-tns-c3502172659-2">
                              <span className="casino-odds  ng-tns-c3502172659-2">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`ng-tns-c3502172659-2 casino-odds-box lay`}
                            onClick={() => {
                              handleModal(
                                // reqRes?.data?.data?.t2[4]?.l1,
                                2,
                                reqRes?.data?.data?.t2[4]?.nat,
                                9
                              );
                              setBetIndex(reqRes?.data?.data?.t2[4]?.sid);
                            }}
                          >
                            <span className="casino-odds ng-tns-c3502172659-2">
                              {reqRes?.data?.data?.t2[4].l1}
                            </span>
                            <div className="casino-volume text-center ng-tns-c3502172659-2">
                              100
                            </div>
                          </div>
                        )}

                        {reqRes?.data?.data?.t2[4]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[4]?.gstatus === "CLOSED" ? (
                          <div className=" ng-tns-c3502172659-2 casino-odds-box back suspended-box">
                            <div className="casino-odds ng-tns-c3502172659-2">
                              <span className="casino-odds  ng-tns-c3502172659-2">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              handleModal(
                                2,
                                reqRes?.data?.data?.t2[4]?.nat,
                                10
                              );
                              setBetIndex(reqRes?.data?.data?.t2[4]?.sid);
                            }}
                            className={`ng-tns-c3502172659-2 back casino-odds-box`}
                          >
                            <span className="casino-odds ng-tns-c3502172659-2">
                              {reqRes?.data?.data?.t2[4].b1}
                            </span>
                            <div className="casino-volume text-center ng-tns-c3502172659-2">
                              100
                            </div>
                          </div>
                        )}

                        <div className="casino-nation-book ng-tns-c3502172659-2">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 5 && (
                                <span
                                  className={`${
                                    ele.number == 5 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 5 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="casino-odd-box-container ng-tns-c3502172659-2">
                        <div className="casino-nation-name ng-tns-c3502172659-2" />
                        <div className="casino-nation-name ng-tns-c3502172659-2">
                          No
                        </div>
                        <div className="casino-nation-name ng-tns-c3502172659-2">
                          Yes
                        </div>
                      </div>
                      <div className="casino-odd-box-container ng-tns-c3502172659-2">
                        <div className="casino-nation-name ng-tns-c3502172659-2">
                          Total cards{" "}
                          <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                            <a
                              data-bs-toggle="collapse"
                              href="#collapserange2rac2020614"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapserange2rac2020614"
                              className="minmax-btn collapsed ng-tns-c3502172659-2"
                            >
                              <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                            </a>
                            <div
                              id="collapserange2rac2020614"
                              className="range-collpase collapse ng-tns-c3502172659-2"
                            >
                              <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                                <b className="ng-tns-c3502172659-2">Min:</b>
                                <span className="ng-tns-c3502172659-2">
                                  100
                                </span>
                              </span>
                              <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                                <b className="ng-tns-c3502172659-2">Max:</b>
                                <span className="ng-tns-c3502172659-2">
                                  100000
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {reqRes?.data?.data?.t2[5]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[5]?.gstatus === "CLOSED" ? (
                          <div className=" ng-tns-c3502172659-2 casino-odds-box lay suspended-box">
                            <div className="casino-odds ng-tns-c3502172659-2">
                              <span className="casino-odds  ng-tns-c3502172659-2">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`ng-tns-c3502172659-2 casino-odds-box lay`}
                            onClick={() => {
                              handleModal(
                                // reqRes?.data?.data?.t2[5]?.l1,
                                2.05,
                                // parseFloat(105)/ parseFloat(100),
                                reqRes?.data?.data?.t2[5]?.nat,
                                11
                              );
                              setBetIndex(reqRes?.data?.data?.t2[5]?.sid);
                            }}
                          >
                            <span className="casino-odds ng-tns-c3502172659-2">
                              {reqRes?.data?.data?.t2[5].l1}
                            </span>
                            <div className="casino-volume text-center ng-tns-c3502172659-2">
                              100
                            </div>
                          </div>
                        )}

                        {reqRes?.data?.data?.t2[5]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[5]?.gstatus === "CLOSED" ? (
                          <div className=" ng-tns-c3502172659-2 casino-odds-box back suspended-box">
                            <div className="casino-odds ng-tns-c3502172659-2">
                              <span className="casino-odds  ng-tns-c3502172659-2">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              handleModal(
                                // reqRes?.data?.data?.t2[5]?.b1,
                                // parseFloat(90)/ parseFloat(100),
                                1.9,
                                reqRes?.data?.data?.t2[5]?.nat,
                                12
                              );
                              setBetIndex(reqRes?.data?.data?.t2[5]?.sid);
                            }}
                            className={`ng-tns-c3502172659-2 back casino-odds-box`}
                          >
                            <span className="casino-odds ng-tns-c3502172659-2">
                              {reqRes?.data?.data?.t2[5].b1}
                            </span>
                            <div className="casino-volume text-center ng-tns-c3502172659-2">
                              100
                            </div>
                          </div>
                        )}

                        <div className="casino-nation-book ng-tns-c3502172659-2">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 6 && (
                                <span
                                  className={`${
                                    ele.number == 6 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 6 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="casino-table-right-box right_box_padding ng-tns-c3502172659-2">
                      <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
                        <div className="casino-nation-name ng-tns-c3502172659-2">
                          Win with 5{" "}
                          <div
                            // onClick={() =>
                            //   setBet("Win with 5", casinoData, dispatch)
                            // }
                            className="min_max_collapse ms-2 ng-tns-c3502172659-2"
                          >
                            <a
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              className="minmax-btn collapsed ng-tns-c3502172659-2"
                              href="#collapserange2race207"
                              aria-controls="collapserange2race207"
                            >
                              <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                            </a>
                            <div
                              className="range-collpase collapse ng-tns-c3502172659-2"
                              id="collapserange2race207"
                            >
                              <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                                <b className="ng-tns-c3502172659-2">Min:</b>
                                <span className="ng-tns-c3502172659-2">
                                  100
                                </span>
                              </span>
                              <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                                <b className="ng-tns-c3502172659-2">Max:</b>
                                <span className="ng-tns-c3502172659-2">
                                  <span className="ng-tns-c3502172659-2 ng-star-inserted">
                                    25000{" "}
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>

                        {reqRes?.data?.data?.t2[6]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[6]?.gstatus === "CLOSED" ? (
                          <div className=" ng-tns-c3502172659-2 casino-odds-box back suspended-box">
                            <div className="casino-odds ng-tns-c3502172659-2">
                              <span className="casino-odds  ng-tns-c3502172659-2">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`ng-tns-c3502172659-2 back casino-odds-box `}
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[6]?.b1,
                                reqRes?.data?.data?.t2[6]?.nat,
                                13
                              );
                              setBetIndex(reqRes?.data?.data?.t2[6]?.sid);
                            }}
                            // className={`ng-tns-c3502172659-2 back casino-odds-box ${casinoData
                            //   ?.data?.response?.autoTime < 5 &&
                            //   " suspended-box"}`}
                          >
                            <span className="casino-odds ng-tns-c3502172659-2">
                              {reqRes?.data?.data?.t2[6].b1}
                            </span>
                          </div>
                        )}

                        <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 7 && (
                                <span
                                  className={`${
                                    ele.number == 7 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 7 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
                        <div className="casino-nation-name ng-tns-c3502172659-2">
                          Win with 6{" "}
                          <div
                            // onClick={() =>
                            //   setBet("Win with 6", casinoData, dispatch)
                            // }
                            className="min_max_collapse ms-2 ng-tns-c3502172659-2"
                          >
                            <a
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              className="minmax-btn collapsed ng-tns-c3502172659-2"
                              href="#collapserange2race208"
                              aria-controls="collapserange2race208"
                            >
                              <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                            </a>
                            <div
                              className="range-collpase collapse ng-tns-c3502172659-2"
                              id="collapserange2race208"
                            >
                              <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                                <b className="ng-tns-c3502172659-2">Min:</b>
                                <span className="ng-tns-c3502172659-2">
                                  100
                                </span>
                              </span>
                              <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                                <b className="ng-tns-c3502172659-2">Max:</b>
                                <span className="ng-tns-c3502172659-2">
                                  <span className="ng-tns-c3502172659-2 ng-star-inserted">
                                    25000{" "}
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {reqRes?.data?.data?.t2[7]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[7]?.gstatus === "CLOSED" ? (
                          <div className=" ng-tns-c3502172659-2 casino-odds-box back suspended-box">
                            <div className="casino-odds ng-tns-c3502172659-2">
                              <span className="casino-odds  ng-tns-c3502172659-2">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`ng-tns-c3502172659-2 back casino-odds-box `}
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[7]?.b1,
                                reqRes?.data?.data?.t2[7]?.nat,
                                14
                              );
                              setBetIndex(reqRes?.data?.data?.t2[7]?.sid);
                            }}
                          >
                            <span className="casino-odds ng-tns-c3502172659-2">
                              {reqRes?.data?.data?.t2[7].b1}
                            </span>
                          </div>
                        )}

                        <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                        {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 8 && (
                                <span
                                  className={`${
                                    ele.number == 8 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 8 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
                        <div className="casino-nation-name ng-tns-c3502172659-2">
                          Win with 7{" "}
                          <div
                            // onClick={() =>
                            //   setBet("Win with 7", casinoData, dispatch)
                            // }
                            className="min_max_collapse ms-2 ng-tns-c3502172659-2"
                          >
                            <a
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              className="minmax-btn collapsed ng-tns-c3502172659-2"
                              href="#collapserange2race209"
                              aria-controls="collapserange2race209"
                            >
                              <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                            </a>
                            <div
                              className="range-collpase collapse ng-tns-c3502172659-2"
                              id="collapserange2race209"
                            >
                              <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                                <b className="ng-tns-c3502172659-2">Min:</b>
                                <span className="ng-tns-c3502172659-2">
                                  100
                                </span>
                              </span>
                              <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                                <b className="ng-tns-c3502172659-2">Max:</b>
                                <span className="ng-tns-c3502172659-2">
                                  <span className="ng-tns-c3502172659-2 ng-star-inserted">
                                    25000{" "}
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {reqRes?.data?.data?.t2[8]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[8]?.gstatus === "CLOSED" ? (
                          <div className=" ng-tns-c3502172659-2 casino-odds-box back suspended-box">
                            <div className="casino-odds ng-tns-c3502172659-2">
                              <span className="casino-odds  ng-tns-c3502172659-2">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`ng-tns-c3502172659-2 back casino-odds-box `}
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[8]?.b1,
                                reqRes?.data?.data?.t2[8]?.nat,
                                15
                              );
                              setBetIndex(reqRes?.data?.data?.t2[8]?.sid);
                            }}
                          >
                            <span className="casino-odds ng-tns-c3502172659-2">
                              {reqRes?.data?.data?.t2[8].b1}
                            </span>
                          </div>
                        )}
                        <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                        {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 9 && (
                                <span
                                  className={`${
                                    ele.number == 9 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 9 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div
                        // onClick={() =>
                        //   setBet("Win with 8", casinoData, dispatch)
                        // }
                        className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted"
                      >
                        <div className="casino-nation-name ng-tns-c3502172659-2">
                          Win with 15{" "}
                          <div className="min_max_collapse ms-2 ng-tns-c3502172659-2">
                            <a
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              className="minmax-btn collapsed ng-tns-c3502172659-2"
                              href="#collapserange2race2010"
                              aria-controls="collapserange2race2010"
                            >
                              <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                            </a>
                            <div
                              className="range-collpase collapse ng-tns-c3502172659-2"
                              id="collapserange2race2010"
                            >
                              <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                                <b className="ng-tns-c3502172659-2">Min:</b>
                                <span className="ng-tns-c3502172659-2">
                                  100
                                </span>
                              </span>
                              <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                                <b className="ng-tns-c3502172659-2">Max:</b>
                                <span className="ng-tns-c3502172659-2">
                                  <span className="ng-tns-c3502172659-2 ng-star-inserted">
                                    25000{" "}
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {reqRes?.data?.data?.t2[9]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[9]?.gstatus === "CLOSED" ? (
                          <div className=" ng-tns-c3502172659-2 casino-odds-box back suspended-box">
                            <div className="casino-odds ng-tns-c3502172659-2">
                              <span className="casino-odds  ng-tns-c3502172659-2">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`ng-tns-c3502172659-2 back casino-odds-box `}
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[9]?.b1,
                                reqRes?.data?.data?.t2[9]?.nat,
                                16
                              );
                              setBetIndex(reqRes?.data?.data?.t2[9]?.sid);
                            }}
                          >
                            <span className="casino-odds ng-tns-c3502172659-2">
                              {reqRes?.data?.data?.t2[9].b1}
                              {/* {getOddForEntity("win with 5", casinoData)}{" "} */}
                            </span>
                          </div>
                        )}
                        <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                        {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 10 && (
                                <span
                                  className={`${
                                    ele.number == 10 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 10 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
                        <div className="casino-nation-name ng-tns-c3502172659-2">
                          Win with 16{" "}
                          <div
                            // onClick={() =>
                            //   setBet(
                            //     "Win with 16",
                            //     casinoData,
                            //     dispatch
                            //   )
                            // }
                            className="min_max_collapse ms-2 ng-tns-c3502172659-2"
                          >
                            <a
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              className="minmax-btn collapsed ng-tns-c3502172659-2"
                              href="#collapserange2race2011"
                              aria-controls="collapserange2race2011"
                            >
                              <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                            </a>
                            <div
                              className="range-collpase collapse ng-tns-c3502172659-2"
                              id="collapserange2race2011"
                            >
                              <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                                <b className="ng-tns-c3502172659-2">Min:</b>
                                <span className="ng-tns-c3502172659-2">
                                  100
                                </span>
                              </span>
                              <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                                <b className="ng-tns-c3502172659-2">Max:</b>
                                <span className="ng-tns-c3502172659-2">
                                  <span className="ng-tns-c3502172659-2 ng-star-inserted">
                                    25000{" "}
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {reqRes?.data?.data?.t2[10]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[10]?.gstatus === "CLOSED" ? (
                          <div className=" ng-tns-c3502172659-2 casino-odds-box back suspended-box">
                            <div className="casino-odds ng-tns-c3502172659-2">
                              <span className="casino-odds  ng-tns-c3502172659-2">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`ng-tns-c3502172659-2 back casino-odds-box `}
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[10]?.b1,
                                reqRes?.data?.data?.t2[10]?.nat,
                                17
                              );
                              setBetIndex(reqRes?.data?.data?.t2[10]?.sid);
                            }}
                          >
                            <span className="casino-odds ng-tns-c3502172659-2">
                              {reqRes?.data?.data?.t2[10].b1}
                              {/* {getOddForEntity("win with 5", casinoData)}{" "} */}
                            </span>
                          </div>
                        )}
                        <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                        {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 11 && (
                                <span
                                  className={`${
                                    ele.number == 11 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 11 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="casino-odd-box-container ng-tns-c3502172659-2 ng-star-inserted">
                        <div className="casino-nation-name ng-tns-c3502172659-2">
                          Win with 17{" "}
                          <div
                            // onClick={() =>
                            //   setBet(
                            //     "Win with 17",
                            //     casinoData,
                            //     dispatch
                            //   )
                            // }
                            className="min_max_collapse ms-2 ng-tns-c3502172659-2"
                          >
                            <a
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              className="minmax-btn collapsed ng-tns-c3502172659-2"
                              href="#collapserange2race2012"
                              aria-controls="collapserange2race2012"
                            >
                              <i className="fas fa-info-circle ng-tns-c3502172659-2" />
                            </a>
                            <div
                              className="range-collpase collapse ng-tns-c3502172659-2"
                              id="collapserange2race2012"
                            >
                              <span className="min_max_span ms-0 ng-tns-c3502172659-2">
                                <b className="ng-tns-c3502172659-2">Min:</b>
                                <span className="ng-tns-c3502172659-2">
                                  {/* {getOddForEntity(
                                          "Win with 17",
                                          casinoData
                                        )} */}
                                </span>
                              </span>
                              <span className="min_max_span ms-1 ng-tns-c3502172659-2">
                                <b className="ng-tns-c3502172659-2">Max:</b>
                                <span className="ng-tns-c3502172659-2">
                                  <span className="ng-tns-c3502172659-2 ng-star-inserted">
                                    25000{" "}
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {reqRes?.data?.data?.t2[11]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[11]?.gstatus === "CLOSED" ? (
                          <div className=" ng-tns-c3502172659-2 casino-odds-box back suspended-box">
                            <div className="casino-odds ng-tns-c3502172659-2">
                              <span className="casino-odds  ng-tns-c3502172659-2">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`ng-tns-c3502172659-2 back casino-odds-box `}
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[11]?.b1,
                                reqRes?.data?.data?.t2[11]?.nat,
                                18
                              );
                              setBetIndex(reqRes?.data?.data?.t2[11]?.sid);
                            }}
                          >
                            <span className="casino-odds ng-tns-c3502172659-2">
                              {reqRes?.data?.data?.t2[11].b1}
                              {/* {getOddForEntity("win with 5", casinoData)}{" "} */}
                            </span>
                          </div>
                        )}
                        <div className="casino-nation-book text-center w-100 ng-tns-c3502172659-2">
                        {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 12 && (
                                <span
                                  className={`${
                                    ele.number == 12 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 12 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <marquee className="ng-tns-c3502172659-2">
                  <p className="text-danger ng-tns-c3502172659-2">
                    <b className="ng-tns-c3502172659-2"> Hi </b>
                  </p>
                </marquee>
              </div>

              <div className="ng-tns-c3502172659-2">
                <div className="casino-last-result-title ng-tns-c3502172659-2">
                  <span className="ng-tns-c3502172659-2">Last Result</span>
                  <span className="ng-tns-c3502172659-2">
                    <a className="ng-tns-c3502172659-2">View All</a>
                  </span>
                </div>
                <div className="casino-last-results ng-tns-c3502172659-2 ng-star-inserted">
                  {/* {lastTenResultData?.data?.data?.casinoMarketHistoryResponseDTOS?.map(
                          (item) => (
                            <span className="ng-tns-c3502172659-2 result ng-star-inserted">
                              <img
                                alt=""
                                className="ng-tns-c3502172659-2"
                                src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/2d/${
                                  CARD_MAPPING[(item?.mainWinner)]
                                }.webp`}
                              />
                            </span>
                          )
                        )} */}
                </div>
              </div>
            </div>
          </div>

          <div className="ng-tns-c3502172659-2 d-none matchbettable w-100 ng-star-inserted">
            <div className="table-responsive ng-tns-c3502172659-2">
              <table className="table ng-tns-c3502172659-2">
                <thead className="ng-tns-c3502172659-2">
                  <tr className="ng-tns-c3502172659-2">
                    <th className="ng-tns-c3502172659-2">Matched Bet</th>
                    <th className="text-end ng-tns-c3502172659-2">Odds</th>
                    <th className="text-end ng-tns-c3502172659-2">Stake</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
      <CasinoBetModal
        id={id}
        name={name}
        modalOpen={showModal}
        betDetails={betDetails}
        setbetDetails={setbetDetails}
        setShowModal={setShowModal}
        betHistory={history}
        realTime={realTime}
        setRealTimeData={setRealTimeData}
      />
    </div>
  );
}
