import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBetButton } from "../../redux/features/betButtonSlice";
import toast from "react-hot-toast";
import { casinoBet } from "../../redux/features/casinobetSlice";
import { fetchThirtyTwoCardStack } from "../../redux/features/thirtyTwoCardGameSlice";

export default function CasinoBetModal({
  betDetails,
  setbetDetails,
  setShowModal,
  name,
  id,
  realTime,
  setRealTimeData,
  modalOpen,
  betHistory,
}) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const uid = user.data.resultid;
  const [loading, setLoading] = useState(false);
  const modalRef = useRef();

  const { buttonData } = useSelector((state) => state.buttonData);
  const [count, setCount] = useState(0);
  // console.log("betDetails==>", betDetails, name, id );
  const inputRef = useRef();
  const handleChange = (event) => {
    let formattedValue = event.target.value.replace(/[^0-9]/g, "").slice(0, 8);
    handleStake(formattedValue);
  };

  const handleStake = (value) => {
    setbetDetails({
      ...betDetails,
      userid: uid,
      amount: value,
      winAmount: 0,
    });
  };

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  useEffect(() => {
    if (betDetails) {
      modalRef?.current?.addEventListener("click", (e) => {
        if (e.target === modalRef?.current) {
          setbetDetails(null);
        }
      });
    }
  }, [betDetails]);

  useEffect(() => {
    dispatch(fetchBetButton({ id: uid, token }));
  }, [dispatch, uid]);
  console.log("modalOpen", modalOpen);
  const handlePlacebet = async () => {
    setShowModal(false);
    setCount(count + 1);
    try {
      if (!realTime) {
        toast.error("Odd Value is miss Match");
        return;
      }
      if (betDetails.amount == undefined) {
        toast.error("Please enter amount");
        return;
      }

      delete betDetails.nat;
      // console.log("bet", betDetails);
      let responce = await dispatch(
        casinoBet({ betDetails, uid, token })
      ).unwrap();
      console.log("placebet", responce);
      if (responce.data.result == "Place Bet Successfull") {
        toast.success(responce.data.result);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
        // dispatch(fetchuserBalance({ id: uid, token }));

        dispatch(
          fetchThirtyTwoCardStack({ uid, gameId: betDetails.gameid, token })
        );

        // dispatch(fetchCasinobetHistory({ uid, token, id }));
      } else {
        toast.error(responce.data.result);
      }
    } catch (e) {
      console.log("Error", e);
    } finally {
      setShowModal(false);
      setRealTimeData(false);
      setbetDetails({});
    }
  };

  return (
    <>
      {window.innerWidth < 768 && modalOpen && (
        <div
          role="dialog"
          aria-modal="true"
          ref={modalRef}
          className="fade modal show"
          tabIndex={0}
          style={{ display: "block" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title h4">Place Bet</div>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    setShowModal(false);
                    setbetDetails({});
                  }}
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="place-bet-modal back">
                  <div className="row align-items-end">
                    <div className="col-6">
                      <b>{name}</b>
                    </div>
                    <div className="col-6">
                      <div className="float-end">
                        <button className="stakeactionminus btn">
                          <span className="fa fa-minus" />
                        </button>
                        <input
                          type="text"
                          className="stakeinput"
                          // value={getPrice()}
                          // defaultValue={getPrice()}
                          value={betDetails?.multipler}
                          defaultValue={betDetails?.multipler}
                          disabled={loading}
                        />
                        <button className="stakeactionminus btn">
                          <span className="fa fa-plus" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-4">
                      <input
                        type="number"
                        // disabled={loading}
                        className="stakeinput w-100"
                        defaultValue={betDetails?.amount}
                        value={betDetails?.amount}
                        onChange={(e) => setbetDetails(e.target.value)}
                      />
                    </div>
                    <div className="col-4 d-grid">
                      <button
                        // onClick={handleSubmit}
                        onClick={() => handlePlacebet()}
                        className="btn btn-primary btn-block"
                        // disabled={loading}
                      >
                        {/* {loading ? (
                          <i className="fa fa-spinner fa-spin" />
                        ) : ( */}
                        "Submit"
                        {/* )} */}
                      </button>
                    </div>
                    <div className="col-4 text-center pt-2">
                      <span>
                        {" "}
                        {/* {parseFloat(Profitdata?.odds || 0).toFixed(2)} */}
                      </span>
                    </div>
                  </div>
                  <div className="place-bet-buttons mt-2">
                    {buttonData.map((item, index) => (
                      <button
                        disabled={loading}
                        className="btn btn-place-bet"
                        key={index}
                        onClick={() => {
                          handleStake(item.price_value);
                        }}
                      >
                        {item.price_level}
                      </button>
                    ))}
                  </div>
                  <div className="mt-3 d-flex justify-content-between align-items-center">
                    <button disabled={loading} className="btn btn-info">
                      Edit
                    </button>
                  </div>
                  {/*{game == "cricket" &&
                    betDetails?.runnersAndExposure?.map((runner) => (
                      <div className="row mt-2">
                        <div className="col-4">
                          <span>{runner?.runnerName}</span>
                        </div>
                        <div
                          style={{
                            color: runner?.exposure > 0 ? "green" : "red",
                          }}
                          className="col-4 text-center"
                        >
                          <span>{runner?.exposure}</span>
                        </div>
                        <div
                          style={{
                            color: runner?.preExposure > 0 ? "green" : "red",
                          }}
                          className="col-4 text-end"
                        >
                          <span>{runner?.preExposure}</span>
                        </div>
                      </div>
                    ))}*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="sidebar right-sidebar">
        {modalOpen && (
          <div className="sidebar-box place-bet-container">
            <div className="sidebar-title">
              <h4>Place Bet</h4>
            </div>
            <div className="place-bet-box">
              <div className="place-bet-box-header">
                <div className="place-bet-for">Casino</div>
                <div className="place-bet-odds">Odds</div>
                <div className="place-bet-stake">Stake</div>
                <div className="place-bet-profit">(Bet for)</div>
              </div>
              <div className="place-bet-box-body">
                <div className="place-bet-for">
                  <span>{name}</span>
                </div>
                <div className="place-bet-odds">
                  <input
                    type="text"
                    className="form-control"
                    // disabled={loading}
                    value={betDetails?.multipler}
                    defaultValue={betDetails?.multipler}
                  />
                  <div className="spinner-buttons input-group-btn btn-group-vertical">
                    <button className="btn-default">
                      <i className="fa fa-angle-up" />
                    </button>
                    <button className="btn-default">
                      <i className="fa fa-angle-down" />
                    </button>
                  </div>
                </div>
                <div className="place-bet-stake">
                  <input
                    type="number"
                    className="form-control"
                    // disabled={loading}
                    defaultValue={betDetails?.amount}
                    value={betDetails?.amount}
                    // onChange={(e) => e.target.value}
                    // onInput={handleChange}
                    ref={inputRef}
                  />
                </div>
                <div className="place-bet-profit">
                  {betDetails?.nation}
                  {/* {parseFloat(betDetails?.profit).toFixed(2)} */}
                  {/* {parseFloat(Profitdata?.odds || 0).toFixed(2)} */}
                </div>
              </div>
              <div className="place-bet-buttons">
                {buttonData.map((item, index) => (
                  <button
                    // disabled={loading}
                    className="btn btn-place-bet"
                    key={index}
                    onClick={() => {
                      handleStake(item.price_value);
                    }}
                  >
                    {item.price_level}
                  </button>
                ))}
              </div>
              <div className="place-bet-action-buttons">
                <div>
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  {/* <button
                // disabled={loading}
                // onClick={() => setbetDetails(null)}
                className="btn btn-info me-1"
              >
                Reset
              </button> */}
                  <button
                    className="btn btn-success"
                    onClick={() => handlePlacebet()}
                    disabled={count > 0 ? true : false}
                  >
                    Submit
                    {/* )} */}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="sidebar-box my-bet-container">
          <div className="sidebar-title">
            <h4>My Bet</h4>
          </div>
          <div className="my-bets">
            <div className="table-responsive w-100">
              <table className="table">
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Period</th>
                    <th>Multiplier</th>
                    <th>Amount</th>
                    <th>Date</th>
                    <th>Remark</th>
                    <th>Card</th>
                    {/* <th>Matched Bet</th>
                    <th className="text-end">Odds</th>
                    <th className="text-end">Stake</th> */}
                  </tr>
                </thead>
                <tbody>
                  {betHistory.length > 0 ? (
                    betHistory.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.period}</td>
                        <td>{item.multipler}</td>
                        <td>{item.amount}</td>
                        <td>{item.dd}</td>
                        <td
                          className={
                            item.remark === "Loss" ? "zero" : "success-1"
                          }
                        >
                          {item.remark}
                        </td>
                        <td>{item.card}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        No Data
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
