import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import { CASINO_VIDEO } from "./CasinoGeneric";
import { fetchThirtyTwoCardStack } from "../../redux/features/thirtyTwoCardGameSlice";
import { API } from "../../service/API";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import CasinoBetModal from "./CasinoBetModal";
import Timer from "./components/Timer";

export default function Poker20() {
  const id = 6;
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const uid = user.data.resultid;
  console.log("id6", user);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [betIndex, setBetIndex] = useState("");
  const [results, setResults] = useState([]);
  const [midRound, setMidRound] = useState(0);
  const [showresultModal, setShowresultModal] = useState(false);
  const [trophy, setTrophy] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [realTime, setRealTimeData] = useState(false);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const [betDetails, setbetDetails] = useState({});
  const [history, setHistory] = useState([]);

  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      console.log("CasinoGameResult 32:", response);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const SOCKET_URL = "https://dreamwin-socket.nakshtech.info/";

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("TeenPatti socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        console.log("poker20", parsedData[0].reqResponse);
        setResults(parsedData[0].reqResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  const handleModal = (value, nation, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nation: nation,
      number: no,
    });
    setShowModal(true);
    setRealTimeData(true);
  };

  

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.sid === betIndex
      );
      // console.log("filter", filteredData, betDetails);
      filteredData[0]?.gstatus !== "0" &&
      betDetails.nation === filteredData[0].nation &&
      betDetails.multipler === filteredData[0].rate
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }
  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  useEffect(() => {
    console.log("id2", id);

    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);

    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      // console.log("===Get", data);
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  async function ResultScheduler_API() {
    try {
      let res = await API.post(
        "/Schulder_7CareGameLive",
        {
          gameid: id,
          username: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  // const findCardImage = (cardId) => {
  //   const card = card52War.find((card) => card.id === cardId);
  //   return card ? card.src : null;
  // };

  // const parseCardData = (cardData) => {
  //   return cardData?.split(",").map((card) => {
  //     const cardName = card.trim(); // Extract card name and trim any whitespace
  //     return cardName;
  //   });
  // };

  // const arrayData = parseCardData(reqRes?.data?.data?.t1[0]?.desc ?? "");
  // console.log("arr", arrayData);

  return (
    <div
      _ngcontent-ng-c1798067373=""
      className="center-main-container casino-page ng-tns-c1798067373-0 ng-star-inserted"
    >
      <div
        _ngcontent-ng-c1798067373=""
        className="center-container ng-tns-c1798067373-0"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-page-container ng-tns-c1798067373-0 cards32a poker-20"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-title-header-mobile d-xl-none ng-tns-c1798067373-0"
          >
            <ul
              _ngcontent-ng-c1798067373=""
              className="nav nav-tabs menu-tabs ng-tns-c1798067373-0"
            >
              <li
                _ngcontent-ng-c1798067373=""
                className="nav-item ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0 active nav-link"
                >
                  Game
                </div>
              </li>
              <li
                _ngcontent-ng-c1798067373=""
                className="nav-item ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0 nav-link"
                >
                  {" "}
                  Placed Bet (0){" "}
                </div>
              </li>
            </ul>
            <div
              _ngcontent-ng-c1798067373=""
              className="pe-2 ng-tns-c1798067373-0"
            >
              <span
                _ngcontent-ng-c1798067373=""
                className="casino-rid ng-tns-c1798067373-0"
              >
                <a
                  _ngcontent-ng-c1798067373=""
                  className="d-block ng-tns-c1798067373-0"
                >
                  <small
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    Rules
                  </small>
                </a>{" "}
                Round ID: {reqRes?.data?.data?.t1[0]?.mid || 0.0}
                {/* Round ID:{" "}
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0 ng-star-inserted"
                >
                  12.241909122203
                </span> */}
                {/**/}
              </span>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-header ng-tns-c1798067373-0"
          >
            <span
              _ngcontent-ng-c1798067373=""
              className="casino-name ng-tns-c1798067373-0"
            >
              {" "}
              20-20 Poker
              <a
                _ngcontent-ng-c1798067373=""
                className="ms-1 d-xl-inline d-none ng-tns-c1798067373-0"
              >
                <small
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  Rules
                </small>
              </a>
            </span>
            <span
              _ngcontent-ng-c1798067373=""
              className="casino-rid d-none d-xl-inline-block ng-tns-c1798067373-0"
            >
              <small
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-0"
              >
                Round ID:{" "}
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0 ng-star-inserted"
                >
                  12.241909122203
                </span>
                {/**/}
              </small>
              <span
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-0 ng-star-inserted"
              >
                {" "}
                | Min: 100 {/**/}
                {/**/} | Max: 100000 {/**/}
                {/**/}
              </span>
              {/**/}
            </span>
          </div>
          {/**/}
          {/**/}
          <div
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-0 ng-star-inserted"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-video ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="video-box-container ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-video-box ng-tns-c1798067373-0 ng-star-inserted"
                >
                  {/* <iframe
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                    src={CASINO_VIDEO_URL + CASINO_VIDEO.poker2020}
                  /> */}
                  <iframe
                        height="100%"
                        width="100%"
                        src={`https://battlemoney.live/iframe/${id}`}
                        title=""></iframe>
                </div>
                {/**/}
              </div>
              <Timer value={reqRes?.data?.data?.t1[0]?.autotime || 0} />

            </div>
            {/**/}
            {/**/}
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-detail ng-tns-c1798067373-0"
            >
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table ng-tns-c1798067373-0 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-table-box ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-left-box ng-tns-c1798067373-0"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-header ng-tns-c1798067373-0"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-detail ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_odds justify-content-start d-lg-none d-flex ng-tns-c1798067373-0"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-0 ng-tns-c1798067373-0"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-0"
                            >
                              Min:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-0"
                            >
                              {" "}
                              100 {/**/}
                              {/**/}
                            </span>
                          </span>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-1 ng-tns-c1798067373-0"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-0"
                            >
                              Max:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-0"
                            >
                              {" "}
                              100000 {/**/}
                              {/**/}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-0"
                      >
                        Player A
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-0"
                      >
                        Player B
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-body ng-tns-c1798067373-0"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-0"
                          >
                            Winner
                          </div>
                        </div>
                        {reqRes?.data?.data?.t2[0]?.gstatus === "0" ? (
                            <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ): (
                          <>
                          <div className="ng-tns-c1798067373-5 casino_odds_row ">
                          
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"

                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[0]?.rate,
                                      reqRes?.data?.data?.t2[0]?.nation,
                                      1
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                  }}>
                                    
                                  {reqRes?.data?.data?.t2[0]?.rate}
                                  <p>
                                    {" "}
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 1 && (
                                          <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}>
                                        {ele.number == 1 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                            <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"

                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[1]?.rate,
                                      reqRes?.data?.data?.t2[1]?.nation,
                                      2
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                                  }}>
                                    
                                  {reqRes?.data?.data?.t2[1]?.rate}
                                  <p>
                                    {" "}
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 2 && (
                                          <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}>
                                        {ele.number == 2 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                         </div>
                        
                               
                            </>
                          )}
                          
                        </div>
                        <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-0"
                          >
                            One Pair
                          </div>
                        </div>
                        {reqRes?.data?.data?.t2[2]?.gstatus === "0" ? (
                            <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ): (
                          <>
                          <div className="ng-tns-c1798067373-5 casino_odds_row ">
                          
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"

                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[2]?.rate,
                                      reqRes?.data?.data?.t2[2]?.nation,
                                      3
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[2]?.sid);
                                  }}>
                                    
                                  {reqRes?.data?.data?.t2[2]?.rate}
                                  <p>
                                    {" "}
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 3 && (
                                          <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}>
                                        {ele.number == 3 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                            <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"

                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[3]?.rate,
                                      reqRes?.data?.data?.t2[3]?.nation,
                                      4
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[3]?.sid);
                                  }}>
                                    
                                  {reqRes?.data?.data?.t2[3]?.rate}
                                  <p>
                                    {" "}
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 4 && (
                                          <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}>
                                        {ele.number == 4 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                         </div>
                        
                               
                            </>
                          )}
                          
                        
                     
                      </div>


                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-0"
                          >
                            Two Pair
                          </div>
                        </div>
                        {reqRes?.data?.data?.t2[4]?.gstatus === "0" ? (
                            <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ): (
                          <>
                          <div className="ng-tns-c1798067373-5 casino_odds_row ">
                          
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"

                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[4]?.rate,
                                      reqRes?.data?.data?.t2[4]?.nation,
                                      5
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[4]?.sid);
                                  }}>
                                    
                                  {reqRes?.data?.data?.t2[4]?.rate}
                                  <p>
                                    {" "}
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 5 && (
                                          <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}>
                                        {ele.number == 5 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                            <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"

                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[5]?.rate,
                                      reqRes?.data?.data?.t2[5]?.nation,
                                      6
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[5]?.sid);
                                  }}>
                                    
                                  {reqRes?.data?.data?.t2[5]?.rate}
                                  <p>
                                    {" "}
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 6 && (
                                          <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}>
                                        {ele.number == 6 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                         </div>
                        
                               
                            </>
                          )}
                          
                        
                     
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-0"
                          >
                            {" "}
                            Three of a Kind{" "}
                          </div>
                        </div>
                        {reqRes?.data?.data?.t2[6]?.gstatus === "0" ? (
                            <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ): (
                          <>
                          <div className="ng-tns-c1798067373-5 casino_odds_row ">
                          
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"

                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[6]?.rate,
                                      reqRes?.data?.data?.t2[6]?.nation,
                                      7
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[6]?.sid);
                                  }}>
                                    
                                  {reqRes?.data?.data?.t2[6]?.rate}
                                  <p>
                                    {" "}
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 7 && (
                                          <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}>
                                        {ele.number == 7 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                            <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"

                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[7]?.rate,
                                      reqRes?.data?.data?.t2[7]?.nation,
                                      8
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[7]?.sid);
                                  }}>
                                    
                                  {reqRes?.data?.data?.t2[7]?.rate}
                                  <p>
                                    {" "}
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 8 && (
                                          <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}>
                                        {ele.number == 8 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                         </div>
                        
                               
                            </>
                          )}
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-0"
                          >
                            Straight
                          </div>
                        </div>
                        {reqRes?.data?.data?.t2[8]?.gstatus === "0" ? (
                            <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ): (
                          <>
                          <div className="ng-tns-c1798067373-5 casino_odds_row ">
                          
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"

                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[8]?.rate,
                                      reqRes?.data?.data?.t2[8]?.nation,
                                      9
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[8]?.sid);
                                  }}>
                                    
                                  {reqRes?.data?.data?.t2[8]?.rate}
                                  <p>
                                    {" "}
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 9 && (
                                          <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}>
                                        {ele.number == 9 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                            <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"

                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[9]?.rate,
                                      reqRes?.data?.data?.t2[9]?.nation,
                                      10
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[9]?.sid);
                                  }}>
                                    
                                  {reqRes?.data?.data?.t2[9]?.rate}
                                  <p>
                                    {" "}
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 10 && (
                                          <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}>
                                        {ele.number == 10 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                         </div>
                        
                               
                            </>
                          )}
                      </div>
                      </div>
                      </div>
                   
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-box-divider ng-tns-c1798067373-0"
                  />
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-right-box ng-tns-c1798067373-0"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-header d-none d-md-flex ng-tns-c1798067373-0"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-detail ng-tns-c1798067373-0"
                      />
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-0"
                      >
                        Player A
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-0"
                      >
                        Player B
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-body ng-tns-c1798067373-0"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-0"
                          >
                            Flush
                          </div>
                        </div>
                         {reqRes?.data?.data?.t2[10]?.gstatus === "0" ? (
                            <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ): (
                          <>
                          <div className="ng-tns-c1798067373-5 casino_odds_row ">
                          
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"

                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[10]?.rate,
                                      reqRes?.data?.data?.t2[10]?.nation,
                                      11
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[10]?.sid);
                                  }}>
                                    
                                  {reqRes?.data?.data?.t2[10]?.rate}
                                  <p>
                                    {" "}
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 11 && (
                                          <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}>
                                        {ele.number == 11 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                            <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"

                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[11]?.rate,
                                      reqRes?.data?.data?.t2[11]?.nation,
                                      12
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[11]?.sid);
                                  }}>
                                    
                                  {reqRes?.data?.data?.t2[11]?.rate}
                                  <p>
                                    {" "}
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 12 && (
                                          <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}>
                                        {ele.number == 12 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                         </div>
                        
                               
                            </>
                          )}
                    
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-0"
                          >
                            Full House
                          </div>
                        </div>
                        {reqRes?.data?.data?.t2[12]?.gstatus === "0" ? (
                            <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ): (
                          <>
                          <div className="ng-tns-c1798067373-5 casino_odds_row ">
                          
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"

                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[12]?.rate,
                                      reqRes?.data?.data?.t2[12]?.nation,
                                      13
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[12]?.sid);
                                  }}>
                                    
                                  {reqRes?.data?.data?.t2[12]?.rate}
                                  <p>
                                    {" "}
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 13 && (
                                          <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}>
                                        {ele.number == 13 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                            <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"

                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[13]?.rate,
                                      reqRes?.data?.data?.t2[13]?.nation,
                                      14
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[13]?.sid);
                                  }}>
                                    
                                  {reqRes?.data?.data?.t2[13]?.rate}
                                  <p>
                                    {" "}
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 14 && (
                                          <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}>
                                        {ele.number == 14 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                         </div>
                        
                               
                            </>
                          )}
                    
                      
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-0"
                          >
                            Four of a Kind
                          </div>
                        </div>
                        {reqRes?.data?.data?.t2[14]?.gstatus === "0" ? (
                            <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ): (
                          <>
                          <div className="ng-tns-c1798067373-5 casino_odds_row ">
                          
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"

                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[14]?.rate,
                                      reqRes?.data?.data?.t2[14]?.nation,
                                      15
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[14]?.sid);
                                  }}>
                                    
                                  {reqRes?.data?.data?.t2[14]?.rate}
                                  <p>
                                    {" "}
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 15 && (
                                          <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}>
                                        {ele.number == 15 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                            <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"

                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[15]?.rate,
                                      reqRes?.data?.data?.t2[15]?.nation,
                                      16
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[15]?.sid);
                                  }}>
                                    
                                  {reqRes?.data?.data?.t2[15]?.rate}
                                  <p>
                                    {" "}
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 16 && (
                                          <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}>
                                        {ele.number == 16 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                         </div>
                        
                               
                            </>
                          )}
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-0"
                          >
                            Straight Flush
                          </div>
                        </div>
                        {reqRes?.data?.data?.t2[16]?.gstatus === "0" ? (
                            <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ): (
                          <>
                          <div className="ng-tns-c1798067373-5 casino_odds_row ">
                          
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"

                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[16]?.rate,
                                      reqRes?.data?.data?.t2[16]?.nation,
                                      17
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[16]?.sid);
                                  }}>
                                    
                                  {reqRes?.data?.data?.t2[16]?.rate}
                                  <p>
                                    {" "}
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 17 && (
                                          <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}>
                                        {ele.number == 17 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                            <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"

                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[17]?.rate,
                                      reqRes?.data?.data?.t2[17]?.nation,
                                      18
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[17]?.sid);
                                  }}>
                                    
                                  {reqRes?.data?.data?.t2[17]?.rate}
                                  <p>
                                    {" "}
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 18 && (
                                          <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}>
                                        {ele.number == 18 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                         </div>
                        
                               
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="remark w-100 text-right pr-2 ng-tns-c1798067373-0"
                />
              </div>
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-last-result-title ng-tns-c1798067373-0"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    Last Result
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      View All
                    </a>
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-last-results ng-tns-c1798067373-0 ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      B
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      B
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      B
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result result-a ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      A
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      B
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result result-a ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      A
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result result-a ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      A
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result result-a ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      A
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      B
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result result-a ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      A
                    </span>
                  </span>
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                </div>
                {/**/}
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-0 d-none matchbettable w-100 ng-star-inserted"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="table-responsive ng-tns-c1798067373-0"
            >
              <table
                _ngcontent-ng-c1798067373=""
                className="table ng-tns-c1798067373-0"
              >
                <thead
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  <tr
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      Matched Bet
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-0"
                    >
                      Odds
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-0"
                    >
                      Stake
                    </th>
                  </tr>
                </thead>
                {/**/}
              </table>
            </div>
          </div>
          {/**/}
          {/**/}
        </div>
        {/**/}
      </div>
      <CasinoBetModal
                id={id}
                name={name}
                modalOpen={showModal}
                betDetails={betDetails}
                setbetDetails={setbetDetails}
                setShowModal={setShowModal}
                betHistory={history}
                realTime={realTime}
                setRealTimeData={setRealTimeData}
              />
    </div>
  );
}
