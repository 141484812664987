import React, { Suspense, useEffect, useRef, useState } from "react";
import { getUserDataAPI } from "../../service/UserService";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { Link, useParams } from "react-router-dom";
import { fetchMatchName } from "../../redux/features/matchNameSlice";
import { updateCurrentMatch } from "../../redux/features/authSlice";

const Home = () => {
  const { game } = useParams();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");

  const [selectedGameType, setSelectedGameType] = useState(
    game ? game : "cricket"
  );
  const { matchName } = useSelector((state) => state.matchInfo) || [];
  console.log("DDD", matchName);
  const gameTypeToIdMap = {
    cricket: 4,
    soccer: 1,
    tennis: 2,
  };

  useEffect(() => {
    const gameId = gameTypeToIdMap[selectedGameType];

    dispatch(fetchMatchName({ id: gameId, token })).unwrap();
  }, [dispatch, selectedGameType]);

  return (
    <>
      {/* <div className="latest-event d-xl-none">
        <div className="latest-event-item">
          <a className="blink_me">
            <i className="d-icon icon-40" />
            <span>INDIA VS BANGLADESH</span>
          </a>
        </div>
      </div> */}
      <ul className="nav nav-tabs d-xl-none menu-tabs">
        <li className="nav-item">
          <a className="nav-link" href="/sports-book/33">
            Lottery
          </a>
        </li>
        <li className="nav-item">
          <a aria-current="page" className="nav-link active" href="/home">
            Sports
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/casino-list">
            Our Casino
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/live-casino-list">
            Live Casino
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/slot-list">
            Slots
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/fantasy-list">
            Fantasy
          </a>
        </li>
      </ul>
      <div className="center-main-container home-page">
        <div className="center-container">
          <div className="latest-event d-none d-xl-flex">
            {selectedGameType === "cricket" &&
              matchName
                ?.slice(0, matchName.length > 4 ? 4 : matchName?.length)
                ?.map((match) => (
                  <div className="latest-event-item ">
                    <a
                      className="blink_me"
                      // href={`/game/cricket/${match?.cricketId}`}
                    >
                      <i className="d-icon me-1 icon-4" />
                      <span>{match?.name}</span>
                    </a>
                  </div>
                ))}
          </div>
          <ul className="nav nav-pills sports-tab">
            <li className="nav-item">
              <a
                 onClick={() => {
                  setSelectedGameType("cricket");
                  const currentMatch = matchName.find(
                    (match) => match.gameType === "cricket"
                  );
                  if (currentMatch) {
                    dispatch(updateCurrentMatch(currentMatch));
                  }
                }}
             
                className={`nav-link ${selectedGameType == "cricket" &&
                  "active"}`}
              >
                <div className="d-xl-none">
                  <i className="icon icon-4" />
                </div>
                <span>Cricket</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                 onClick={() => {
                  setSelectedGameType("soccer");
                  const currentMatch = matchName.find(
                    (match) => match.gameType === "soccer"
                  );
                  if (currentMatch) {
                    dispatch(updateCurrentMatch(currentMatch));
                  }
                }}
 
                className={`nav-link ${selectedGameType == "soccer" &&
                  "active"}`}
              >
                <div className="d-xl-none">
                  <i className="icon icon-1" />
                </div>
                <span>Football</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                   onClick={() => {
                    setSelectedGameType("tennis");
                    const currentMatch = matchName.find(
                      (match) => match.gameType === "tennis"
                    );
                    if (currentMatch) {
                      dispatch(updateCurrentMatch(currentMatch));
                    }
                  }}
             
                className={`nav-link ${selectedGameType == "tennis" &&
                  "active"}`}
              >
                <div className="d-xl-none">
                  <i className="icon icon-2" />
                </div>
                <span>Tennis</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-40" />
                </div>
                <span>Politics</span>
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-68" />
                </div>
                <span>Esoccer</span>
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-10" />
                </div>
                <span>Horse Racing</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-65" />
                </div>
                <span>Greyhound Racing</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-8" />
                </div>
                <span>Table Tennis</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-15" />
                </div>
                <span>Basketball</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-6" />
                </div>
                <span>Boxing</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-3" />
                </div>
                <span>Mixed Martial Arts</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-58" />
                </div>
                <span>American Football</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-18" />
                </div>
                <span>Volleyball</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-22" />
                </div>
                <span>Badminton</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-59" />
                </div>
                <span>Snooker</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-19" />
                </div>
                <span>Ice Hockey</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-11" />
                </div>
                <span>E Games</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-9" />
                </div>
                <span>Futsal</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-39" />
                </div>
                <span>Handball</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-52" />
                </div>
                <span>Motor Sports</span>
              </a>
            </li>
          </ul>

          <div className="tab-content mt-1">
            <div className="tab-pane active">
              <div className="bet-table">
                <div className="bet-table-header">
                  <div className="bet-nation-name">
                    <b>Game</b>
                  </div>
                  <div className="bet-nation-odd">
                    <b>1</b>
                  </div>
                  <div className="bet-nation-odd">
                    <b>X</b>
                  </div>
                  <div className="bet-nation-odd">
                    <b>2</b>
                  </div>
                </div>
                <div className="bet-table-body">
                  {matchName?.map((item, index) => {
                    const date = new Date(item.openDate);
                    date.setHours(date.getHours() - 5);
                    date.setMinutes(date.getMinutes() - 30);
                    const isFuture = new Date(date) >= new Date();

                    return (
                      <div className="bet-table-row" key={item?.id}>
                        <div className="bet-nation-name">
                          <Link
                            className="bet-nation-game-name"
                            // href={`/game/${item.id}`}
                            to={`/game/${selectedGameType.toLowerCase()}/${
                              item?.id
                            }`}
                          >
                            <span>{item.name}</span>
                            {moment(date).format("DD-MM-YYYY HH:mm A")}
                          </Link>
                          <div className="game-icons">
                            <div className="game-icon">
                              {!isFuture && <span className="active" />}
                            </div>
                            <div className="game-icon">
                              <i className="fas fa-tv icon-tv" />
                            </div>
                            <div className="game-icon">
                              <img src={require("../../assets/ic_fancy.png")} />
                            </div>
                            <div className="game-icon">
                              <img src={require("../../assets/ic_bm.png")} />
                            </div>
                            <div className="game-icon" />
                          </div>
                        </div>
                        <div className="bet-nation-odd">
                          <div className="back odd-box">
                            <span className="bet-odd">
                              {item.Back_1 ? item?.Back_1 : <b>-</b>}
                            </span>
                          </div>
                          <div className="lay odd-box">
                            <span className="bet-odd">
                              {item.Lay_1 ? item.Lay_1 : <b>-</b>}
                            </span>
                          </div>
                        </div>
                        <div className="bet-nation-odd">
                          <div className="back odd-box">
                            <span className="bet-odd">
                              {item.Back_x ? item?.Back_x : <b>-</b>}
                            </span>
                          </div>
                          <div className="lay odd-box">
                            <span className="bet-odd">
                              {item.Lay_x ? item?.Lay_x : <b>-</b>}
                            </span>
                          </div>
                        </div>
                        <div className="bet-nation-odd">
                          <div className="back odd-box">
                            <span className="bet-odd">
                              {item.Back_2 ? item?.Back_2 : <b>-</b>}
                            </span>
                          </div>
                          <div className="lay odd-box">
                            <span className="bet-odd">
                              {item.Lay_2 ? item?.Back_2 : <b>-</b>}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="casino-list mt-2">
            <div className="casino-list-item">
              <a href="/casino/ballbyball">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/ballbyball.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Ball by Ball</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/superover">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/superover.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Super Over</div>
              </a>
            </div>

            <div className="casino-list-item">
              <a href="/casino/race20">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/race20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Race20</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/cricketv3">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/cricketv3.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">5Five Cricket</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/abj">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/abj.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Andar Bahar 2</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/dt202">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/dt202.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">20-20 Dragon Tiger 2</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/baccarat">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/baccarat.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Baccarat</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/baccarat2">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/baccarat2.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Baccarat 2</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/lucky7eu">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/lucky7eu.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Lucky 7 - B</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/war">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/war.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Casino War</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/dtl20">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/dtl20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">20-20 D T L</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/teen8">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen8.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Teenpatti Open</div>
              </a>
            </div>

            <div className="casino-list-item">
              <a href="/casino/teen20">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">20-20 Teenpatti</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/card32">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/card32.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">32 Cards A</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/teen9">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen9.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Teenpatti Test</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/dt6">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/dt6.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">1 Day Dragon Tiger</div>
              </a>
            </div>

            <div className="casino-list-item">
              <a href="/casino/teen">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Teenpatti 1-day</div>
              </a>
            </div>

            <div className="casino-list-item">
              <a href="/casino/poker">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/poker.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Poker 1-Day</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/poker20">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/poker20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">20-20 Poker</div>
              </a>
            </div>

            <div className="casino-list-item">
              <a href="/casino/dt20">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/dt20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">20-20 Dragon Tiger</div>
              </a>
            </div>

            <div className="casino-list-item">
              <a href="/casino/card32eu">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/card32eu.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">32 Cards B</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/ab20">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/ab20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Andar Bahar</div>
              </a>
            </div>

            <div className="casino-list-item">
              <a href="/casino/lucky7">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/lucky7.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Lucky 7 - A</div>
              </a>
            </div>

            <div className="casino-list-item">
              <a href="/casino/worli">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/worli.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Worli Matka</div>
              </a>
            </div>

            <div className="casino-list-item">
              <a href="/casino/aaa">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/aaa.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Amar Akbar Anthony</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/btable">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/btable.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Bollywood Casino</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
