import React from "react";
import "./index.scoped.css";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import { CASINO_VIDEO } from "./CasinoGeneric";
export default function WorliMatka() {
  return (
    <div
      _ngcontent-ng-c1798067373=""
      className="center-main-container casino-page ng-tns-c1798067373-8 ng-star-inserted"
    >
      <div
        _ngcontent-ng-c1798067373=""
        className="center-container ng-tns-c1798067373-8"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-page-container ng-tns-c1798067373-8 worli"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-title-header-mobile d-xl-none ng-tns-c1798067373-8"
          >
            <ul
              _ngcontent-ng-c1798067373=""
              className="nav nav-tabs menu-tabs ng-tns-c1798067373-8"
            >
              <li
                _ngcontent-ng-c1798067373=""
                className="nav-item ng-tns-c1798067373-8"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-8 active nav-link"
                >
                  Game
                </div>
              </li>
              <li
                _ngcontent-ng-c1798067373=""
                className="nav-item ng-tns-c1798067373-8"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-8 nav-link"
                >
                  {" "}
                  Placed Bet (0){" "}
                </div>
              </li>
            </ul>
            <div
              _ngcontent-ng-c1798067373=""
              className="pe-2 ng-tns-c1798067373-8"
            >
              <span
                _ngcontent-ng-c1798067373=""
                className="casino-rid ng-tns-c1798067373-8"
              >
                <a
                  _ngcontent-ng-c1798067373=""
                  className="d-block ng-tns-c1798067373-8"
                >
                  <small
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-8"
                  >
                    Rules
                  </small>
                </a>{" "}
                Round ID:{" "}
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-8 ng-star-inserted"
                >
                  23.241809163239
                </span>
                {/**/}
              </span>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-header ng-tns-c1798067373-8"
          >
            <span
              _ngcontent-ng-c1798067373=""
              className="casino-name ng-tns-c1798067373-8"
            >
              {" "}
              Instant Worli
              <a
                _ngcontent-ng-c1798067373=""
                className="ms-1 d-xl-inline d-none ng-tns-c1798067373-8"
              >
                <small
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-8"
                >
                  Rules
                </small>
              </a>
            </span>
            <span
              _ngcontent-ng-c1798067373=""
              className="casino-rid d-none d-xl-inline-block ng-tns-c1798067373-8"
            >
              <small
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-8"
              >
                Round ID:{" "}
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-8 ng-star-inserted"
                >
                  23.241809163239
                </span>
                {/**/}
              </small>
              <span
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-8 ng-star-inserted"
              >
                {" "}
                | Min: 10 {/**/}
                {/**/} | Max: 100000 {/**/}
                {/**/}
              </span>
              {/**/}
            </span>
          </div>
          {/**/}
          {/**/}
          <div
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-8 ng-star-inserted"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-video ng-tns-c1798067373-8"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="video-box-container ng-tns-c1798067373-8"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-video-box ng-tns-c1798067373-8 ng-star-inserted"
                >
                  <iframe
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-8"
                    src={CASINO_VIDEO_URL + CASINO_VIDEO.instantWorli}
                  />
                </div>
                {/**/}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-8 ng-star-inserted"
              >
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-video-cards ng-tns-c1798067373-8 ng-star-inserted"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="flip-card-container ng-tns-c1798067373-8"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="me-lg-2 me-1 ng-tns-c1798067373-8 ng-star-inserted"
                    >
                      <img
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-8 ng-star-inserted"
                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/10CC.webp"
                      />
                      {/**/}
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="me-lg-2 me-1 ng-tns-c1798067373-8 ng-star-inserted"
                    >
                      {/**/}
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="me-lg-2 me-1 ng-tns-c1798067373-8 ng-star-inserted"
                    >
                      {/**/}
                    </div>
                    {/**/}
                  </div>
                </div>
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
              </div>
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="clock ng-tns-c1798067373-8"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="count-no ng-tns-c1798067373-8 ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-8"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="content ng-tns-c1798067373-8"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        style={{ position: "absolute" }}
                        className="ng-tns-c1798067373-8"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="flip ng-tns-c1798067373-8"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="up ng-tns-c1798067373-8"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-8"
                            >
                              0
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="down ng-tns-c1798067373-8"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-8"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-8"
                              >
                                0
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        style={{ position: "absolute" }}
                        className="ng-tns-c1798067373-8"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="flip-card ng-tns-c1798067373-8"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="flip-card-inner ng-tns-c1798067373-8 ng-trigger ng-trigger-flip"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="flip-card-front ng-tns-c1798067373-8"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="up ng-tns-c1798067373-8"
                              >
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-8"
                                >
                                  0
                                </div>
                              </div>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="flip-card-back ng-tns-c1798067373-8"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="down ng-tns-c1798067373-8"
                              >
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-8"
                                >
                                  <div
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-8"
                                  >
                                    0
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
                {/**/}
                <div
                  _ngcontent-ng-c1798067373=""
                  className="count-no ng-tns-c1798067373-8 ng-star-inserted"
                  style={{ marginRight: 80 }}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-8"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="content ng-tns-c1798067373-8"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        style={{ position: "absolute" }}
                        className="ng-tns-c1798067373-8"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="flip ng-tns-c1798067373-8"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="up ng-tns-c1798067373-8"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-8"
                            >
                              0
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="down ng-tns-c1798067373-8"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-8"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-8"
                              >
                                0
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        style={{ position: "absolute" }}
                        className="ng-tns-c1798067373-8"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="flip-card ng-tns-c1798067373-8"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="flip-card-inner ng-tns-c1798067373-8 ng-trigger ng-trigger-flip"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="flip-card-front ng-tns-c1798067373-8"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="up ng-tns-c1798067373-8"
                              >
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-8"
                                >
                                  0
                                </div>
                              </div>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="flip-card-back ng-tns-c1798067373-8"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="down ng-tns-c1798067373-8"
                              >
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-8"
                                >
                                  <div
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-8"
                                  >
                                    0
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
                {/**/}
              </div>
            </div>
            {/**/}
            {/**/}
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-detail ng-tns-c1798067373-8"
            >
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-detail ng-tns-c1798067373-8 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-table ng-tns-c1798067373-8"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="d-xl-block d-none w-100 ng-tns-c1798067373-8"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8 worlibox"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="worli-left ng-tns-c1798067373-8"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="worli-box-title ng-tns-c1798067373-8"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-8"
                          >
                            {" "}
                            9{" "}
                          </b>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="worli-box-row ng-tns-c1798067373-8"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              1
                            </span>
                            {/**/}
                            {/**/}
                            {/**/}
                            {/**/}
                            {/**/}
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              2
                            </span>
                            {/**/}
                            {/**/}
                            {/**/}
                            {/**/}
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            {/**/}
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              3
                            </span>
                            {/**/}
                            {/**/}
                            {/**/}
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            {/**/}
                            {/**/}
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              4
                            </span>
                            {/**/}
                            {/**/}
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            {/**/}
                            {/**/}
                            {/**/}
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              5
                            </span>
                            {/**/}
                          </div>
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="worli-box-row ng-tns-c1798067373-8"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              6
                            </span>
                            {/**/}
                            {/**/}
                            {/**/}
                            {/**/}
                            {/**/}
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              7
                            </span>
                            {/**/}
                            {/**/}
                            {/**/}
                            {/**/}
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            {/**/}
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              8
                            </span>
                            {/**/}
                            {/**/}
                            {/**/}
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            {/**/}
                            {/**/}
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              9
                            </span>
                            {/**/}
                            {/**/}
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            {/**/}
                            {/**/}
                            {/**/}
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              0
                            </span>
                            {/**/}
                          </div>
                          {/**/}
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="worli-right ng-tns-c1798067373-8"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="worli-box-title ng-tns-c1798067373-8 ng-star-inserted"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-8"
                          >
                            {" "}
                            9{" "}
                          </b>
                        </div>
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="worli-box-row ng-tns-c1798067373-8"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-8"
                              >
                                {" "}
                                Line 1{" "}
                              </b>
                            </span>
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="d-block ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              1|2|3|4|5{" "}
                            </span>
                            {/**/}
                            {/**/}
                            {/**/}
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            {/**/}
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-8"
                              >
                                {" "}
                                ODD{" "}
                              </b>
                            </span>
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="d-block ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              1|3|5|7|9{" "}
                            </span>
                            {/**/}
                          </div>
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="worli-box-row ng-tns-c1798067373-8"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-8"
                              >
                                {" "}
                                Line 2{" "}
                              </b>
                            </span>
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="d-block ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              6|7|8|9|0{" "}
                            </span>
                            {/**/}
                            {/**/}
                            {/**/}
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            {/**/}
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-8"
                              >
                                {" "}
                                EVEN{" "}
                              </b>
                            </span>
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="d-block ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              2|4|6|8|0{" "}
                            </span>
                            {/**/}
                          </div>
                          {/**/}
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="text-end worli_welcome ng-tns-c1798067373-8"
                    >
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-8"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-8"
                        >
                          welcome single
                        </b>
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="d-xl-none d-block w-100 ng-tns-c1798067373-8"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="worlibox ng-tns-c1798067373-8"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="worli-box-title ng-tns-c1798067373-8"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-8"
                        >
                          {" "}
                          9
                        </b>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-8 w-100 worli-left"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="worli-box-row ng-tns-c1798067373-8"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              1
                            </span>
                            {/**/}
                            {/**/}
                            {/**/}
                            {/**/}
                            {/**/}
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              2
                            </span>
                            {/**/}
                            {/**/}
                            {/**/}
                            {/**/}
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            {/**/}
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              3
                            </span>
                            {/**/}
                            {/**/}
                            {/**/}
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            {/**/}
                            {/**/}
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              4
                            </span>
                            {/**/}
                            {/**/}
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            {/**/}
                            {/**/}
                            {/**/}
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              5
                            </span>
                            {/**/}
                          </div>
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="worli-box-row ng-tns-c1798067373-8"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              6
                            </span>
                            {/**/}
                            {/**/}
                            {/**/}
                            {/**/}
                            {/**/}
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              7
                            </span>
                            {/**/}
                            {/**/}
                            {/**/}
                            {/**/}
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            {/**/}
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              8
                            </span>
                            {/**/}
                            {/**/}
                            {/**/}
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            {/**/}
                            {/**/}
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              9
                            </span>
                            {/**/}
                            {/**/}
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            {/**/}
                            {/**/}
                            {/**/}
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              0
                            </span>
                            {/**/}
                          </div>
                          {/**/}
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="worli-box-title mt-3 ng-tns-c1798067373-8"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-8 ng-star-inserted"
                        >
                          9{" "}
                        </b>
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-8 w-100 worli-right"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="worli-box-row ng-tns-c1798067373-8"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back Worli_odd_box_mobile ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-8"
                              >
                                {" "}
                                Line 1{" "}
                              </b>
                            </span>
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="d-block ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              1|2|3|4|5
                            </span>
                            {/**/}
                            {/**/}
                            {/**/}
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back Worli_odd_box_mobile ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            {/**/}
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-8"
                              >
                                {" "}
                                ODD{" "}
                              </b>
                            </span>
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="d-block ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              1|3|5|7|9{" "}
                            </span>
                            {/**/}
                          </div>
                          {/**/}
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back Worli_odd_box_mobile ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-8"
                              >
                                {" "}
                                Line 2{" "}
                              </b>
                            </span>
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="d-block ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              6|7|8|9|0{" "}
                            </span>
                            {/**/}
                            {/**/}
                            {/**/}
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="worli-odd-box back Worli_odd_box_mobile ng-tns-c1798067373-8 ng-star-inserted"
                          >
                            {/**/}
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-8"
                              >
                                {" "}
                                EVEN{" "}
                              </b>
                            </span>
                            {/**/}
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="d-block ng-tns-c1798067373-8 ng-star-inserted"
                            >
                              2|4|6|8|0{" "}
                            </span>
                            {/**/}
                          </div>
                          {/**/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-8"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-last-result-title ng-tns-c1798067373-8"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-8"
                  >
                    Last Result
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-8"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      View All
                    </a>
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-last-results ng-tns-c1798067373-8 ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-8 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-8 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-8 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-8 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-8 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-8 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-8 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-8 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-8 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-8 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                </div>
                {/**/}
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-8 d-none matchbettable w-100 ng-star-inserted"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="table-responsive ng-tns-c1798067373-8"
            >
              <table
                _ngcontent-ng-c1798067373=""
                className="table ng-tns-c1798067373-8"
              >
                <thead
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-8"
                >
                  <tr
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-8"
                  >
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      Matched Bet
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-8"
                    >
                      Odds
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-8"
                    >
                      Stake
                    </th>
                  </tr>
                </thead>
                {/**/}
              </table>
            </div>
          </div>
          {/**/}
          {/**/}
        </div>
        {/**/}
      </div>
      <div
        _ngcontent-ng-c1798067373=""
        className="sidebar right-sidebar casino-right-sidebar ng-tns-c1798067373-8"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="sidebar-box place-bet-container d-xl-block d-none ng-tns-c1798067373-8"
        >
          {/**/}
          <div
            _ngcontent-ng-c1798067373=""
            className="sidebar-title ng-tns-c1798067373-8"
          >
            <h4 _ngcontent-ng-c1798067373="" className="ng-tns-c1798067373-8">
              Place Bet
            </h4>
          </div>
          {/**/}
        </div>
        <div
          _ngcontent-ng-c1798067373=""
          className="sidebar-box my-bet-container d-xl-block d-none ng-tns-c1798067373-8"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="sidebar-title ng-tns-c1798067373-8"
          >
            <h4 _ngcontent-ng-c1798067373="" className="ng-tns-c1798067373-8">
              My Bet
            </h4>
            <a
              _ngcontent-ng-c1798067373=""
              className="sideviewmore ng-tns-c1798067373-8"
            >
              View more
            </a>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="my-bets ng-tns-c1798067373-8"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="table-responsive w-100 ng-tns-c1798067373-8"
            >
              <table
                _ngcontent-ng-c1798067373=""
                className="table ng-tns-c1798067373-8"
              >
                <thead
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-8"
                >
                  <tr
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-8"
                  >
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      Matched Bet
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-8"
                    >
                      Odds
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-8"
                    >
                      Stake
                    </th>
                  </tr>
                </thead>
                {/**/}
              </table>
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c1798067373=""
          className="sidebar-box sidebar-box-rules my-bet-container ng-tns-c1798067373-8"
        >
          <app-casino-game-rules
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-8"
            _nghost-ng-c1761710207=""
          >
            {/**/}
            {/**/}
            {/**/}
            {/**/}
          </app-casino-game-rules>
        </div>
      </div>
    </div>
  );
}
