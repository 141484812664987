import React from "react";
import "./index.scoped.css";
import { CASINO_VIDEO } from "./CasinoGeneric";
import { CASINO_VIDEO_URL } from "../../utils/constants";
export default function BTable() {
  return (
    <div
      _ngcontent-ng-c1798067373=""
      className="center-main-container casino-page ng-tns-c1798067373-0 ng-star-inserted"
    >
      <div
        _ngcontent-ng-c1798067373=""
        className="center-container ng-tns-c1798067373-0"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-page-container ng-tns-c1798067373-0 bollywood"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-title-header-mobile d-xl-none ng-tns-c1798067373-0"
          >
            <ul
              _ngcontent-ng-c1798067373=""
              className="nav nav-tabs menu-tabs ng-tns-c1798067373-0"
            >
              <li
                _ngcontent-ng-c1798067373=""
                className="nav-item ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0 active nav-link"
                >
                  Game
                </div>
              </li>
              <li
                _ngcontent-ng-c1798067373=""
                className="nav-item ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0 nav-link"
                >
                  {" "}
                  Placed Bet (0){" "}
                </div>
              </li>
            </ul>
            <div
              _ngcontent-ng-c1798067373=""
              className="pe-2 ng-tns-c1798067373-0"
            >
              <span
                _ngcontent-ng-c1798067373=""
                className="casino-rid ng-tns-c1798067373-0"
              >
                <a
                  _ngcontent-ng-c1798067373=""
                  className="d-block ng-tns-c1798067373-0"
                >
                  <small
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    Rules
                  </small>
                </a>{" "}
                Round ID:{" "}
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0 ng-star-inserted"
                >
                  26.241809190010
                </span>
                {/**/}
              </span>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-header ng-tns-c1798067373-0"
          >
            <span
              _ngcontent-ng-c1798067373=""
              className="casino-name ng-tns-c1798067373-0"
            >
              {" "}
              Bollywood Table
              <a
                _ngcontent-ng-c1798067373=""
                className="ms-1 d-xl-inline d-none ng-tns-c1798067373-0"
              >
                <small
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  Rules
                </small>
              </a>
            </span>
            <span
              _ngcontent-ng-c1798067373=""
              className="casino-rid d-none d-xl-inline-block ng-tns-c1798067373-0"
            >
              <small
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-0"
              >
                Round ID:{" "}
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0 ng-star-inserted"
                >
                  26.241809190010
                </span>
                {/**/}
              </small>
              <span
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-0 ng-star-inserted"
              >
                {" "}
                | Min: 100 {/**/}
                {/**/} | Max: 300000 {/**/}
                {/**/}
              </span>
              {/**/}
            </span>
          </div>
          {/**/}
          {/**/}
          <div
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-0 ng-star-inserted"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-video ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="video-box-container ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-video-box ng-tns-c1798067373-0 ng-star-inserted"
                >
                  <iframe
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                    src={CASINO_VIDEO_URL + CASINO_VIDEO.bollyWoodTable}
                  />
                </div>
                {/**/}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-0 ng-star-inserted"
              >
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-video-cards ng-tns-c1798067373-0 ng-star-inserted"
                >
                  <h5
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    Card
                  </h5>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="flip-card-container ng-tns-c1798067373-0"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="me-lg-2 me-1 ng-tns-c1798067373-0 ng-star-inserted"
                    >
                      <img
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/1.webp"
                      />
                    </div>
                    {/**/}
                  </div>
                </div>
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
              </div>
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="clock ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="count-no ng-tns-c1798067373-0 ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="content ng-tns-c1798067373-0"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        style={{ position: "absolute" }}
                        className="ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="flip ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="up ng-tns-c1798067373-0"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-0"
                            >
                              4
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="down ng-tns-c1798067373-0"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-0"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0"
                              >
                                4
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        style={{ position: "absolute" }}
                        className="ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="flip-card ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="flip-card-inner ng-tns-c1798067373-0 ng-trigger ng-trigger-flip ng-animating"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="flip-card-front ng-tns-c1798067373-0"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="up ng-tns-c1798067373-0"
                              >
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  4
                                </div>
                              </div>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="flip-card-back ng-tns-c1798067373-0"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="down ng-tns-c1798067373-0"
                              >
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  <div
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-0"
                                  >
                                    4
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
                {/**/}
                <div
                  _ngcontent-ng-c1798067373=""
                  className="count-no ng-tns-c1798067373-0 ng-star-inserted"
                  style={{ marginRight: 80 }}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="content ng-tns-c1798067373-0"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        style={{ position: "absolute" }}
                        className="ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="flip ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="up ng-tns-c1798067373-0"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-0"
                            >
                              0
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="down ng-tns-c1798067373-0"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-0"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0"
                              >
                                0
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        style={{ position: "absolute" }}
                        className="ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="flip-card ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="flip-card-inner ng-tns-c1798067373-0 ng-trigger ng-trigger-flip"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="flip-card-front ng-tns-c1798067373-0"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="up ng-tns-c1798067373-0"
                              >
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  0
                                </div>
                              </div>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="flip-card-back ng-tns-c1798067373-0"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="down ng-tns-c1798067373-0"
                              >
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  <div
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-0"
                                  >
                                    0
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
                {/**/}
              </div>
            </div>
            {/**/}
            {/**/}
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-detail ng-tns-c1798067373-0"
            >
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table ng-tns-c1798067373-0 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="desktop-design d-lg-block d-none w-100 ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-box casino-table-box_top_bg ng-tns-c1798067373-0"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="w-100 text-end ng-tns-c1798067373-0"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="min_max_collapse ng-tns-c1798067373-0"
                      >
                        <a
                          _ngcontent-ng-c1798067373=""
                          data-bs-toggle="collapse"
                          href="#collapserangebollywood12"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapserangebollywood12"
                          className="minmax-btn collapsed ng-tns-c1798067373-0"
                        >
                          <i
                            _ngcontent-ng-c1798067373=""
                            className="fas fa-info-circle ng-tns-c1798067373-0"
                          />
                        </a>
                        <div
                          _ngcontent-ng-c1798067373=""
                          id="collapserangebollywood12"
                          className="range-collpase collapse ng-tns-c1798067373-0"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-0 ng-tns-c1798067373-0"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-0"
                            >
                              Min:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-0"
                            >
                              {" "}
                              100 {/**/}
                              {/**/}
                            </span>
                          </span>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-1 ng-tns-c1798067373-0"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-0"
                            >
                              Max:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0 ng-star-inserted"
                              >
                                {" "}
                                150000{" "}
                              </span>
                              {/**/}
                              {/**/}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odd-box-container ng-tns-c1798067373-0 ng-star-inserted"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name ng-tns-c1798067373-0"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="text-danger ng-tns-c1798067373-0 ng-star-inserted"
                        >
                          A.
                        </span>
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/} DON{" "}
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-book d-md-none ng-tns-c1798067373-0"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0 casino-odds-box-row"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds-box back ng-tns-c1798067373-0"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-0"
                          >
                            15
                          </span>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds-box lay ng-tns-c1798067373-0"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-0"
                          >
                            17
                          </span>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-book text-center d-none d-md-block w-100 ng-tns-c1798067373-0"
                      >
                        {" "}
                        0{" "}
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odd-box-container ng-tns-c1798067373-0 ng-star-inserted"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name ng-tns-c1798067373-0"
                      >
                        {/**/}
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="text-danger ng-tns-c1798067373-0 ng-star-inserted"
                        >
                          B.
                        </span>
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/} Amar Akbar Anthony{" "}
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-book d-md-none ng-tns-c1798067373-0"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0 casino-odds-box-row"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds-box back ng-tns-c1798067373-0"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-0"
                          >
                            5.15
                          </span>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds-box lay ng-tns-c1798067373-0"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-0"
                          >
                            5.5
                          </span>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-book text-center d-none d-md-block w-100 ng-tns-c1798067373-0"
                      >
                        {" "}
                        0{" "}
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odd-box-container ng-tns-c1798067373-0 ng-star-inserted"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name ng-tns-c1798067373-0"
                      >
                        {/**/}
                        {/**/}
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="text-danger ng-tns-c1798067373-0 ng-star-inserted"
                        >
                          C.
                        </span>
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/} Sahib Bibi Aur Ghula{" "}
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-book d-md-none ng-tns-c1798067373-0"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0 casino-odds-box-row"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds-box back ng-tns-c1798067373-0"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-0"
                          >
                            5.15
                          </span>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds-box lay ng-tns-c1798067373-0"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-0"
                          >
                            5.5
                          </span>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-book text-center d-none d-md-block w-100 ng-tns-c1798067373-0"
                      >
                        {" "}
                        0{" "}
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odd-box-container ng-tns-c1798067373-0 ng-star-inserted"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name ng-tns-c1798067373-0"
                      >
                        {/**/}
                        {/**/}
                        {/**/}
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="text-danger ng-tns-c1798067373-0 ng-star-inserted"
                        >
                          D.
                        </span>
                        {/**/}
                        {/**/}
                        {/**/} Dharam Veer{" "}
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-book d-md-none ng-tns-c1798067373-0"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0 casino-odds-box-row"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds-box back ng-tns-c1798067373-0"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-0"
                          >
                            7.65
                          </span>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds-box lay ng-tns-c1798067373-0"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-0"
                          >
                            8.35
                          </span>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-book text-center d-none d-md-block w-100 ng-tns-c1798067373-0"
                      >
                        {" "}
                        0{" "}
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odd-box-container ng-tns-c1798067373-0 ng-star-inserted"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name ng-tns-c1798067373-0"
                      >
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="text-danger ng-tns-c1798067373-0 ng-star-inserted"
                        >
                          E.
                        </span>
                        {/**/}
                        {/**/} Kis KisKo Pyaar Karo{" "}
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-book d-md-none ng-tns-c1798067373-0"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0 casino-odds-box-row"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds-box back ng-tns-c1798067373-0"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-0"
                          >
                            3.85
                          </span>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds-box lay ng-tns-c1798067373-0"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-0"
                          >
                            4.15
                          </span>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-book text-center d-none d-md-block w-100 ng-tns-c1798067373-0"
                      >
                        {" "}
                        0{" "}
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odd-box-container ng-tns-c1798067373-0 ng-star-inserted"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name ng-tns-c1798067373-0"
                      >
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="text-danger ng-tns-c1798067373-0 ng-star-inserted"
                        >
                          F.
                        </span>
                        {/**/} Ghulam{" "}
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-book d-md-none ng-tns-c1798067373-0"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0 casino-odds-box-row"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds-box back ng-tns-c1798067373-0"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-0"
                          >
                            5.15
                          </span>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds-box lay ng-tns-c1798067373-0"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-0"
                          >
                            5.5
                          </span>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-book text-center d-none d-md-block w-100 ng-tns-c1798067373-0"
                      >
                        {" "}
                        0{" "}
                      </div>
                    </div>
                    {/**/}
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-box mt-3 ng-tns-c1798067373-0"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-left-box left-odd-box ng-tns-c1798067373-0"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="w-100 text-end ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-0"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            href="#collapserange2btodds"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapserange2btodds"
                            className="minmax-btn collapsed ng-tns-c1798067373-0"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-0"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            id="collapserange2btodds"
                            className="range-collpase collapse ng-tns-c1798067373-0"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-0"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0"
                              >
                                {" "}
                                100 {/**/}
                                {/**/}
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-0"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0 ng-star-inserted"
                                >
                                  {" "}
                                  50000{" "}
                                </span>
                                {/**/}
                                {/**/}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odd-box-container ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-name ng-tns-c1798067373-0"
                        >
                          {" "}
                          Odd{" "}
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-book text-danger d-md-none ng-tns-c1798067373-0"
                          />
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0 casino-odds-box-row"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box back ng-tns-c1798067373-0"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-0"
                            >
                              1.31
                            </span>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box lay ng-tns-c1798067373-0"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-0"
                            >
                              1.36
                            </span>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-book text-center d-none d-md-block w-100 ng-tns-c1798067373-0"
                        >
                          {" "}
                          0{" "}
                        </div>
                      </div>
                      {/**/}
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-right-box right-odd-box ng-tns-c1798067373-0"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="aaa-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="w-100 text-end ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min_max_collapse ng-tns-c1798067373-0"
                          >
                            <a
                              _ngcontent-ng-c1798067373=""
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              className="minmax-btn collapsed ng-tns-c1798067373-0"
                              href="#collapserange2bt14"
                              aria-controls="collapserange2bt14"
                            >
                              <i
                                _ngcontent-ng-c1798067373=""
                                className="fas fa-info-circle ng-tns-c1798067373-0"
                              />
                            </a>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="range-collpase collapse ng-tns-c1798067373-0"
                              id="collapserange2bt14"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-0 ng-tns-c1798067373-0"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  Min:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  100 {/**/}
                                  {/**/}
                                </span>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-1 ng-tns-c1798067373-0"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  Max:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  <span
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-0 ng-star-inserted"
                                  >
                                    {" "}
                                    50000{" "}
                                  </span>
                                  {/**/}
                                  {/**/}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds text-center ng-tns-c1798067373-0"
                        >
                          {" "}
                          1.97{" "}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-0"
                          >
                            Dulha Dulhan K-Q
                          </span>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-book text-center ng-tns-c1798067373-0"
                        >
                          {" "}
                          0{" "}
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="aaa-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="w-100 text-end ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min_max_collapse ng-tns-c1798067373-0"
                          >
                            <a
                              _ngcontent-ng-c1798067373=""
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              className="minmax-btn collapsed ng-tns-c1798067373-0"
                              href="#collapserange2bt15"
                              aria-controls="collapserange2bt15"
                            >
                              <i
                                _ngcontent-ng-c1798067373=""
                                className="fas fa-info-circle ng-tns-c1798067373-0"
                              />
                            </a>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="range-collpase collapse ng-tns-c1798067373-0"
                              id="collapserange2bt15"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-0 ng-tns-c1798067373-0"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  Min:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  100 {/**/}
                                  {/**/}
                                </span>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-1 ng-tns-c1798067373-0"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  Max:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  <span
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-0 ng-star-inserted"
                                  >
                                    {" "}
                                    50000{" "}
                                  </span>
                                  {/**/}
                                  {/**/}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds text-center ng-tns-c1798067373-0"
                        >
                          {" "}
                          1.97{" "}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-0"
                          >
                            Barati J-A
                          </span>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-book text-center ng-tns-c1798067373-0"
                        >
                          {" "}
                          0{" "}
                        </div>
                      </div>
                      {/**/}
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-box mt-3 ng-tns-c1798067373-0"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-left-box ng-tns-c1798067373-0"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="w-100 text-end ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-0"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            href="#collapserange2asdasd"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapserange2asdasd"
                            className="minmax-btn collapsed ng-tns-c1798067373-0"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-0"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            id="collapserange2asdasd"
                            className="range-collpase collapse ng-tns-c1798067373-0"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-0"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0"
                              >
                                {" "}
                                100 {/**/}
                                {/**/}
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-0"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0 ng-star-inserted"
                                >
                                  {" "}
                                  12500{" "}
                                </span>
                                {/**/}
                                {/**/}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="aaa-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds text-center ng-tns-c1798067373-0"
                        >
                          {" "}
                          1.97{" "}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-0 ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="card-icon ms-1 ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="card-red ng-tns-c1798067373-0"
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                                />
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="card-icon ms-1 ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="card-red ng-tns-c1798067373-0"
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                                />
                              </span>
                            </span>
                          </div>
                          {/**/}
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-book text-center ng-tns-c1798067373-0"
                        >
                          {" "}
                          0{" "}
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="aaa-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds text-center ng-tns-c1798067373-0"
                        >
                          {" "}
                          1.97{" "}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme"
                        >
                          {/**/}
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-0 ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="card-icon ms-1 ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="card-black ng-tns-c1798067373-0"
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                                />
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="card-icon ms-1 ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="card-black ng-tns-c1798067373-0"
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                                />
                              </span>
                            </span>
                          </div>
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-book text-center ng-tns-c1798067373-0"
                        >
                          {" "}
                          0{" "}
                        </div>
                      </div>
                      {/**/}
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-right-box right-cards ng-tns-c1798067373-0"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="w-100 text-end ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-0"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            href="#collapserange24cardbt"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapserange24cardbt"
                            className="minmax-btn collapsed ng-tns-c1798067373-0"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-0"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            id="collapserange24cardbt"
                            className="range-collpase collapse ng-tns-c1798067373-0"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-0"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0"
                              >
                                {" "}
                                100 {/**/}
                                {/**/}
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-0"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0 ng-star-inserted"
                                >
                                  {" "}
                                  12500{" "}
                                </span>
                                {/**/}
                                {/**/}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <h4
                        _ngcontent-ng-c1798067373=""
                        className="w-100 text-center ng-tns-c1798067373-0"
                        style={{ fontSize: 16 }}
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                        >
                          Cards 3.75
                        </b>
                      </h4>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0 ng-star-inserted"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/J.webp"
                          />
                          {/**/}
                          {/**/}
                          {/**/}
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-book ng-tns-c1798067373-0"
                        >
                          <p
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0"
                          >
                            0
                          </p>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                        >
                          {/**/}
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0 ng-star-inserted"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/Q.webp"
                          />
                          {/**/}
                          {/**/}
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-book ng-tns-c1798067373-0"
                        >
                          <p
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0"
                          >
                            0
                          </p>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                        >
                          {/**/}
                          {/**/}
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0 ng-star-inserted"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/K.webp"
                          />
                          {/**/}
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-book ng-tns-c1798067373-0"
                        >
                          <p
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0"
                          >
                            0
                          </p>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                        >
                          {/**/}
                          {/**/}
                          {/**/}
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0 ng-star-inserted"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/A.webp"
                          />
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-book ng-tns-c1798067373-0"
                        >
                          <p
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0"
                          >
                            0
                          </p>
                        </div>
                      </div>
                      {/**/}
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="mobile-design d-lg-none d-block w-100 ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-box mobile-akbar ng-tns-c1798067373-0"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="w-100 ng-tns-c1798067373-0"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-header ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min_max_odds justify-content-start d-lg-none d-flex ng-tns-c1798067373-0"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-0"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0"
                              >
                                {" "}
                                100 {/**/}
                                {/**/}
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-0"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0 ng-star-inserted"
                                >
                                  {" "}
                                  150000{" "}
                                </span>
                                {/**/}
                                {/**/}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-body ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-table-row ng-tns-c1798067373-0 ng-star-inserted"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-detail ng-tns-c1798067373-0"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-name d-flex w-100 ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="text-danger ng-tns-c1798067373-0 ng-star-inserted"
                              >
                                A.
                              </span>
                              {/**/}
                              {/**/}
                              {/**/}
                              {/**/}
                              {/**/}
                              {/**/} DON{" "}
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-book d-md-none ng-tns-c1798067373-0"
                            >
                              {" "}
                              0{" "}
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0 casino_odds_row"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds-box back ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="casino-odds ng-tns-c1798067373-0"
                              >
                                15
                              </span>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds-box lay ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="casino-odds ng-tns-c1798067373-0"
                              >
                                17
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-table-row ng-tns-c1798067373-0 ng-star-inserted"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-detail ng-tns-c1798067373-0"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-name d-flex w-100 ng-tns-c1798067373-0"
                            >
                              {/**/}
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="text-danger ng-tns-c1798067373-0 ng-star-inserted"
                              >
                                B.
                              </span>
                              {/**/}
                              {/**/}
                              {/**/}
                              {/**/}
                              {/**/} Amar Akbar Anthony{" "}
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-book d-md-none ng-tns-c1798067373-0"
                            >
                              {" "}
                              0{" "}
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0 casino_odds_row"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds-box back ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="casino-odds ng-tns-c1798067373-0"
                              >
                                5.15
                              </span>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds-box lay ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="casino-odds ng-tns-c1798067373-0"
                              >
                                5.5
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-table-row ng-tns-c1798067373-0 ng-star-inserted"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-detail ng-tns-c1798067373-0"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-name d-flex w-100 ng-tns-c1798067373-0"
                            >
                              {/**/}
                              {/**/}
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="text-danger ng-tns-c1798067373-0 ng-star-inserted"
                              >
                                C.
                              </span>
                              {/**/}
                              {/**/}
                              {/**/}
                              {/**/} Sahib Bibi Aur Ghula{" "}
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-book d-md-none ng-tns-c1798067373-0"
                            >
                              {" "}
                              0{" "}
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0 casino_odds_row"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds-box back ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="casino-odds ng-tns-c1798067373-0"
                              >
                                5.15
                              </span>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds-box lay ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="casino-odds ng-tns-c1798067373-0"
                              >
                                5.5
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-table-row ng-tns-c1798067373-0 ng-star-inserted"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-detail ng-tns-c1798067373-0"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-name d-flex w-100 ng-tns-c1798067373-0"
                            >
                              {/**/}
                              {/**/}
                              {/**/}
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="text-danger ng-tns-c1798067373-0 ng-star-inserted"
                              >
                                D.
                              </span>
                              {/**/}
                              {/**/}
                              {/**/} Dharam Veer{" "}
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-book d-md-none ng-tns-c1798067373-0"
                            >
                              {" "}
                              0{" "}
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0 casino_odds_row"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds-box back ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="casino-odds ng-tns-c1798067373-0"
                              >
                                7.65
                              </span>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds-box lay ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="casino-odds ng-tns-c1798067373-0"
                              >
                                8.35
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-table-row ng-tns-c1798067373-0 ng-star-inserted"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-detail ng-tns-c1798067373-0"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-name d-flex w-100 ng-tns-c1798067373-0"
                            >
                              {/**/}
                              {/**/}
                              {/**/}
                              {/**/}
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="text-danger ng-tns-c1798067373-0 ng-star-inserted"
                              >
                                E.
                              </span>
                              {/**/}
                              {/**/} Kis KisKo Pyaar Karo{" "}
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-book d-md-none ng-tns-c1798067373-0"
                            >
                              {" "}
                              0{" "}
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0 casino_odds_row"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds-box back ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="casino-odds ng-tns-c1798067373-0"
                              >
                                3.85
                              </span>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds-box lay ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="casino-odds ng-tns-c1798067373-0"
                              >
                                4.15
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-table-row ng-tns-c1798067373-0 ng-star-inserted"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-detail ng-tns-c1798067373-0"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-name d-flex w-100 ng-tns-c1798067373-0"
                            >
                              {/**/}
                              {/**/}
                              {/**/}
                              {/**/}
                              {/**/}
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="text-danger ng-tns-c1798067373-0 ng-star-inserted"
                              >
                                F.
                              </span>
                              {/**/} Ghulam{" "}
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-book d-md-none ng-tns-c1798067373-0"
                            >
                              {" "}
                              0{" "}
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0 casino_odds_row"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds-box back ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="casino-odds ng-tns-c1798067373-0"
                              >
                                5.15
                              </span>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds-box lay ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="casino-odds ng-tns-c1798067373-0"
                              >
                                5.5
                              </span>
                            </div>
                          </div>
                        </div>
                        {/**/}
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-box mobile-akbar ng-tns-c1798067373-0"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="w-100 ng-tns-c1798067373-0"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-header casino-table-header_border mt-3 ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min_max_odds justify-content-start d-lg-none d-flex ng-tns-c1798067373-0"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-0"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0"
                              >
                                {" "}
                                100 {/**/}
                                {/**/}
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-0"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0 ng-star-inserted"
                                >
                                  {" "}
                                  50000{" "}
                                </span>
                                {/**/}
                                {/**/}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-body ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-table-row ng-tns-c1798067373-0 ng-star-inserted"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-detail ng-tns-c1798067373-0"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-name d-flex w-100 ng-tns-c1798067373-0"
                            >
                              {" "}
                              Odd{" "}
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-book d-md-none ng-tns-c1798067373-0"
                            >
                              {" "}
                              0{" "}
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0 casino_odds_row"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds-box back ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="casino-odds ng-tns-c1798067373-0"
                              >
                                1.31
                              </span>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds-box lay ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="casino-odds ng-tns-c1798067373-0"
                              >
                                1.36
                              </span>
                            </div>
                          </div>
                        </div>
                        {/**/}
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-box mt-3 ng-tns-c1798067373-0"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-right-box right-odd-box ng-tns-c1798067373-0"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="aaa-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="w-100 text-end ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min_max_collapse ng-tns-c1798067373-0"
                          >
                            <a
                              _ngcontent-ng-c1798067373=""
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              className="minmax-btn collapsed ng-tns-c1798067373-0"
                              href="#collapserange2bt14"
                              aria-controls="collapserange2bt14"
                            >
                              <i
                                _ngcontent-ng-c1798067373=""
                                className="fas fa-info-circle ng-tns-c1798067373-0"
                              />
                            </a>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="range-collpase collapse ng-tns-c1798067373-0"
                              id="collapserange2bt14"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-0 ng-tns-c1798067373-0"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  Min:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  100 {/**/}
                                  {/**/}
                                </span>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-1 ng-tns-c1798067373-0"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  Max:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  <span
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-0 ng-star-inserted"
                                  >
                                    {" "}
                                    50000{" "}
                                  </span>
                                  {/**/}
                                  {/**/}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds text-center ng-tns-c1798067373-0"
                        >
                          {" "}
                          1.97{" "}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-0"
                          >
                            Dulha Dulhan K-Q
                          </span>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-book text-center ng-tns-c1798067373-0"
                        >
                          {" "}
                          0{" "}
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="aaa-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="w-100 text-end ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min_max_collapse ng-tns-c1798067373-0"
                          >
                            <a
                              _ngcontent-ng-c1798067373=""
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              className="minmax-btn collapsed ng-tns-c1798067373-0"
                              href="#collapserange2bt15"
                              aria-controls="collapserange2bt15"
                            >
                              <i
                                _ngcontent-ng-c1798067373=""
                                className="fas fa-info-circle ng-tns-c1798067373-0"
                              />
                            </a>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="range-collpase collapse ng-tns-c1798067373-0"
                              id="collapserange2bt15"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-0 ng-tns-c1798067373-0"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  Min:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  100 {/**/}
                                  {/**/}
                                </span>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-1 ng-tns-c1798067373-0"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  Max:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  <span
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-0 ng-star-inserted"
                                  >
                                    {" "}
                                    50000{" "}
                                  </span>
                                  {/**/}
                                  {/**/}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds text-center ng-tns-c1798067373-0"
                        >
                          {" "}
                          1.97{" "}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-0"
                          >
                            Barati J-A
                          </span>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-book text-center ng-tns-c1798067373-0"
                        >
                          {" "}
                          0{" "}
                        </div>
                      </div>
                      {/**/}
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-box ng-tns-c1798067373-0"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-left-box ng-tns-c1798067373-0"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="aaa-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="w-100 text-end ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min_max_collapse ng-tns-c1798067373-0"
                          >
                            <a
                              _ngcontent-ng-c1798067373=""
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              className="minmax-btn collapsed ng-tns-c1798067373-0"
                              href="#collapserangerb8"
                              aria-controls="collapserangerb8"
                            >
                              <i
                                _ngcontent-ng-c1798067373=""
                                className="fas fa-info-circle ng-tns-c1798067373-0"
                              />
                            </a>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="range-collpase collapse ng-tns-c1798067373-0"
                              id="collapserangerb8"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-0 ng-tns-c1798067373-0"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  Min:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  100 {/**/}
                                  {/**/}
                                </span>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-1 ng-tns-c1798067373-0"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  Max:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  <span
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-0 ng-star-inserted"
                                  >
                                    {" "}
                                    12500{" "}
                                  </span>
                                  {/**/}
                                  {/**/}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds text-center ng-tns-c1798067373-0"
                        >
                          {" "}
                          1.97{" "}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-0 ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="card-icon ms-1 ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="card-red ng-tns-c1798067373-0"
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                                />
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="card-icon ms-1 ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="card-red ng-tns-c1798067373-0"
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                                />
                              </span>
                            </span>
                          </div>
                          {/**/}
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-book text-center ng-tns-c1798067373-0"
                        >
                          {" "}
                          0{" "}
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="aaa-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="w-100 text-end ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min_max_collapse ng-tns-c1798067373-0"
                          >
                            <a
                              _ngcontent-ng-c1798067373=""
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              className="minmax-btn collapsed ng-tns-c1798067373-0"
                              href="#collapserangerb9"
                              aria-controls="collapserangerb9"
                            >
                              <i
                                _ngcontent-ng-c1798067373=""
                                className="fas fa-info-circle ng-tns-c1798067373-0"
                              />
                            </a>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="range-collpase collapse ng-tns-c1798067373-0"
                              id="collapserangerb9"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-0 ng-tns-c1798067373-0"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  Min:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  100 {/**/}
                                  {/**/}
                                </span>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-1 ng-tns-c1798067373-0"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  Max:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  <span
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-0 ng-star-inserted"
                                  >
                                    {" "}
                                    12500{" "}
                                  </span>
                                  {/**/}
                                  {/**/}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds text-center ng-tns-c1798067373-0"
                        >
                          {" "}
                          1.97{" "}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme"
                        >
                          {/**/}
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-0 ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="card-icon ms-1 ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="card-red ng-tns-c1798067373-0"
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                                />
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="card-icon ms-1 ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="card-red ng-tns-c1798067373-0"
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                                />
                              </span>
                            </span>
                          </div>
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-book text-center ng-tns-c1798067373-0"
                        >
                          {" "}
                          0{" "}
                        </div>
                      </div>
                      {/**/}
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-right-box right-cards ng-tns-c1798067373-0"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="d-flex w-100 ng-tns-c1798067373-0"
                      >
                        <h4
                          _ngcontent-ng-c1798067373=""
                          className="w-100 text-end ng-tns-c1798067373-0"
                          style={{ fontSize: 13 }}
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0"
                          >
                            Cards 3.75
                          </b>
                        </h4>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="w-100 text-end bollywood_minmaxcards ng-tns-c1798067373-0"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min_max_collapse ng-tns-c1798067373-0"
                          >
                            <a
                              _ngcontent-ng-c1798067373=""
                              data-bs-toggle="collapse"
                              href="#collapserange2"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapserange2"
                              className="minmax-btn collapsed ng-tns-c1798067373-0"
                            >
                              <i
                                _ngcontent-ng-c1798067373=""
                                className="fas fa-info-circle ng-tns-c1798067373-0"
                              />
                            </a>
                            <div
                              _ngcontent-ng-c1798067373=""
                              id="collapserange2"
                              className="range-collpase collapse ng-tns-c1798067373-0"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-0 ng-tns-c1798067373-0"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  Min:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  {" "}
                                  100 {/**/}
                                  {/**/}
                                </span>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-1 ng-tns-c1798067373-0"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  Max:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-0"
                                >
                                  <span
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-0 ng-star-inserted"
                                  >
                                    {" "}
                                    12500{" "}
                                  </span>
                                  {/**/}
                                  {/**/}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0 ng-star-inserted"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/J.webp"
                          />
                          {/**/}
                          {/**/}
                          {/**/}
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-book ng-tns-c1798067373-0"
                        >
                          {" "}
                          0{" "}
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                        >
                          {/**/}
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0 ng-star-inserted"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/Q.webp"
                          />
                          {/**/}
                          {/**/}
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-book ng-tns-c1798067373-0"
                        >
                          {" "}
                          0{" "}
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                        >
                          {/**/}
                          {/**/}
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0 ng-star-inserted"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/K.webp"
                          />
                          {/**/}
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-book ng-tns-c1798067373-0"
                        >
                          {" "}
                          0{" "}
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                        >
                          {/**/}
                          {/**/}
                          {/**/}
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0 ng-star-inserted"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/A.webp"
                          />
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-book ng-tns-c1798067373-0"
                        >
                          {" "}
                          0{" "}
                        </div>
                      </div>
                      {/**/}
                    </div>
                  </div>
                </div>
              </div>
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-last-result-title ng-tns-c1798067373-0"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    Last Result
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      View All
                    </a>
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-last-results ng-tns-c1798067373-0 ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      A
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      C
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      C
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      E
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      B
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      B
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      C
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      C
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      E
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      {" "}
                      E
                    </span>
                  </span>
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                </div>
                {/**/}
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-0 d-none matchbettable w-100 ng-star-inserted"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="table-responsive ng-tns-c1798067373-0"
            >
              <table
                _ngcontent-ng-c1798067373=""
                className="table ng-tns-c1798067373-0"
              >
                <thead
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  <tr
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      Matched Bet
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-0"
                    >
                      Odds
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-0"
                    >
                      Stake
                    </th>
                  </tr>
                </thead>
                {/**/}
              </table>
            </div>
          </div>
          {/**/}
          {/**/}
        </div>
        {/**/}
      </div>
      <div
        _ngcontent-ng-c1798067373=""
        className="sidebar right-sidebar casino-right-sidebar ng-tns-c1798067373-0"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="sidebar-box place-bet-container d-xl-block d-none ng-tns-c1798067373-0"
        >
          {/**/}
          <div
            _ngcontent-ng-c1798067373=""
            className="sidebar-title ng-tns-c1798067373-0"
          >
            <h4 _ngcontent-ng-c1798067373="" className="ng-tns-c1798067373-0">
              Place Bet
            </h4>
          </div>
          {/**/}
        </div>
        <div
          _ngcontent-ng-c1798067373=""
          className="sidebar-box my-bet-container d-xl-block d-none ng-tns-c1798067373-0"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="sidebar-title ng-tns-c1798067373-0"
          >
            <h4 _ngcontent-ng-c1798067373="" className="ng-tns-c1798067373-0">
              My Bet
            </h4>
            <a
              _ngcontent-ng-c1798067373=""
              className="sideviewmore ng-tns-c1798067373-0"
            >
              View more
            </a>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="my-bets ng-tns-c1798067373-0"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="table-responsive w-100 ng-tns-c1798067373-0"
            >
              <table
                _ngcontent-ng-c1798067373=""
                className="table ng-tns-c1798067373-0"
              >
                <thead
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  <tr
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      Matched Bet
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-0"
                    >
                      Odds
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-0"
                    >
                      Stake
                    </th>
                  </tr>
                </thead>
                {/**/}
              </table>
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c1798067373=""
          className="sidebar-box sidebar-box-rules my-bet-container ng-tns-c1798067373-0"
        >
          <app-casino-game-rules
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-0"
            _nghost-ng-c1761710207=""
          >
            {/**/}
            {/**/}
            {/**/}
            {/**/}
          </app-casino-game-rules>
        </div>
      </div>
    </div>
  );
}
