import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import { CASINO_VIDEO } from "./CasinoGeneric";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import { fetchThirtyTwoCardStack } from "../../redux/features/thirtyTwoCardGameSlice";
import { API } from "../../service/API";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import CasinoBetModal from "./CasinoBetModal";
import Timer from "./components/Timer";

export default function TeenpattiOneDay() {
  const id = 11;
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const uid = user.data.resultid;
  console.log("id1", user);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [midRound, setMidRound] = useState(0);
  const [showresultModal, setShowresultModal] = useState(false);
  const [trophy, setTrophy] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [realTime, setRealTimeData] = useState(false);
  const [reqRes, setreqRes] = useState({ t1: [] });
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const [betDetails, setbetDetails] = useState({});
  const [history, setHistory] = useState([]);

  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      console.log("CasinoGameResult 32:", response);
      setCasinoGameResult(response?.data?.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const SOCKET_URL = "https://dreamwin-socket.nakshtech.info/";

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("TeenPatti socket1==>", data);
      if (data && data?.length > 0) {
        const parsedData = data?.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item?.reqResponse),
          resultUrlResponse: JSON.parse(item?.resultUrlResponse),
        }));

        console.log("32cardA", parsedData[0]?.reqResponse);
        setResults(parsedData[0]?.reqResponse);
        setreqRes(parsedData[0]?.reqResponse);
        setName(parsedData[0]?.name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  const handleModalPopUp = () => {
    setShowModal(!showModal);
  };

  const handleModal = (value, nation, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nation: nation,
      number: no,
    });
    handleModalPopUp(true);
    setRealTimeData(true);
  };

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t1.filter(
        (item) => item?.nation === betDetails?.nation
      );
      console.log("check", filteredData, betDetails);

      filteredData[0]?.gstatus === "ACTIVE" &&
      (betDetails?.multipler === filteredData[0]?.l1 ||
        betDetails?.multipler === filteredData[0]?.b1)
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t1, betDetails]);

  useEffect(() => {
    console.log("id2", id);

    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);

    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      // console.log("===Get", data);
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  async function ResultScheduler_API() {
    try {
      let res = await API.post(
        "/Schulder_7CareGameLive",
        {
          gameid: id,
          username: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  return (
    <div className="center-main-container casino-page ng-tns-c1798067373-5 ng-star-inserted">
      <div className="center-container ng-tns-c1798067373-5">
        <div className="casino-page-container ng-tns-c1798067373-5 teenpatti1day">
          <div className="casino-title-header-mobile d-xl-none ng-tns-c1798067373-5">
            <ul className="nav nav-tabs menu-tabs ng-tns-c1798067373-5">
              <li className="nav-item ng-tns-c1798067373-5">
                <div className="ng-tns-c1798067373-5 active nav-link">Game</div>
              </li>
              <li className="nav-item ng-tns-c1798067373-5">
                <div className="ng-tns-c1798067373-5 nav-link">
                  {" "}
                  Placed Bet (0){" "}
                </div>
              </li>
            </ul>
            <div className="pe-2 ng-tns-c1798067373-5">
              <span className="casino-rid ng-tns-c1798067373-5">
                <a className="d-block ng-tns-c1798067373-5">
                  <small className="ng-tns-c1798067373-5">Rules</small>
                </a>{" "}
                Round ID:{" "}
                <span className="ng-tns-c1798067373-5 ng-star-inserted">
                {reqRes?.data?.data?.t1[0]?.mid || 0.0}
                </span>
              </span>
            </div>
          </div>
          <div className="casino-header ng-tns-c1798067373-5">
            <span className="casino-name ng-tns-c1798067373-5">
              {" "}
              1 Day Teen Patti
              <a className="ms-1 d-xl-inline d-none ng-tns-c1798067373-5">
                <small className="ng-tns-c1798067373-5">Rules</small>
              </a>
            </span>
            <span className="casino-rid d-none d-xl-inline-block ng-tns-c1798067373-5">
              <small className="ng-tns-c1798067373-5">
                Round ID:{" "}
                <span className="ng-tns-c1798067373-5 ng-star-inserted">
                {reqRes?.data?.data?.t1[0]?.mid || 0.0}
                </span>
                {/**/}
              </small>
              <span className="ng-tns-c1798067373-5 ng-star-inserted">
                {" "}
                | Min:
                <span className="ng-tns-c1798067373-5 ng-star-inserted">
                  {" "}
                  100{" "}
                </span>
                | Max:
                <span className="ng-tns-c1798067373-5 ng-star-inserted">
                  {" "}
                  250000{" "}
                </span>
              </span>
            </span>
          </div>
          <div className="ng-tns-c1798067373-5 ng-star-inserted">
            <div className="casino-video ng-tns-c1798067373-5">
              <div className="video-box-container ng-tns-c1798067373-5">
                <div className="casino-video-box ng-tns-c1798067373-5 ng-star-inserted">
                  <iframe
                    className="ng-tns-c1798067373-5"
                    src={CASINO_VIDEO_URL + CASINO_VIDEO.teenPatti1Day}
                  />
                </div>
              </div>
              <div className="ng-tns-c1798067373-5 ng-star-inserted">
                <div className="casino-video-cards ng-tns-c1798067373-5 ng-star-inserted">
                  <div className="ng-tns-c1798067373-5 ng-star-inserted">
                    <h5 className="ng-tns-c1798067373-5 ng-star-inserted">
                      PLAYER A
                    </h5>
                    <div className="flip-card-container ng-tns-c1798067373-5 ng-star-inserted">
                      <div className="me-lg-2 me-1 ng-tns-c1798067373-5 ng-star-inserted">
                        <img
                          className="ng-tns-c1798067373-5"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/1.webp"
                        />
                      </div>
                      <div className="me-lg-2 me-1 ng-tns-c1798067373-5 ng-star-inserted">
                        <img
                          className="ng-tns-c1798067373-5"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/1.webp"
                        />
                      </div>
                      <div className="me-lg-2 me-1 ng-tns-c1798067373-5 ng-star-inserted">
                        <img
                          className="ng-tns-c1798067373-5"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/1.webp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ng-tns-c1798067373-5 ng-star-inserted">
                    <h5 className="ng-tns-c1798067373-5 ng-star-inserted">
                      PLAYER B
                    </h5>
                    <div className="flip-card-container ng-tns-c1798067373-5 ng-star-inserted">
                      <div className="me-lg-2 me-1 ng-tns-c1798067373-5 ng-star-inserted">
                        <img
                          className="ng-tns-c1798067373-5"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/1.webp"
                        />
                      </div>
                      <div className="me-lg-2 me-1 ng-tns-c1798067373-5 ng-star-inserted">
                        <img
                          className="ng-tns-c1798067373-5"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/1.webp"
                        />
                      </div>
                      <div className="me-lg-2 me-1 ng-tns-c1798067373-5 ng-star-inserted">
                        <img
                          className="ng-tns-c1798067373-5"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/1.webp"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Timer value={reqRes?.data?.data?.t1[0]?.autotime || 0} />

              {/* <div className="clock ng-tns-c1798067373-5">
                <div className="count-no ng-tns-c1798067373-5 ng-star-inserted">
                  <span className="ng-tns-c1798067373-5">
                    <div className="content ng-tns-c1798067373-5">
                      <div
                        style={{ position: "absolute" }}
                        className="ng-tns-c1798067373-5"
                      >
                        <div className="flip ng-tns-c1798067373-5">
                          <div className="up ng-tns-c1798067373-5">
                            <div className="ng-tns-c1798067373-5">0</div>
                          </div>
                          <div className="down ng-tns-c1798067373-5">
                            <div className="ng-tns-c1798067373-5">
                              <div className="ng-tns-c1798067373-5">0</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ position: "absolute" }}
                        className="ng-tns-c1798067373-5"
                      >
                        <div className="flip-card ng-tns-c1798067373-5">
                          <div className="flip-card-inner ng-tns-c1798067373-5 ng-trigger ng-trigger-flip">
                            <div className="flip-card-front ng-tns-c1798067373-5">
                              <div className="up ng-tns-c1798067373-5">
                                <div className="ng-tns-c1798067373-5">0</div>
                              </div>
                            </div>
                            <div className="flip-card-back ng-tns-c1798067373-5">
                              <div className="down ng-tns-c1798067373-5">
                                <div className="ng-tns-c1798067373-5">
                                  <div className="ng-tns-c1798067373-5">0</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
                <div
                  className="count-no ng-tns-c1798067373-5 ng-star-inserted"
                  style={{ marginRight: 80 }}
                >
                  <span className="ng-tns-c1798067373-5">
                    <div className="content ng-tns-c1798067373-5">
                      <div
                        style={{ position: "absolute" }}
                        className="ng-tns-c1798067373-5"
                      >
                        <div className="flip ng-tns-c1798067373-5">
                          <div className="up ng-tns-c1798067373-5">
                            <div className="ng-tns-c1798067373-5">0</div>
                          </div>
                          <div className="down ng-tns-c1798067373-5">
                            <div className="ng-tns-c1798067373-5">
                              <div className="ng-tns-c1798067373-5">0</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ position: "absolute" }}
                        className="ng-tns-c1798067373-5"
                      >
                        <div className="flip-card ng-tns-c1798067373-5">
                          <div className="flip-card-inner ng-tns-c1798067373-5 ng-trigger ng-trigger-flip">
                            <div className="flip-card-front ng-tns-c1798067373-5">
                              <div className="up ng-tns-c1798067373-5">
                                <div className="ng-tns-c1798067373-5">0</div>
                              </div>
                            </div>
                            <div className="flip-card-back ng-tns-c1798067373-5">
                              <div className="down ng-tns-c1798067373-5">
                                <div className="ng-tns-c1798067373-5">
                                  <div className="ng-tns-c1798067373-5">0</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </div> */}
            </div>

            <div className="casino-detail ng-tns-c1798067373-5">
              {/**/}
              <div className="casino-table ng-tns-c1798067373-5 ng-star-inserted">
                <div className="casino-table-box ng-tns-c1798067373-5">
                  <div className="casino-table-left-box w-100 ng-tns-c1798067373-5">
                    <div className="casino-table-header ng-tns-c1798067373-5">
                      <div className="casino-nation-detail ng-tns-c1798067373-5">
                        <div className="min_max_odds justify-content-start d-lg-none d-flex ng-tns-c1798067373-5">
                          <span className="min_max_span ms-0 ng-tns-c1798067373-5">
                            <b className="ng-tns-c1798067373-5">Min:</b>
                            <span className="ng-tns-c1798067373-5">
                              <span className="ng-tns-c1798067373-5 ng-star-inserted">
                                {" "}
                                100{" "}
                              </span>
                              {/**/}
                              {/**/}
                            </span>
                          </span>
                          <span className="min_max_span ms-1 ng-tns-c1798067373-5">
                            <b className="ng-tns-c1798067373-5">Max:</b>
                            <span className="ng-tns-c1798067373-5">
                              <span className="ng-tns-c1798067373-5 ng-star-inserted">
                                {" "}
                                250000{" "}
                              </span>
                              {/**/}
                              {/**/}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="casino-odds-box back ng-tns-c1798067373-5">
                        Back
                      </div>
                      <div className="casino-odds-box lay ng-tns-c1798067373-5">
                        Lay
                      </div>
                    </div>
                    <div className="casino-table-body ng-tns-c1798067373-5">
                      <div className="casino-table-row ng-tns-c1798067373-5 ng-star-inserted">
                        <div className="casino-nation-detail ng-tns-c1798067373-5">
                          <div className="casino-nation-name ng-tns-c1798067373-5">
                            {" "}
                            {reqRes?.data?.data?.t1[0]?.nation}{" "}
                            <p className="ng-tns-c1798067373-5">
                              {" "}
                              {thirtyTwoCardStack.map(
                                (ele) =>
                                  ele.number === 1 && (
                                    <span
                                      className={`${
                                        ele.number == 1 && ele.amount >= 0
                                          ? "success-1"
                                          : "zero"
                                      }`}
                                    >
                                      {ele.number == 1 && ele.amount}
                                    </span>
                                  )
                              )}
                            </p>
                          </div>
                        </div>

                        {reqRes?.data?.data?.t1[0]?.gstatus == "SUSPENDED" ||
                        reqRes?.data?.data?.t1[0]?.gstatus == "CLOSED" ? (
                          <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box lay ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="ng-tns-c1798067373-5 casino_odds_row ">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span
                                className="casino-odds ng-tns-c1798067373-5"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t1[0]?.b1,
                                    reqRes?.data?.data?.t1[0]?.nation,
                                    1
                                  );
                                }}
                              >
                                {reqRes?.data?.data?.t1[0]?.b1}
                              </span>
                              <p className="ng-tns-c1798067373-5"> 1M </p>
                            </div>
                            <div
                              className="casino-odds-box lay ng-tns-c1798067373-5"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t1[0]?.l1,
                                  reqRes?.data?.data?.t1[0]?.nation,
                                  3
                                );
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-5">
                                {reqRes?.data?.data?.t1[0]?.l1}
                              </span>
                              <p className="ng-tns-c1798067373-5"> 0 </p>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="casino-table-row ng-tns-c1798067373-5 ng-star-inserted">
                        <div className="casino-nation-detail ng-tns-c1798067373-5">
                          <div className="casino-nation-name ng-tns-c1798067373-5">
                            {" "}
                            {reqRes?.data?.data?.t1[1]?.nation}{" "}
                            <p className="ng-tns-c1798067373-5">
                              {" "}
                              {thirtyTwoCardStack.map(
                                (ele) =>
                                  ele.number === 2 && (
                                    <span
                                      className={`${
                                        ele.number == 2 && ele.amount >= 0
                                          ? "success-1"
                                          : "zero"
                                      }`}
                                    >
                                      {ele.number == 2 && ele.amount}
                                    </span>
                                  )
                              )}
                            </p>
                          </div>
                        </div>
                        {reqRes?.data?.data?.t1[1]?.gstatus == "SUSPENDED" ||
                        reqRes?.data?.data?.t1[1]?.gstatus == "CLOSED" ? (
                          <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box lay ng-tns-c1798067373-5">
                              <span className="casino-odds ng-tns-c1798067373-5">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="ng-tns-c1798067373-5 casino_odds_row ">
                            <div className="casino-odds-box back ng-tns-c1798067373-5">
                              <span
                                className="casino-odds ng-tns-c1798067373-5"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t1[1]?.b1,
                                    reqRes?.data?.data?.t1[1]?.nation,
                                    2
                                  );
                                }}
                              >
                                {reqRes?.data?.data?.t1[1]?.b1}
                              </span>
                              <p className="ng-tns-c1798067373-5"> 1M </p>
                            </div>
                            <div
                              className="casino-odds-box lay ng-tns-c1798067373-5"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t1[1]?.l1,
                                  reqRes?.data?.data?.t1[1]?.nation,
                                  3
                                );
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-5">
                                {reqRes?.data?.data?.t1[1]?.l1}
                              </span>
                              <p className="ng-tns-c1798067373-5"> 0 </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="ng-tns-c1798067373-5">
                <div className="casino-last-result-title ng-tns-c1798067373-5">
                  <span className="ng-tns-c1798067373-5">Last Result</span>
                  <span className="ng-tns-c1798067373-5">
                    <a className="ng-tns-c1798067373-5">View All</a>
                  </span>
                </div>
                <div className="casino-last-results ng-tns-c1798067373-5 ng-star-inserted">
                  <span className="ng-tns-c1798067373-5 result result-a ng-star-inserted">
                    <span className="ng-tns-c1798067373-5"> A</span>
                  </span>
                  <span className="ng-tns-c1798067373-5 result result-b ng-star-inserted">
                    <span className="ng-tns-c1798067373-5"> B</span>
                  </span>
                  <span className="ng-tns-c1798067373-5 result result-b ng-star-inserted">
                    <span className="ng-tns-c1798067373-5"> B</span>
                  </span>
                  <span className="ng-tns-c1798067373-5 result result-b ng-star-inserted">
                    <span className="ng-tns-c1798067373-5"> B</span>
                  </span>
                  <span className="ng-tns-c1798067373-5 result result-a ng-star-inserted">
                    <span className="ng-tns-c1798067373-5"> A</span>
                  </span>
                  <span className="ng-tns-c1798067373-5 result result-a ng-star-inserted">
                    <span className="ng-tns-c1798067373-5"> A</span>
                  </span>
                  <span className="ng-tns-c1798067373-5 result result-a ng-star-inserted">
                    <span className="ng-tns-c1798067373-5"> A</span>
                  </span>
                  <span className="ng-tns-c1798067373-5 result result-a ng-star-inserted">
                    <span className="ng-tns-c1798067373-5"> A</span>
                  </span>
                  <span className="ng-tns-c1798067373-5 result result-b ng-star-inserted">
                    <span className="ng-tns-c1798067373-5"> B</span>
                  </span>
                  <span className="ng-tns-c1798067373-5 result result-a ng-star-inserted">
                    <span className="ng-tns-c1798067373-5"> A</span>
                  </span>
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                </div>
                {/**/}
              </div>
            </div>
          </div>
          <div className="ng-tns-c1798067373-5 d-none matchbettable w-100 ng-star-inserted">
            <div className="table-responsive ng-tns-c1798067373-5">
              <table className="table ng-tns-c1798067373-5">
                <thead className="ng-tns-c1798067373-5">
                  <tr className="ng-tns-c1798067373-5">
                    <th className="ng-tns-c1798067373-5">Matched Bet</th>
                    <th className="text-end ng-tns-c1798067373-5">Odds</th>
                    <th className="text-end ng-tns-c1798067373-5">Stake</th>
                  </tr>
                </thead>
                {/**/}
              </table>
            </div>
          </div>
          {/**/}
          {/**/}
        </div>
        {/**/}
      </div>
      <div className="sidebar right-sidebar casino-right-sidebar ng-tns-c1798067373-5">
        <div className="sidebar-box place-bet-container d-xl-block d-none ng-tns-c1798067373-5">
          {/**/}
          <div className="sidebar-title ng-tns-c1798067373-5">
            <h4 className="ng-tns-c1798067373-5">Place Bet</h4>
          </div>
          <div>
            {showModal && (
              <CasinoBetModal
                id={id}
                name={name}
                modalOpen={showModal}
                betDetails={betDetails}
                setbetDetails={setbetDetails}
                setShowModal={setShowModal}
                realTime={realTime}
                setRealTimeData={setRealTimeData}
              />
            )}
          </div>
          {/**/}
        </div>
        <div className="sidebar-box my-bet-container d-xl-block d-none ng-tns-c1798067373-5">
          <div className="sidebar-title ng-tns-c1798067373-5">
            <h4 className="ng-tns-c1798067373-5">My Bet</h4>
            <a className="sideviewmore ng-tns-c1798067373-5">View more</a>
            <div
              className="my-bets"
              style={{ overflowX: "auto", height: "320px" }}
            >
              <div className="table-responsive w-100">
                <table className="table">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Period</th>
                      <th>Multiplier</th>
                      <th>Amount</th>
                      <th>Date</th>
                      <th>Remark</th>
                      <th>Card</th>
                    </tr>
                  </thead>
                  <tbody>
                    {history.length > 0 ? (
                      history.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.period}</td>
                          <td>{item.multipler}</td>
                          <td>{item.amount}</td>
                          <td>{item.dd}</td>
                          <td
                            className={
                              item.remark === "Loss" ? "zero" : "success-1"
                            }
                          >
                            {item.remark}
                          </td>
                          <td>{item.card}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="my-bets ng-tns-c1798067373-5">
            <div className="table-responsive w-100 ng-tns-c1798067373-5">
              <table className="table ng-tns-c1798067373-5">
                <thead className="ng-tns-c1798067373-5">
                  <tr className="ng-tns-c1798067373-5">
                    <th className="ng-tns-c1798067373-5">Matched Bet</th>
                    <th className="text-end ng-tns-c1798067373-5">Odds</th>
                    <th className="text-end ng-tns-c1798067373-5">Stake</th>
                  </tr>
                </thead>
                {/**/}
              </table>
            </div>
          </div>
        </div>
        <div className="sidebar-box sidebar-box-rules my-bet-container ng-tns-c1798067373-5">
          <app-casino-game-rules
            className="ng-tns-c1798067373-5"
            _nghost-ng-c1761710207=""
          />
        </div>
      </div>
    </div>
  );
}
